import { PaginatedDataDTO } from '@bottega52/commons-pagination';
import MomentUtils from '@date-io/moment';
import { Accordion, AccordionDetails, AccordionSummary, Button, Card, TextField } from "@material-ui/core";
import { WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import { PersonOutline } from '@material-ui/icons';
import WalletIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RedoIcon from '@material-ui/icons/Redo';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from 'moment';
import * as React from "react";
import { ConnectedProps, connect } from 'react-redux';
import { RechargeFileAcceptedExtensions, RechargeInvoiceFileName, RechargeStatusOptions } from '../../codec/rechargeInDTODecoder';
import { ModalTypes } from '../../components/Modals/ModalTypes';
import * as ModalsActions from '../../redux/modals/modals.actions';
import * as SettingsActions from '../../redux/settings/settings.actions';
import { IState } from '../../redux/store';
import { ICustomerInDTO } from '../../repository/jago/model/input/ICustomerInDTO';
import { IRechargeFormDTO } from '../../repository/jago/model/input/IRechargeFormDTO';
import { IWalletInDTO } from '../../repository/jago/model/input/IWalletInDTO';
import AbilityProvider from '../../services/PermissionService/AbilityProvider';
import { PERMISSIONS, Roles } from '../../services/PermissionService/PermissionConstants';
import ReduxLanguage from "../ReduxLanguage";

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: 20,
    height: '100%',
    flex: 1,
  },
  formTitle: {
    fontWeight: 'bold',
    marginBottom: 20,
  },
  errorText: {
    color: 'red'
  },
  inputTitle: {
    marginTop: 20,
    marginBottom: 8,
    fontSize: 18,
    fontWeight: 'bold',
  },
  disabledButton: {
    backgroundColor: 'red'
  },
  inputTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  italicText: {
    fontStyle: 'italic',
  },
  actionButton: {
    width: 'fit-content',
    color: 'white',
    marginLeft: 20,
    padding: 4,
    paddingLeft: 10,
    paddingRight: 10,
    fontWeight: 'bold'
  },
  actionButtonText: {
    margin: 0,
    fontWeight: 'bold',
  },
  icon: {
    fontSize: 15,
    marginRight: 5,
  },
  iconCustomer: {
    marginRight: 5,
    fontSize: 25,
  },
});

type IReduxProps = ConnectedProps<typeof connector>;

export interface IRechargeFormState {
  recharge: IRechargeFormDTO;
  errors: Record<keyof IRechargeFormDTO, boolean>;
  selectedFileName: string;
};

export interface IRechargeFormProps extends WithStyles<typeof styles>{
  onEditRecharge: (recharge: IRechargeFormDTO) => void;
  onOpenCustomerInfo: (customer: ICustomerInDTO) => void;
  recharge?: IRechargeFormDTO;
  customers: PaginatedDataDTO<ICustomerInDTO>;
  wallets: PaginatedDataDTO<IWalletInDTO>;
}

export type ComponentProps = IRechargeFormProps & IReduxProps;
class RechargeForm extends React.Component<ComponentProps, IRechargeFormState> {
  inputFileRefInvoice = null;

  constructor(props: ComponentProps) {
    super(props);
    this.inputFileRefInvoice = React.createRef();

    this.state = {
      selectedFileName: null,
      recharge: props.recharge ? props.recharge : {
        varId: null,
        varName: null,
        scratchcardId: null,
        mode: "",
        status: "",
        rejectedNotes: "",
        deletedNotes: "",
        rejectedInvoiceNotes: "",
        sfdcId: "",
        walletId: 0,
        customerId: 0,
        creditsSuggested: 0,
        creditsConfirmed: 0,
        odsMail: "",

        salesOrderConfirmationNumber: "",
        purchaseOrderNumberSofia: "",
        custRefID: "",
        invoiceNumber: "",
        
        orderContactEmail: "",

        documents: [],
        documentsOUT: {},
      },
      errors: {
        varId: false,
        varName: false,
        scratchcardId: false,
        mode: false,
        status: false,
        rejectedNotes: false,
        deletedNotes: false,
        rejectedInvoiceNotes: false,
        sfdcId: false,
        walletId: false,
        customerId: false,
        creditsSuggested: false,
        creditsConfirmed: false,
        odsMail: false,

        salesOrderConfirmationNumber: false,
        purchaseOrderNumberSofia: false,
        custRefID: false,
        invoiceNumber: false,
        
        orderContactEmail: false,

        documents: false,
        documentsOUT: false,
      },
    };
  }

  public componentDidMount() {
    const { dispatch } = this.props;
    // Init per caso "edit"
    if (this.props.recharge) {
      
    }
    dispatch<any>(SettingsActions.setSpinnerVisible(false));
  }

  onChangeValue(field: keyof IRechargeFormDTO, mandatory: boolean, value?: any) {
    const { recharge, errors } = this.state;
    // Custom errors on specific fields
    let customError = false;
    let customValue = undefined;
    this.setState({
      errors: { ...errors, [field]: (!value||customError) && mandatory ? true : false }
    });
    (recharge as any)[field] = customValue===undefined?value:customValue;
    this.setState({ recharge });
  }

  toBase64 = (file: File) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      // Rimuovo la prima parte, i.e. "data:application/pdf;base64,"
      let resultAsString = (reader.result as string);
      let resultSplitted = resultAsString.split(',');
      if (resultSplitted.length>1)
        resolve(resultSplitted[1])
    };
    reader.onerror = reject;
  });

  async onNewInputFile(newFile: File) {
    const { dispatch } = this.props;
    var extensionOK = false;
    var fileName: string = "";
    var fileExtension: string = "";
    var fileExtSearch: number = newFile&&newFile.name?newFile.name.lastIndexOf("."):-1
    if (fileExtSearch>0&&fileExtSearch<newFile.name.length) {
      fileName = newFile.name.substring(0,fileExtSearch);
      fileExtension = newFile.name.substring(fileExtSearch+1);
      extensionOK = RechargeFileAcceptedExtensions.some(e=>e.toUpperCase()===fileExtension.toUpperCase())
    }
    if (!extensionOK) {
      dispatch<any>(ModalsActions.showModal(`NEWFILE_EXTENSION_ERROR_MODAL`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'recharges.newFileExtensionError',
        }
      }));
      return;
    }
    dispatch<any>(ModalsActions.showModal(`NEWFILE_NAME_MODAL`, {
      modalType: ModalTypes.TEXTFIELD_MODAL,
      modalProps: {
        icon: null,
        titleMessageKey: "",
        successMessageKey: 'recharges.newFileName',
        initialText: fileName?fileName:"",
        fieldLabel: "",
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: (text: string) => this.onNewInputFileConfirmed(newFile,text,fileExtension),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`NEWFILE_NAME_MODAL`)),
      }
    }));
  }

  async onNewInputFileConfirmed(newFile: File,newName: string,newExtension: string) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.hideModal(`NEWFILE_NAME_MODAL`))
    const { selectedFileName, recharge } = this.state;
    let encodedFile = null;
    try {encodedFile = await this.toBase64(newFile)} catch(e) {return}
    if (encodedFile===null) return;
    if (selectedFileName!==null) {
      recharge.documents = this.state.recharge.documents.map((item,i) => {
        if (selectedFileName===item.name) return {name:item.name,contentBase64:encodedFile};
        else return item;
      });
    }
    else {
      if (newName!==null&&newExtension!==null) {
        // Se per qualche motivo arcano mi han messo un fileName che include RechargeInvoiceFileName, rimuovo gli underscore e pippa
        if(newName.includes(RechargeInvoiceFileName))
          newName = newName.replaceAll("__","")
        // Controllo che il nome dato non sia già presente, altrimenti puppa
        let tempSearch = recharge.documents.filter(e=>e.name===(newName+"."+newExtension))
        if (tempSearch.length>0) {
          dispatch<any>(ModalsActions.showModal(`NEWFILE_DUPLICATE_NAME_ERROR_MODAL`, {
            modalType: ModalTypes.ERROR_MODAL,
            modalProps: {
              titleMessageKey: 'errors.error',
              errorMessageKey: 'recharges.newFileDuplicateNameError',
            }
          }));
          return;
        }
        recharge.documents = [...recharge.documents, {name:(newName+"."+newExtension),contentBase64:encodedFile}]
      }
    }
    this.setState({ recharge })
  }

  async onNewInvoiceFileConfirmed(newFile: File) {
    const { dispatch } = this.props;
    const { recharge } = this.state;
    let encodedFile = null;
    try {encodedFile = await this.toBase64(newFile)} catch(e) {return}
    if (encodedFile===null) return;
    // Se esiste già un file RechargeInvoiceFileName, sostituisco quello, altrimenti creo nuovo
    let tempSearch = recharge.documents.filter(e=>e.name.includes(RechargeInvoiceFileName))
    if (tempSearch.length>0) {
      recharge.documents = this.state.recharge.documents.map((item,i) => {
        if (item.name.includes(RechargeInvoiceFileName)) return {name:item.name,contentBase64:encodedFile};
        else return item;
      });
    }
    else {
      // Piglio l'estensione del file (il filename lo piazzo io)
      var fileExtension: string = "";
      var fileExtSearch: number = newFile&&newFile.name?newFile.name.lastIndexOf("."):-1
      if (fileExtSearch>0&&fileExtSearch<newFile.name.length)
        fileExtension = newFile.name.substring(fileExtSearch+1);
      else {
        dispatch<any>(ModalsActions.showModal(`NEWFILE_EXTENSION_ERROR_MODAL`, {
          modalType: ModalTypes.ERROR_MODAL,
          modalProps: {
            titleMessageKey: 'errors.error',
            errorMessageKey: 'recharges.newFileExtensionError',
          }
        }));
        return;
      }
      recharge.documents = [...recharge.documents, {name:(RechargeInvoiceFileName+"."+fileExtension),contentBase64:encodedFile}]
    }
    this.setState({ recharge })
  }

  onSubmitForm() {
    let { recharge } = this.state;
    if (!this.canSubmitForm())
      return;
    // Piazzo i documents che sono stati aggiunti || aggiornati
    let newDocuments = {};
    for (let i=0; i<recharge.documents.length; i++) {
      if (recharge.documents[i].name&&recharge.documents[i].contentBase64) {
        newDocuments[recharge.documents[i].name] = recharge.documents[i].contentBase64;
      }
    }
    recharge.documentsOUT = newDocuments;
    // Salvo
    const { onEditRecharge } = this.props;
    onEditRecharge(recharge);
  }

  canSubmitForm() {
    const { recharge } = this.state;
    if (recharge.status===RechargeStatusOptions.TAKEN_CHARGE||recharge.status===RechargeStatusOptions.REJECTED||recharge.status===RechargeStatusOptions.SELLOUT_ORDER_GENERATED)
      return recharge.salesOrderConfirmationNumber && recharge.purchaseOrderNumberSofia && 
      (recharge.creditsConfirmed!==null && recharge.creditsConfirmed>0);
    else
      return recharge.invoiceNumber&&recharge.documents.filter(e=>e.name.includes(RechargeInvoiceFileName)).length>=1
  }

  public render() {
    const { classes, customers, wallets, onOpenCustomerInfo } = this.props;
    const { recharge, errors } = this.state;
    
    const customer = customers.content.filter(e=>e.id===recharge.customerId).length>0?customers.content.filter(e=>e.id===recharge.customerId)[0]:null
    const wallet = wallets.content.filter(e=>e.id===recharge.walletId).length>0?wallets.content.filter(e=>e.id===recharge.walletId)[0]:null

    const canEdit = AbilityProvider.getAbilityHelper().hasPermission(PERMISSIONS.RECHARGE_REQUEST_EDIT)

    return (
      <MuiPickersUtilsProvider utils={MomentUtils} locale={moment.locale()}>
        <div className={classes.container}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              id="rechargeBasicDataAccordion"
            >
            <div>
              <h2 style={{ fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'recharges.basicData'} />}</h2>
            </div>
            </AccordionSummary>
            <AccordionDetails style={{display:'flex',flexDirection:'column'}}>
              <h3 style={{ fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'recharges.customerAndWallet'} />}</h3>
              <div style={{marginTop:10,marginBottom:20}}>
                <Card style={{display:'flex',flexDirection:'column',padding:10}}>
                  <div style={{display:'flex',flexDirection:'row',padding:10}}>
                    <PersonOutline className={classes.iconCustomer} />
                    <h3 style={{fontWeight: 'bold'}}>{customer?customer.name:"Customer not defined"}</h3>
                    <Button
                      variant="contained"
                      className={classes.actionButton}
                      style={{ backgroundColor:'darkcyan', flexShrink:0}}
                      onClick={() => {if (customer) onOpenCustomerInfo(customer)}}
                    >
                      {<VisibilityIcon className={classes.icon}/>}
                      <h5 className={classes.actionButtonText}><ReduxLanguage languageKey={"recharges.seeCustomer"} /></h5>
                    </Button>
                  </div>
                  {recharge.varName?(
                    <div style={{display:'flex',flexDirection:'row',padding:10}}>
                      <h3 style={{fontStyle:'italic',fontWeight:'bold',fontSize:'1.0em',display:'inline',border:'1px solid grey',borderRadius:5,marginLeft:0,paddingRight:3}}>
                        {"VAR "}
                      </h3>
                      <h3 style={{fontWeight: 'bold'}}>{recharge.varName}</h3>
                    </div>
                  ):null}
                  <div style={{display:'flex',flexDirection:'row',padding:10}}>
                    <WalletIcon className={classes.iconCustomer} />
                    <div>
                      <h3 style={{fontWeight: 'bold'}}>#{wallet?wallet.id:""} - {wallet?wallet.name:"Wallet not defined"}</h3>
                      <h3>{wallet?wallet.notes:""}</h3>
                    </div>
                  </div>
                </Card>
              </div>
              
              <div style={{marginBottom:10}}>
                <h3 style={{ fontWeight: 'bold', display:'inline-block' }}>{<ReduxLanguage languageKey={'recharges.mode'} />}</h3>
                <h3 style={{ fontWeight: 'bold', display:'inline-block', textDecoration:'underline', fontSize:'0.9em' }}>
                  {<ReduxLanguage languageKey={recharge.mode?`recharges.${recharge.mode}`:"N/D"} />}
                </h3>
              </div>
              <h3 style={{ fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'recharges.creditsSuggested'} />}</h3>
              <TextField
                autoComplete='off'
                id="outlined-basic"
                label={""}
                variant="outlined"
                type='number'
                fullWidth
                required
                disabled={true}
                error={false}
                value={recharge.creditsSuggested}
                style={{ marginBottom: 20, marginTop:10, maxWidth:400 }}
                onChange={e => {}}
              />
              <h3 style={{ fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'recharges.creditsConfirmed'} />}</h3>
              <TextField
                autoComplete='off'
                id="outlined-basic"
                label={<ReduxLanguage languageKey={'activations.formEnterAnswerGT0'} />}
                variant="outlined"
                type='number'
                onWheel={(e) => {if(e.target&&('blur' in e.target)&&(typeof e.target.blur === 'function')) e.target.blur()}}
                fullWidth
                required
                disabled={!canEdit||!(recharge.status===RechargeStatusOptions.TAKEN_CHARGE||recharge.status===RechargeStatusOptions.REJECTED)}
                error={errors.creditsConfirmed}
                value={recharge.creditsConfirmed}
                style={{ marginBottom: 20, marginTop:10, maxWidth:400  }}
                onChange={e => this.onChangeValue('creditsConfirmed', true, e.target.value)}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              id="rechargeBasicDataAccordion"
            >
            <div>
              <h2 style={{ fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'recharges.ordersData'} />}</h2>
            </div>
            </AccordionSummary>
            <AccordionDetails style={{display:'flex',flexDirection:'column'}}>
                <h3 style={{ fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'recharges.sfdcId'} />}</h3>
                <TextField
                  autoComplete='off'
                  id="outlined-basic"
                  label={""}
                  variant="outlined"
                  fullWidth
                  required
                  disabled={true}
                  error={errors.sfdcId}
                  value={recharge.sfdcId}
                  style={{ marginBottom: 20, marginTop:10 }}
                  onChange={e => this.onChangeValue('sfdcId', true, e.target.value)}
                />

                <h3 style={{ fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'recharges.salesOrderConfirmationNumber'} />}</h3>
                <h5 style={{ fontWeight: 'normal' }}>{<ReduxLanguage languageKey={'recharges.salesOrderConfirmationNumber_desc'} />}</h5>
                <TextField
                  autoComplete='off'
                  id="outlined-basic"
                  label={recharge.salesOrderConfirmationNumber?"":<ReduxLanguage languageKey={'recharges.formEnterAnswer'} />}
                  variant="outlined"
                  fullWidth
                  required
                  disabled={!canEdit||!(recharge.status===RechargeStatusOptions.TAKEN_CHARGE||recharge.status===RechargeStatusOptions.REJECTED)}
                  error={errors.salesOrderConfirmationNumber}
                  value={recharge.salesOrderConfirmationNumber}
                  style={{ marginBottom: 20, marginTop:10 }}
                  onChange={e => this.onChangeValue('salesOrderConfirmationNumber', true, e.target.value)}
                />

                <h3 style={{ fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'recharges.purchaseOrderNumberSofia'} />}</h3>
                <TextField
                  autoComplete='off'
                  id="outlined-basic"
                  label={recharge.purchaseOrderNumberSofia?"":<ReduxLanguage languageKey={'recharges.formEnterAnswer'} />}
                  variant="outlined"
                  fullWidth
                  required
                  disabled={!canEdit||(
                    !(recharge.status===RechargeStatusOptions.TAKEN_CHARGE||recharge.status===RechargeStatusOptions.REJECTED)&&
                    !(AbilityProvider.getAbilityHelper().hasRoles([Roles.ROLE_VASHO_SOFIA_ADMIN])&&recharge.status===RechargeStatusOptions.SCRATCHCARD_CREATED)
                  )}
                  error={errors.purchaseOrderNumberSofia}
                  value={recharge.purchaseOrderNumberSofia}
                  style={{ marginBottom: 20, marginTop:10 }}
                  onChange={e => this.onChangeValue('purchaseOrderNumberSofia', true, e.target.value)}
                />

                <h3 style={{ fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'recharges.orderContactEmail'} />}</h3>
                <TextField
                  autoComplete='off'
                  id="outlined-basic"
                  label={recharge.orderContactEmail?"":<ReduxLanguage languageKey={'recharges.formEnterAnswer'} />}
                  variant="outlined"
                  fullWidth
                  disabled={!canEdit||(
                    !(recharge.status===RechargeStatusOptions.TAKEN_CHARGE||recharge.status===RechargeStatusOptions.REJECTED)&&
                    !(AbilityProvider.getAbilityHelper().hasRoles([Roles.ROLE_VASHO_SOFIA_ADMIN])&&recharge.status===RechargeStatusOptions.SCRATCHCARD_CREATED)
                  )}
                  error={errors.orderContactEmail}
                  value={recharge.orderContactEmail}
                  style={{ marginBottom: 20, marginTop:10 }}
                  onChange={e => this.onChangeValue('orderContactEmail', true, e.target.value)}
                />
            </AccordionDetails>
          </Accordion>
          
          {recharge.status === RechargeStatusOptions.SCRATCHCARD_CREATED || recharge.status===RechargeStatusOptions.INVOICED || recharge.status===RechargeStatusOptions.WALLET_RECHARGED ? (
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id="rechargeInvoiceDataAccordion"
              >
              <div>
                <h2 style={{ fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'recharges.invoiceData'} />}</h2>
              </div>
              </AccordionSummary>
              <AccordionDetails style={{display:'flex',flexDirection:'column'}}>
                  <h3 style={{ fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'recharges.invoiceNumber'} />}</h3>
                  <TextField
                    autoComplete='off'
                    id="outlined-basic"
                    label={recharge.invoiceNumber?"":<ReduxLanguage languageKey={'recharges.formEnterAnswer'} />}
                    variant="outlined"
                    fullWidth
                    required
                    disabled={!canEdit||!(recharge.status===RechargeStatusOptions.SCRATCHCARD_CREATED)}
                    error={errors.invoiceNumber}
                    value={recharge.invoiceNumber}
                    style={{ marginBottom: 20, marginTop:10 }}
                    onChange={e => this.onChangeValue('invoiceNumber', true, e.target.value)}
                  />

                  <input type='file' style={{display: 'none'}} ref={this.inputFileRefInvoice} 
                    onChange={(e) => {
                      if(e.target.files&&e.target.files.length>0) {
                        this.onNewInvoiceFileConfirmed(e.target.files[0])
                        e.target.value = null
                      }
                    }}
                  />
                  <h3 style={{ fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'recharges.invoiceFile'} />}</h3>
                  <h5 style={{ fontWeight: 'normal' }}>{<ReduxLanguage languageKey={'recharges.invoiceFile_desc'} />}</h5>
                  {recharge.documents.filter(e=>e.name.includes(RechargeInvoiceFileName)).map((document,index) => (
                    <Card key={index}
                      style={{padding:10,backgroundColor:'white',display:'flex',flexDirection:'row', alignItems:'center',marginTop:10}} elevation={2}>
                      <div style={{fontWeight:'bold',maxWidth:'70%',overflow:'hidden',textOverflow:'ellipsis',fontSize:'0.8em'}}>
                        {"Invoice"}
                      </div>
                      <span style={{flexGrow:1}}/>
                      {document.url?(
                        <a href={document.url}>
                          <Button
                            variant="contained"
                            style={{backgroundColor:'darkcyan'}}
                            className={classes.actionButton}
                          >
                            <CloudDownloadIcon className={classes.icon}/>
                            <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="recharges.selloutFilesDownload" /></h5>
                          </Button>
                        </a>
                      ):null}
                      {!canEdit||!(recharge.status===RechargeStatusOptions.SCRATCHCARD_CREATED)?null:(
                        <Button
                          variant="contained"
                          style={{backgroundColor:'#282c34'}}
                          className={classes.actionButton}
                          onClick={(e) => {
                            if (this.inputFileRefInvoice&&this.inputFileRefInvoice.current)
                              this.inputFileRefInvoice.current.click();
                          }}
                        >
                          <RedoIcon className={classes.icon}/>
                          <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="recharges.selloutFilesReplace" /></h5>
                        </Button>
                      )}
                    </Card>
                  ))}
                  {!canEdit||recharge.documents.filter(e=>e.name.includes(RechargeInvoiceFileName)).length>=1||
                  !(recharge.status===RechargeStatusOptions.SCRATCHCARD_CREATED)?(null):(
                    <Button
                      variant="contained"
                      style={{backgroundColor:'#282c34', marginTop:10}}
                      className={classes.actionButton}
                      onClick={(e) => {
                          if (this.inputFileRefInvoice&&this.inputFileRefInvoice.current)
                            this.inputFileRefInvoice.current.click();
                      }}
                    >
                      <CloudUploadIcon className={classes.icon}/>
                      <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="recharges.selloutFilesAdd" /></h5>
                    </Button>
                  )}
                  <h6 style={{ fontWeight: 'normal', marginTop:10, marginBottom:20 }}>
                    {<ReduxLanguage languageKey={'recharges.invoiceFile_desc2'} />}
                  </h6>
              </AccordionDetails>
            </Accordion>
          ):null}
          {!canEdit||!(recharge.status === RechargeStatusOptions.TAKEN_CHARGE || recharge.status === RechargeStatusOptions.REJECTED || recharge.status === RechargeStatusOptions.SCRATCHCARD_CREATED) ? null:(
            <div style={{position:'sticky',bottom:'0',paddingTop:'20px',backgroundColor:'white',marginLeft:'-5px',marginRight:'-5px',zIndex:1}}>
              <Button
                variant="contained"
                disabled={!this.canSubmitForm()}
                style={{ width: '100%', opacity: this.canSubmitForm() ? 1 : 0.5, backgroundColor: '#5AC0B1', color: 'white', fontWeight: 'bold' }}
                onClick={() => this.onSubmitForm()}
              >
                <ReduxLanguage languageKey="recharges.saveRequest" />
              </Button>
            </div>
          )}
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
  };
}

const connector = connect(mapStateToProps);

export default connector(withStyles(styles)(RechargeForm));