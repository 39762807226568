import { ModalTypes, IModalViewProps } from "./ModalTypes";
import ErrorModal from "./ErrorModal";
import OperationalViewModal from "./OperationalViewModal";
import OperationalViewModalBackdrop from "./OperationalViewModalBackdrop";
import SuccessBar from './SuccessSnackBar';
import ConfirmModal from "./ConfirmModal";
import ContentModal from './ContentModal';
import SuccessSnackBar from "./SuccessSnackBar";
import TextfieldModal from "./TextfieldModal";
import SelectModal from "./SelectModal";
import OperationalViewModalWP from "./OperationalViewModalWP";

export const modalComponents: { [key: string]: React.ComponentType<IModalViewProps<any>> } = {
  [ModalTypes.ERROR_MODAL]: ErrorModal,
  [ModalTypes.OPERATIONAL_VIEW_MODAL]: OperationalViewModal,
  [ModalTypes.OPERATIONAL_VIEW_MODAL_BACKDROP]: OperationalViewModalBackdrop,
  [ModalTypes.OPERATIONAL_VIEW_MODAL_WP]: OperationalViewModalWP,
  [ModalTypes.SUCCESS_MODAL]: SuccessBar,
  [ModalTypes.CONFIRM_MODAL]: ConfirmModal,
  [ModalTypes.CONTENT_MODAL]: ContentModal,
  [ModalTypes.SUCCESS_ALERT]: SuccessSnackBar,
  [ModalTypes.TEXTFIELD_MODAL]: TextfieldModal,
  [ModalTypes.SELECT_MODAL]: SelectModal,
};