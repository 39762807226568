import { PaginatedDataDTO, PaginationDTO } from '@bottega52/commons-pagination';
import { WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/Warning';
import _ from 'lodash';
import 'moment/locale/it';
import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import * as ActivationDecoder from '../../codec/activationInDTODecoder';
import ActivationsTable from '../../components/Activations/ActivationsTable';
import ActivationNewForm from '../../components/Forms/ActivationNewForm';
import { ModalTypes } from '../../components/Modals/ModalTypes';
import ReduxLanguage from '../../components/ReduxLanguage';
import ActivationSearchBar from '../../components/SearchBar/ActivationSearchBar';
import * as ActivationActions from '../../redux/activations/activations.actions';
import * as CreditsActions from '../../redux/credits/credits.actions';
import * as ModalsActions from '../../redux/modals/modals.actions';
import * as SettingsActions from '../../redux/settings/settings.actions';
import { IState } from '../../redux/store';
import * as VarsActions from '../../redux/vars/vars.actions';
import { IActivationInDTO } from '../../repository/jago/model/input/IActivationInDTO';
import { IActivationNewFormDTO } from '../../repository/jago/model/input/IActivationNewFormDTO';
import { IActivationsSearchDTO } from '../../repository/jago/model/input/IActivationsSearchDTO';
import IClusterInDTO from '../../repository/jago/model/input/IClusterInDTO';
import { ICustomerFormDTO } from '../../repository/jago/model/input/ICustomerFormDTO';
import { ICustomerInDTO } from '../../repository/jago/model/input/ICustomerInDTO';
import { IKamInDTO } from '../../repository/jago/model/input/IKamInDTO';
import IMarketplaceInDTO from '../../repository/jago/model/input/IMarketplaceInDTO';
import { IVarInDTO } from '../../repository/jago/model/input/IVarInDTO';
import { IWalletFormDTO } from '../../repository/jago/model/input/IWalletFormDTO';
import { IWalletStatusEnum } from '../../repository/jago/model/input/IWalletStatusEnum';
import { ICardOutDTO, ICardOutSubscription } from '../../repository/jago/model/output/ICardOutDTO';
import AbilityProvider from '../../services/PermissionService/AbilityProvider';
import { PERMISSIONS } from '../../services/PermissionService/PermissionConstants';
import Utils, { VAR_USERS_ROLES } from '../../utils/Utils';

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
  header: {
    backgroundColor: 'white',
    width: '100%',
    zIndex: 200,
    padding: 10,
    paddingRight: 20,
    borderBottom: '2px solid #5AC0B1',
  },
  tableContainer: {
    display: 'flex',
    backgroundColor: 'white',
    height: 'calc(100vh - 160px)',
    width: '100%'
  },
});

type IReduxProps = ConnectedProps<typeof connector>;

export interface IActivationRequestsPageProps extends WithStyles<typeof styles> {
  language: string;
  activations: PaginatedDataDTO<IActivationInDTO>;
  clusters: PaginatedDataDTO<IClusterInDTO>;
  marketplaces: PaginatedDataDTO<IMarketplaceInDTO>;
}

export type ComponentProps = IActivationRequestsPageProps & IReduxProps;

export interface IActivationRequestsPageState {
  defaultMarketplace: IMarketplaceInDTO;
  searchValuesCurrent: IActivationsSearchDTO;
  pageSize: number;
  isLoading: boolean;
};

class ActivationRequestsPage extends React.Component<ComponentProps,IActivationRequestsPageState> {

  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      defaultMarketplace: null,
      searchValuesCurrent: {},
      pageSize: 100,
      isLoading: false,
    };
  }

  async componentDidMount() {
    const { dispatch } = this.props;
    const { pageSize } = this.state;
    try {
      const isVar = AbilityProvider.getAbilityHelper().hasRoles(VAR_USERS_ROLES.map(e=>{return e.name}))
      this.setState({ isLoading: true });
      if (!isVar && AbilityProvider.getAbilityHelper().hasPermission(PERMISSIONS.KAM_READ)) {
        /* TODO DOPO FIX KAMS
        await dispatch<any>(UserActions.fetchUsersRoles)
        const { usersRoles } = this.props;
        const usersRolesData = (usersRoles as PaginatedDataDTO<IUserRoleInDTO>).content
        const kamRole = _.find(usersRolesData, e=>e.name===Roles.ROLE_VASHO_KAM);
        if (kamRole && kamRole.id) {
          const params: IUserRequestParamsDTO = { 
            roleIds: kamRole.id.toString(),
            page: 0,
            pageSize: 1000,
          };
          await dispatch<any>(UserActions.fetchUsers(params))
        }
        */
        await dispatch<any>(ActivationActions.fetchKams({ page: 0, pageSize: 500 }));
      }
      else {
        /* TODO DOPO FIX KAMS
        await dispatch<any>(UserActions.UserActions.saveUsers({content: [], pagination: {}}));
        */
        await dispatch<any>(ActivationActions.ActivationsActions.saveKams({content: [], pagination: {}}));
      }
      if (!isVar)
        await dispatch<any>(ActivationActions.fetchClusters());
      if (!isVar) {
        await dispatch<any>(ActivationActions.fetchMarketplaces());
        let defaultMarketplaceSearch =(this.props.marketplaces as PaginatedDataDTO<IMarketplaceInDTO>).content.filter(e=>e.name===Utils.getDefaultMarketplace())
        if (defaultMarketplaceSearch.length>0) {
          let defaultMarketplace = defaultMarketplaceSearch[0];
          this.setState({defaultMarketplace: defaultMarketplace})
          await dispatch<any>(ActivationActions.fetchMarketplaceSubscriptions(defaultMarketplace.id));
        }
      }
      await this.onFetchActivations(0, pageSize);
      if (isVar)
        await dispatch<any>(VarsActions.fetchVar());
      else
        await dispatch<any>(VarsActions.fetchVars({ page: 0, pageSize: 500 }))
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
      dispatch<any>(ModalsActions.showModal(`ERROR_OPEN_PARAMETERS`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.openSystemParametersError',
        }
      }));
    }
  }

  async onFetchActivations(page: number = 0, pageSize: number = 100) {
    const { dispatch } = this.props;
    const { searchValuesCurrent } = this.state;
    const params = { page, pageSize, ...searchValuesCurrent };
    try {
      this.setState({ isLoading: true });
      await dispatch<any>(ActivationActions.fetchActivations(params));
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
      throw error;
    }
  }

  async onSearchActivations(searchValues: IActivationsSearchDTO) {
    const { pageSize } = this.state;
    this.setState({ searchValuesCurrent:searchValues }, async () => await this.onFetchActivations(0, pageSize));
  }

  onResetSearch() {  
    const { pageSize } = this.state;
    this.setState({ searchValuesCurrent:{}, isLoading: true });
    try {
      setTimeout(() => {
        this.onFetchActivations(0, pageSize);
        this.setState({ isLoading: false });
      }, 100);
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  onDeleteActivationRequest(activation: IActivationInDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`DELETE_ACTIVATION_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'forms.warning',
        successMessageKey: 'activations.deleteActivationConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onDeleteActivationConfirm(activation),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`DELETE_ACTIVATION_CONFIRM_MODAL`)),
      }
    }));
  }

  async onDeleteActivationConfirm(activation: IActivationInDTO) {
    const { dispatch, activations } = this.props;
    const { pageSize } = this.state;
    const pagination = activations.pagination as PaginationDTO;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(ActivationActions.deleteActivation(activation.id));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('DELETE_ACTIVATION_CONFIRM_MODAL'));
      this.onFetchActivations(pagination.number, pageSize);
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_ACTIVATION_DELETE`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'activations.deleteActivationError',
        }
      }));
    }
  }

  onDeleteActivationAdminRequest(activation: IActivationInDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`DELETE_ACTIVATION_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'forms.warning',
        successMessageKey: 'activations.deleteActivationConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onDeleteActivationConfirm(activation),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`DELETE_ACTIVATION_CONFIRM_MODAL`)),
      }
    }));
  }

  async onDeleteActivationAdminConfirm(activation: IActivationInDTO) {
    const { dispatch, activations } = this.props;
    const { pageSize } = this.state;
    const pagination = activations.pagination as PaginationDTO;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(ActivationActions.deleteActivation(activation.id));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('DELETE_ACTIVATION_CONFIRM_MODAL'));
      this.onFetchActivations(pagination.number, pageSize);
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_ACTIVATION_DELETE`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'activations.deleteActivationError',
        }
      }));
    }
  }

  onPromoteActivationRequest(activation: IActivationInDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`PROMOTE_ACTIVATION_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'forms.warning',
        successMessageKey: 'activations.promoteActivationConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onPromoteActivationConfirm(activation),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`PROMOTE_ACTIVATION_CONFIRM_MODAL`)),
      }
    }));
  }

  async onPromoteActivationConfirm(activation: IActivationInDTO) {
    const { dispatch, activations } = this.props;
    const { pageSize } = this.state;
    const pagination = activations.pagination as PaginationDTO;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(ActivationActions.promoteActivation(activation.id));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('PROMOTE_ACTIVATION_CONFIRM_MODAL'));
      this.onFetchActivations(pagination.number, pageSize);
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_ACTIVATION_PROMOTE`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'activations.promoteActivationError',
        }
      }));
    }
  }

  onCreatedActivationRequest(activation: IActivationInDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`CREATED_ACTIVATION_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'forms.warning',
        successMessageKey: 'activations.createdActivationConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onCreatedActivationConfirm(activation),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`CREATED_ACTIVATION_CONFIRM_MODAL`)),
      }
    }));
  }

  async onCreatedActivationConfirm(activation: IActivationInDTO) {
    const { dispatch, activations } = this.props;
    const { pageSize } = this.state;
    const pagination = activations.pagination as PaginationDTO;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(ActivationActions.promoteActivationAdmin(activation.id,ActivationDecoder.ActivationstatusOptions.CREATED));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('CREATED_ACTIVATION_CONFIRM_MODAL'));
      this.onFetchActivations(pagination.number, pageSize);
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_ACTIVATION_CREATED`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'activations.createdActivationError',
        }
      }));
    }
  }

  onInvoicedActivationRequest(activation: IActivationInDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`INVOICED_ACTIVATION_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'forms.warning',
        successMessageKey: 'activations.invoicedActivationConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onInvoicedActivationConfirm(activation),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`INVOICED_ACTIVATION_CONFIRM_MODAL`)),
      }
    }));
  }

  async onInvoicedActivationConfirm(activation: IActivationInDTO) {
    const { dispatch, activations } = this.props;
    const { pageSize } = this.state;
    const pagination = activations.pagination as PaginationDTO;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(ActivationActions.invoicedActivation(activation.id));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('INVOICED_ACTIVATION_CONFIRM_MODAL'));
      this.onFetchActivations(pagination.number, pageSize);
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_ACTIVATION_INVOICED`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'activations.invoicedActivationError',
        }
      }));
    }
  }

  onRejectActivationRequest(activation: IActivationInDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`REJECT_ACTIVATION_CONFIRM_MODAL`, {
      modalType: ModalTypes.TEXTFIELD_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'forms.warning',
        successMessageKey: 'activations.rejectActivationConfirm',
        initialText: "",
        fieldLabel: null,
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: (text: string) => this.onRejectActivationConfirm(activation,text),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`REJECT_ACTIVATION_CONFIRM_MODAL`)),
      }
    }));
  }

  async onRejectActivationConfirm(activation: IActivationInDTO, rejectedNotes: string) {
    const { dispatch, activations } = this.props;
    const { pageSize } = this.state;
    const pagination = activations.pagination as PaginationDTO;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(ActivationActions.promoteActivationAdmin(activation.id,ActivationDecoder.ActivationstatusOptions.REJECTED));
      let rejectedActivation = ActivationDecoder.encodeActivationInFromOut(activation);
      rejectedActivation.rejectedNotes = rejectedNotes;
      await dispatch<any>(ActivationActions.editActivationAdmin(activation.id, rejectedActivation, null));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('REJECT_ACTIVATION_CONFIRM_MODAL'));
      this.onFetchActivations(pagination.number, pageSize);
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_ACTIVATION_REJECT`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'activations.rejectActivationError',
        }
      }));
    }
  }

  onEditCustrefRequest(activation: IActivationInDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`EDIT_CUSTREF_CONFIRM_MODAL`, {
      modalType: ModalTypes.TEXTFIELD_MODAL,
      modalProps: {
        icon: null,
        titleMessageKey: "",
        successMessageKey: 'activations.editCustrefConfirm',
        initialText: activation?.plantRegistry?.custRefID || "",
        fieldLabel: null,
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: (text: string) => this.onEditCustrefConfirm(activation,text),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`EDIT_CUSTREF_CONFIRM_MODAL`)),
      }
    }));
  }

  async onEditCustrefConfirm(activation: IActivationInDTO, custRefId: string) {
    const { dispatch, activations } = this.props;
    const { pageSize } = this.state;
    const pagination = activations.pagination as PaginationDTO;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      let editedActivation = ActivationDecoder.encodeActivationInFromOut(activation);
      if (editedActivation && editedActivation.plantRegistry) {
        editedActivation.plantRegistry.custRefID = custRefId;
      }
      await dispatch<any>(ActivationActions.editActivationAdmin(activation.id, editedActivation, null));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('EDIT_CUSTREF_CONFIRM_MODAL'));
      this.onFetchActivations(pagination.number, pageSize);
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_ACTIVATION_EDIT_CUSTREF`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'activations.editCustrefError',
        }
      }));
    }
  }

  onProcessingActivationRequest(activation: IActivationInDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`PROCESSING_ACTIVATION_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'forms.warning',
        successMessageKey: 'activations.processingActivationConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onProcessingActivationConfirm(activation),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`PROCESSING_ACTIVATION_CONFIRM_MODAL`)),
      }
    }));
  }

  async onProcessingActivationConfirm(activation: IActivationInDTO) {
    const { dispatch, activations } = this.props;
    const { pageSize } = this.state;
    const pagination = activations.pagination as PaginationDTO;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(ActivationActions.promoteActivationAdmin(activation.id,ActivationDecoder.ActivationstatusOptions.PROCESSING));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('PROCESSING_ACTIVATION_CONFIRM_MODAL'));
      this.onFetchActivations(pagination.number, pageSize);
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_ACTIVATION_PROCESSING`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'activations.processingActivationError',
        }
      }));
    }
  }

  onEditStatusAdmin(activation: IActivationInDTO, newStatus: string) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`EDIT_STATUS_ADMIN_ACTIVATION_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'forms.warning',
        successMessageKey: 'activations.editStatusActivationConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onEditStatusAdminConfirm(activation,newStatus),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`EDIT_STATUS_ADMIN_ACTIVATION_CONFIRM_MODAL`)),
      }
    }));
  }

  async onEditStatusAdminConfirm(activation: IActivationInDTO, newStatus: string) {
    const { dispatch, activations } = this.props;
    const { pageSize } = this.state;
    const pagination = activations.pagination as PaginationDTO;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(ActivationActions.promoteActivationAdmin(activation.id,newStatus));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('EDIT_STATUS_ADMIN_ACTIVATION_CONFIRM_MODAL'));
      this.onFetchActivations(pagination.number, pageSize);
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_ACTIVATION_EDIT_STATUS_ADMIN`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'activations.editStatusActivationError',
        }
      }));
    }
  }

  onCreateNewCustomerRequest(activation: IActivationInDTO) {
    const { dispatch } = this.props;
    const { clusters, marketplaces } = this.props;
    // Parso il customer dal DTO
    const clustersData = (clusters as PaginatedDataDTO<IClusterInDTO>).content;
    const marketplacesData = (marketplaces as PaginatedDataDTO<IMarketplaceInDTO>).content;
    const newCustomerData: ICustomerFormDTO = {
      name: activation.plantRegistry.customerName,
      marketplace: activation.plantRegistry.customerMarketplaceId&&marketplacesData.filter(e=>e.id===activation.clusterId).length>0?
        marketplacesData.filter(e=>e.id===activation.plantRegistry.customerMarketplaceId)[0]:null,
      vat: activation.plantRegistry.customerVAT,
      fiscalCode: activation.plantRegistry.customerFiscalCode?activation.plantRegistry.customerFiscalCode:"",
      country: activation.plantRegistry.customerCountry,
      address: activation.plantRegistry.customerAddress,
      city: activation.plantRegistry.customerCity,
      crmId: activation.plantRegistry.CRMID,
      email: activation.plantRegistry.customerEmail,
      certifiedEmail: activation.plantRegistry.customerPEC,
      phone: activation.plantRegistry.customerPhone,
      cluster: activation.clusterId&&clustersData.filter(e=>e.id===activation.clusterId).length>0?
        clustersData.filter(e=>e.id===activation.clusterId)[0]:null,
      company: activation.plantRegistry.iseoCompanyName,
      postalCode: activation.plantRegistry.customerPostalCode,
      principalSubDivision: activation.plantRegistry.customerProvince,
      VAR:null,
    }
    dispatch<any>(ModalsActions.showModal(`CREATE_CUSTOMER_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'credits.newCustomer',
        successMessageKey: 'credits.customerCreationConfirm',
        confirmMessageKey: 'credits.createCustomer',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onCreateNewCustomerConfirm(newCustomerData, activation),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`CREATE_CUSTOMER_CONFIRM_MODAL`)),
      }
    }));
  }

  async onCreateNewCustomerConfirm(newCustomerData: ICustomerFormDTO, activation: IActivationInDTO) {
    const { dispatch, activations } = this.props;
    const { pageSize } = this.state;
    const pagination = activations.pagination as PaginationDTO;
    try {
      dispatch<any>(ModalsActions.hideModal('CREATE_CUSTOMER_CONFIRM_MODAL'));
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      const customerResult: ICustomerInDTO = await dispatch<any>(CreditsActions.createNewCustomer(newCustomerData,undefined));
      if (customerResult&&customerResult.id) {
        let editedActivation = ActivationDecoder.encodeActivationInFromOut(activation);
        editedActivation.plantRegistry.customerId = customerResult.id;
        await dispatch<any>(ActivationActions.editActivationAdmin(activation.id, editedActivation, null));
        this.onFetchActivations(pagination.number, pageSize);
        dispatch<any>(SettingsActions.setSpinnerVisible(false));
      }
      else {
        dispatch<any>(SettingsActions.setSpinnerVisible(false));
        dispatch<any>(ModalsActions.showModal(`ERROR_DOMAIN_CREATION`, {
          modalType: ModalTypes.ERROR_MODAL,
          modalProps: {
            titleMessageKey: 'errors.error',
            errorMessageKey: 'errors.createCustomerError',
          }
        }));
      }
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_DOMAIN_CREATION`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.createCustomerError',
        }
      }));
    }
  }

  onCreateNewWalletRequest(activation: IActivationInDTO) {
    const { dispatch } = this.props;
    if (!activation.plantRegistry.customerId) return;
    if (!activation.plantRegistry.keyAccountManager) return;
    // Parso il wallet dal DTO
    const newWalletData: IWalletFormDTO = {
      name: activation.plantName,
      contactEmail: activation.plantRegistry.keyAccountManager,
      status: IWalletStatusEnum.READY_TO_ACTIVATE,
    }
    dispatch<any>(ModalsActions.showModal(`CREATE_CUSTOMER_WALLET_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'credits.newWallet',
        successMessageKey: 'credits.walletCreationConfirm',
        confirmMessageKey: 'credits.createWallet',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onCreateNewWalletConfirm(newWalletData,activation),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`CREATE_CUSTOMER_WALLET_CONFIRM_MODAL`)),
      }
    }));
  }

  async onCreateNewWalletConfirm(walletData: IWalletFormDTO, activation: IActivationInDTO) {
    const { dispatch, activations } = this.props;
    const { pageSize } = this.state;
    const pagination = activations.pagination as PaginationDTO;
    try {
      dispatch<any>(ModalsActions.hideModal(`CREATE_CUSTOMER_WALLET_CONFIRM_MODAL`));
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      const walletResult: ICustomerInDTO = await dispatch<any>(CreditsActions.createNewCustomerWallet(activation.plantRegistry.customerId, walletData));
      if (walletResult&&walletResult.id) {
        let editedActivation = ActivationDecoder.encodeActivationInFromOut(activation);
        editedActivation.plantRegistry.walletId = walletResult.id;
        await dispatch<any>(ActivationActions.editActivationAdmin(activation.id, editedActivation, null));
        this.onFetchActivations(pagination.number, pageSize);
        dispatch<any>(SettingsActions.setSpinnerVisible(false));
      }
      else {
        dispatch<any>(SettingsActions.setSpinnerVisible(false));
        dispatch<any>(ModalsActions.showModal(`ERROR_WALLET_CREATION`, {
          modalType: ModalTypes.ERROR_MODAL,
          modalProps: {
            titleMessageKey: 'errors.error',
            errorMessageKey: 'errors.createCustomerWalletError',
          }
        }));
      }
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_WALLET_CREATION`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.createCustomerWalletError',
        }
      }));
    }
  }

  onCreateNewScratchcardRequest(activation: IActivationInDTO) {
    const { dispatch } = this.props;
    // Parso la scratchcard dal DTO
    const newCardActionsData: ICardOutSubscription[] = [];
    if (activation.plantRegistry.baselineSubscriptionID) {
       newCardActionsData.push({subscriptionId: activation.plantRegistry.baselineSubscriptionID})
       if (activation.plantRegistry.addons) {
         activation.plantRegistry.addons.forEach((item,i)=>{
           if (item.id)
             newCardActionsData.push({subscriptionId: item.id})
         })
       }
    }
    const newCardData: ICardOutDTO = ({
      varId: activation.plantRegistry.varId?activation.plantRegistry.varId:null,
      plantRequestId: activation.id,
      quantity: activation.plantRegistry.credits*100,
      kamReference: activation.plantRegistry.keyAccountManager,
      iseoSalesOrderReference: activation.plantRegistry.salesOrderConfirmationNumber,
      iseoPurchaseOrderReference: activation.plantRegistry.purchaseOrderNumberSofia,
      sofiaSalesOrderReference: "N/A",
      crmSource: "SFDC_ISEO",
      crmId: activation.optId,
      sofiaProjectId: activation && activation.plantRegistry && activation.plantRegistry.custRefID ? activation.plantRegistry.custRefID : "N/A",
      actions: newCardActionsData
    } as ICardOutDTO)
    dispatch<any>(ModalsActions.showModal(`CREATE_CARD_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'credits.newCard',
        successMessageKey: 'forms.createCardConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onCreateNewScratchcardConfirm(newCardData, activation),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`CREATE_CARD_CONFIRM_MODAL`)),
      }
    }));
  }

  async onCreateNewScratchcardConfirm(card: ICardOutDTO, activation: IActivationInDTO) {
    const { dispatch, activations } = this.props;
    const { pageSize } = this.state;
    const pagination = activations.pagination as PaginationDTO;
    try {
      dispatch<any>(ModalsActions.hideModal('CREATE_CARD_CONFIRM_MODAL'));
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      const cardResult: ICustomerInDTO = await dispatch<any>(CreditsActions.createSystemCard(card));
      if (cardResult && cardResult.id) {
        let editedActivation = ActivationDecoder.encodeActivationInFromOut(activation);
        editedActivation.plantRegistry.scratchcardId = cardResult.id;
        await dispatch<any>(ActivationActions.editActivationAdmin(activation.id, editedActivation, null));
        this.onFetchActivations(pagination.number, pageSize);
        dispatch<any>(SettingsActions.setSpinnerVisible(false));
        dispatch<any>(ModalsActions.showModal(`SUCCESS_ALERT`, {
          modalType: ModalTypes.SUCCESS_MODAL,
          modalProps: {
            titleMessageKey: 'forms.createCardSuccess',
          }
        }));
      }
      else {
        dispatch<any>(SettingsActions.setSpinnerVisible(false));
        dispatch<any>(ModalsActions.showModal(`ERROR_SUBSCRIPTION_CREATE`, {
          modalType: ModalTypes.ERROR_MODAL,
          modalProps: {
            titleMessageKey: 'errors.error',
            errorMessageKey: 'errors.createCardError',
          }
        }));
      }
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_SUBSCRIPTION_CREATE`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.createCardError',
        }
      }));
    }
  }

  async onOpenEditActivationForm(activationToEdit: IActivationInDTO) {
    const { dispatch } = this.props;
    const locked = (activationToEdit.status!==ActivationDecoder.ActivationstatusOptions.DRAFT&&activationToEdit.status!==ActivationDecoder.ActivationstatusOptions.REJECTED)||
      !AbilityProvider.getAbilityHelper().hasPermission(PERMISSIONS.ACTIVATION_REQUEST_EDIT);
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(ActivationActions.fetchActivation(activationToEdit.id));
      await dispatch<any>(ActivationActions.resetCustomersSearch());
      const activationFormData = ActivationDecoder.decodeActivationContentToForm(
        (this.props.activationDetailed as IActivationInDTO),(this.props.clusters as PaginatedDataDTO<IClusterInDTO>).content,
        (this.props.marketplaces as PaginatedDataDTO<IMarketplaceInDTO>).content,
        (this.props.kams as PaginatedDataDTO<IKamInDTO>).content
        // TODO DOPO FIX KAMS (this.props.users as PaginatedDataDTO<IKamInDTO>).content
      );
      dispatch<any>(ModalsActions.showModal(`EDIT_ACTIVATION_MODAL`, {
        modalType: ModalTypes.OPERATIONAL_VIEW_MODAL,
        modalProps: {
          content: (
            <ActivationNewForm
              activation={activationFormData}
              onCreateEditActivation={(newActivationData: IActivationNewFormDTO) => this.onEditActivationConfirm(activationToEdit.id, newActivationData)}
              defaultMarketplace={this.state.defaultMarketplace}
              locked={locked}
            />
          ),
          titleMessageKey: locked?'activations.viewActivation':'activations.editActivation',
          titleLinkText: <ReduxLanguage languageKey={"activations.getInfo"}/>,
          titleLinkUrl: "https://help-center.sofialocks.com/",
        }
      }));
    } catch (error) {
      console.log(error)
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
    }
  }

  public async onEditActivationConfirm(activationId: number, newActivationData: IActivationNewFormDTO) {
    const { dispatch, activations } = this.props;
    const { pageSize } = this.state;
    const pagination = activations.pagination as PaginationDTO;
    try {
      dispatch<any>(ModalsActions.hideModal(`EDIT_ACTIVATION_CONFIRM_MODAL`));
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(ActivationActions.editActivation(activationId, newActivationData));
      this.onFetchActivations(pagination.number, pageSize);
      dispatch<any>(ModalsActions.hideModal('EDIT_ACTIVATION_MODAL'));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_ACTIVATION_EDIT`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'activations.editActivationError',
        }
      }));
    }
  }

  async onOpenNewActivationForm() {
    const { dispatch, VAR } = this.props;
    try {
      const isVar = AbilityProvider.getAbilityHelper().hasRoles(VAR_USERS_ROLES.map(e=>{return e.name}))
      const varData = isVar?(VAR as IVarInDTO):undefined
      await dispatch<any>(ActivationActions.resetCustomersSearch());
      dispatch<any>(ModalsActions.showModal(`CREATE_ACTIVATION_MODAL`, {
        modalType: ModalTypes.OPERATIONAL_VIEW_MODAL_BACKDROP,
        modalProps: {
          content: (
            <ActivationNewForm
              onCreateEditActivation={(newActivationData: IActivationNewFormDTO) => this.onCreateNewActivationConfirm(newActivationData)}
              defaultMarketplace={this.state.defaultMarketplace}
              locked={false}
              forcedVar={varData}
            />
          ),
          titleMessageKey: 'activations.newActivation',
          titleLinkText: <ReduxLanguage languageKey={"activations.getInfo"}/>,
          titleLinkUrl: "https://help-center.sofialocks.com/",
        }
      }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
    }
  }

  public async onCreateNewActivationConfirm(newActivationData: IActivationNewFormDTO) {
    const { dispatch, activations } = this.props;
    const { pageSize } = this.state;
    const pagination = activations.pagination as PaginationDTO;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(ActivationActions.createNewActivation(newActivationData));
      this.onFetchActivations(pagination.number, pageSize);
      dispatch<any>(ModalsActions.hideModal('CREATE_ACTIVATION_MODAL'));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_ACTIVATION_CREATE`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'activations.newActivationError',
        }
      }));
    }
  }

  exportActivationsCSV() {
    const { dispatch } = this.props;
    dispatch<any>(ActivationActions.exportActivationsCSV());
  }

  async onFetchElementsOnPage(page) {
    const { pageSize } = this.state;
    this.onFetchActivations(page, pageSize);
  }

  async onPageSizeChange(pageSize) {
    this.setState({ pageSize, isLoading: true }, async () => {
      this.onFetchActivations(0, pageSize);
    })
  }

  render() {
    const { activations, classes, clusters, vars } = this.props;
    const { pageSize } = this.state;
    const activationsPagination = !_.isEmpty(activations.pagination) ? (activations.pagination as PaginationDTO) : null;
    const varsData = (vars as PaginatedDataDTO<IVarInDTO>)
    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <ActivationSearchBar
            totalElements={activationsPagination?activationsPagination.totalElements:''}
            onCreateNewEntityButtonClick={() => this.onOpenNewActivationForm()}
            onSearch={(searchValues: IActivationsSearchDTO) => this.onSearchActivations(searchValues)}
            onResetSearch={() => this.onResetSearch()}
            onExportCSV={() => this.exportActivationsCSV()}
            clusters={clusters}
          />
        </div>
        <div className={classes.tableContainer}>
          <ActivationsTable
            activations={activations}
            clusters={clusters}
            vars={varsData}
            rowsPerPage={pageSize}
            isLoading={this.state.isLoading}
            onNewActivation={() => this.onOpenNewActivationForm()}
            onEditActivation={(activation) => this.onOpenEditActivationForm(activation)}
            onDeleteActivationRequest={activation => this.onDeleteActivationRequest(activation)}
            onDeleteActivationAdminRequest={activation => this.onDeleteActivationAdminRequest(activation)}
            onPromoteActivationRequest={activation => this.onPromoteActivationRequest(activation)}
            onCreatedActivationRequest={activation => this.onCreatedActivationRequest(activation)}
            onRejectActivationRequest={activation => this.onRejectActivationRequest(activation)}
            onProcessingActivationRequest={activation => this.onProcessingActivationRequest(activation)}
            onInvoicedActivationRequest={activation => this.onInvoicedActivationRequest(activation)}
            onEditCustrefRequest={activation => this.onEditCustrefRequest(activation)}
            onEditStatusAdmin={(activation,newStatus) => this.onEditStatusAdmin(activation,newStatus)}
            onCreateNewCustomerRequest={activation => this.onCreateNewCustomerRequest(activation)}
            onCreateNewWalletRequest={activation => this.onCreateNewWalletRequest(activation)}
            onCreateNewScratchcardRequest={activation => this.onCreateNewScratchcardRequest(activation)}
            onFetchElementsOnPage={page => this.onFetchElementsOnPage(page)}
            onPageSizeChange={pageSize => this.onPageSizeChange(pageSize)}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    language: state.settings.language,
    selectedMarketplace: state.credits.marketplaces.selectedMarketplace,
    activations: state.activations.activations.data,
    activationDetailed: state.activations.activationDetailed.data,
    clusters: state.activations.clusters.data,
    marketplaces: state.activations.marketplaces.data,
    kams: state.activations.kams.data,
    vars: state.vars.vars.data,
    VAR: state.vars.VAR.data,
    users: state.user.users.data,
    usersRoles: state.user.usersRoles.data,
  };
}

const connector = connect(mapStateToProps);

export default connector(withStyles(styles)(ActivationRequestsPage as any));