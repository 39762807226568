import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import React from 'react';
import ReduxLanguage from '../ReduxLanguage';
import { IModalViewProps } from './ModalTypes';


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export interface ContentModalProps {
  titleMessageKey?: string;
  successMessageKey: string;
  confirmMessageKey?: string;
  cancelMessageKey?: string;
  icon?: JSX.Element;
  content: JSX.Element;
  onConfirm?: () => void;
  onCancel?: () => void;
};

export default class ContentModal extends React.Component<IModalViewProps<ContentModalProps>> {

  render(): React.ReactNode {
    if (!this.props.modalProps) return;
    const { content, cancelMessageKey, onConfirm, confirmMessageKey } = this.props.modalProps;
    return (
      <Dialog open onClose={() => this.handleClose()} TransitionComponent={Transition}>
        <div style={{ padding: 20,  flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {content}
          {onConfirm ? (
            <Button
              color="primary"
              variant="contained"
              style={{ marginTop: 20, backgroundColor: '#5AC0B1', width: 250, borderRadius: 100 }}
              onClick={() => onConfirm()}
            >
            <ReduxLanguage languageKey={confirmMessageKey || 'forms.save'}/>
          </Button>
        ) : null}
          <Button
            color="primary"
            variant="contained"
            style={{ marginTop: 20, backgroundColor: 'lightgray', width: 250, borderRadius: 100 }}
            onClick={() => this.handleClose()}
          >
            <ReduxLanguage languageKey={cancelMessageKey || 'forms.close'}/>
          </Button>
          
        </div>
      </Dialog>
    );
  }

  private handleClose = (): void => {
    const {hideModal, modalProps} = this.props;
    hideModal();
    if (modalProps && modalProps.onCancel) {
      modalProps.onCancel();
    }
  }
}
