import { PERMISSIONS } from './PermissionConstants';
import _ from 'lodash';

export default class PermissionsParser {
  parse(roles: string[]) {
    let permissions = [];
    roles.forEach((role,i)=>{permissions = [...permissions,...RolesPermissionMap[role]]});
    return _.uniq(permissions)
  }
}

export const RoleEntities = {
  NAVIGATION: 'NAVIGATION',
  CLUSTER: 'CLUSTER',
  VAR: 'VAR',
  CUSTOMER: 'CUSTOMER',
  OPPORTUNITY: 'OPPORTUNITY',
  WALLET: 'WALLET',
  DOMAIN: 'DOMAIN',
  GATEWAYS: 'GATEWAYS',
  CARD: 'CARD',
  PLANT_ACTIVATION: 'PLANT_ACTIVATION',
  RECHARGE_REQUEST: 'RECHARGE_REQUEST',
  ACTIVATION_REQUEST: 'ACTIVATION_REQUEST',
}

export const RolesPermissionMap = {
  ROLE_VASHO_SOFIA_ADMIN: [
    PERMISSIONS.NAVIGATION_VAR_MANAGEMENT,
    PERMISSIONS.NAVIGATION_MARKETPLACES,
    PERMISSIONS.NAVIGATION_CUSTOMERS,
    PERMISSIONS.NAVIGATION_OPPORTUNITIES,
    PERMISSIONS.NAVIGATION_DOMAINS,
    PERMISSIONS.NAVIGATION_PLANT_ACTIVATIONS,
    PERMISSIONS.NAVIGATION_RECHARGE_REQUESTS,
    PERMISSIONS.NAVIGATION_ORDERS_STATUS,
    PERMISSIONS.NAVIGATION_GATEWAYS,
    PERMISSIONS.NAVIGATION_PRICELIST,
    PERMISSIONS.NAVIGATION_CARDS,
    PERMISSIONS.NAVIGATION_SALESINFO,
    PERMISSIONS.NAVIGATION_PRODUCTINFO,
    PERMISSIONS.NAVIGATION_PLATFORM_USERS,
    PERMISSIONS.CLUSTER_FILTER,
    PERMISSIONS.VAR_READ,
    PERMISSIONS.VAR_USER_READ,
    PERMISSIONS.VAR_CREATE,
    PERMISSIONS.VAR_UPDATE,
    PERMISSIONS.VAR_USER_CREATE,
    PERMISSIONS.CUSTOMER_READ,
    PERMISSIONS.CUSTOMER_DELETE_REQUEST,
    PERMISSIONS.CUSTOMER_EXPORT,
    PERMISSIONS.CUSTOMER_CREATE,
    PERMISSIONS.CUSTOMER_UPDATE,
    PERMISSIONS.CUSTOMER_DELETE,
    PERMISSIONS.OPPORTUNITY_READ,
    PERMISSIONS.OPPORTUNITY_EDIT,
    PERMISSIONS.OPPORTUNITY_CREATE,
    PERMISSIONS.OPPORTUNITY_UPDATE,
    PERMISSIONS.OPPORTUNITY_UPDATE_ADMIN,
    PERMISSIONS.OPPORTUNITY_EXPORT,
    PERMISSIONS.OPPORTUNITY_APPROVE,
    PERMISSIONS.OPPORTUNITY_REJECT,
    PERMISSIONS.OPPORTUNITY_SYNC_SFDCID,
    PERMISSIONS.OPPORTUNITY_HISTORY_READ,
    PERMISSIONS.OPPORTUNITY_MARK_LOST,
    PERMISSIONS.OPPORTUNITY_MARK_WON,
    PERMISSIONS.OPPORTUNITY_CANCEL_DRAFT,
    PERMISSIONS.OPPORTUNITY_CANCEL_ON_APPROVAL,
    PERMISSIONS.OPPORTUNITY_CANCEL_APPROVED,
    PERMISSIONS.OPPORTUNITY_MARK_TO_BE_APPROVED,
    PERMISSIONS.OPPORTUNITY_CHANGE_OWNER,
    PERMISSIONS.WALLET_CREATE,
    PERMISSIONS.WALLET_EXPORT,
    PERMISSIONS.WALLET_READ,
    PERMISSIONS.WALLET_UPDATE,
    PERMISSIONS.WALLET_RECHARGE,
    PERMISSIONS.WALLET_DELETE_REQUEST,
    PERMISSIONS.WALLET_SUBSCRIPTION_UPDATE,
    PERMISSIONS.WALLET_SUBSCRIPTION_DELETE,
    PERMISSIONS.DOMAIN_READ,
    PERMISSIONS.DOMAIN_CREATE,
    PERMISSIONS.DOMAIN_EXPORT_ALL_MANAGERS,
    PERMISSIONS.DOMAIN_ADDITIONAL_INFO_UPDATE,
    PERMISSIONS.DOMAIN_MANAGER_CREATE,
    PERMISSIONS.DOMAIN_MANAGER_UPDATE,
    PERMISSIONS.DOMAIN_INSTALLER_CREATE,
    PERMISSIONS.DOMAIN_INSTALLER_UPDATE,
    PERMISSIONS.DOMAIN_IMPERSONATE_WRITE,
    PERMISSIONS.DOMAIN_IMPERSONATE_SUPPORT,
    PERMISSIONS.DOMAIN_EXTEND,
    PERMISSIONS.DOMAIN_CONFIG,
    PERMISSIONS.DOMAIN_DELETE_REQUEST,
    PERMISSIONS.DOMAIN_IRIS_ENABLE,
    PERMISSIONS.DOMAIN_LEGACY_DOMAINS_READ,
    PERMISSIONS.GATEWAYS,
    PERMISSIONS.CARD_CREATE,
    PERMISSIONS.CARD_UPDATE,
    PERMISSIONS.ACTIVATION_REQUEST_EXPORT,
    PERMISSIONS.ACTIVATION_REQUEST_READ,
    PERMISSIONS.ACTIVATION_REQUEST_CREATE,
    PERMISSIONS.ACTIVATION_REQUEST_EDIT,
    PERMISSIONS.RECHARGE_REQUEST_EXPORT,
    PERMISSIONS.RECHARGE_REQUEST_READ,
    PERMISSIONS.RECHARGE_REQUEST_CREATE,
    PERMISSIONS.RECHARGE_REQUEST_EDIT,
    PERMISSIONS.RECHARGE_REQUEST_EDIT_ADMIN,
    PERMISSIONS.ACTIVATION_EDIT_ADMIN,
    PERMISSIONS.KAM_READ,
    PERMISSIONS.CUSTOMER_SEARCH_BY_DOMAIN,
  ],
  ROLE_VASHO_GLOBAL_SEGMENT_LEADER: [
    PERMISSIONS.NAVIGATION_VAR_MANAGEMENT,
    PERMISSIONS.NAVIGATION_CUSTOMERS,
    PERMISSIONS.NAVIGATION_CARDS,
    PERMISSIONS.NAVIGATION_OPPORTUNITIES,
    PERMISSIONS.NAVIGATION_PLANT_ACTIVATIONS,
    PERMISSIONS.NAVIGATION_RECHARGE_REQUESTS,
    PERMISSIONS.NAVIGATION_ORDERS_STATUS,
    PERMISSIONS.NAVIGATION_PRODUCTINFO,
    PERMISSIONS.CLUSTER_FILTER,
    PERMISSIONS.VAR_READ,
    PERMISSIONS.VAR_USER_READ,
    PERMISSIONS.CUSTOMER_READ,
    PERMISSIONS.OPPORTUNITY_READ,
    PERMISSIONS.OPPORTUNITY_APPROVE,
    PERMISSIONS.OPPORTUNITY_REJECT,
    PERMISSIONS.OPPORTUNITY_SYNC_SFDCID,
    PERMISSIONS.OPPORTUNITY_HISTORY_READ,
    PERMISSIONS.OPPORTUNITY_CANCEL_ON_APPROVAL,
    PERMISSIONS.OPPORTUNITY_CHANGE_OWNER,
    PERMISSIONS.WALLET_READ,
    PERMISSIONS.DOMAIN_READ,
    PERMISSIONS.ACTIVATION_REQUEST_READ,
    PERMISSIONS.RECHARGE_REQUEST_READ,
    PERMISSIONS.KAM_READ,
  ],
  ROLE_VASHO_GLOBAL_SALES_OPERATION_MANAGER: [
    PERMISSIONS.NAVIGATION_VAR_MANAGEMENT,
    PERMISSIONS.NAVIGATION_CUSTOMERS,
    PERMISSIONS.NAVIGATION_CARDS,
    PERMISSIONS.NAVIGATION_OPPORTUNITIES,
    PERMISSIONS.NAVIGATION_PLANT_ACTIVATIONS,
    PERMISSIONS.NAVIGATION_RECHARGE_REQUESTS,
    PERMISSIONS.NAVIGATION_ORDERS_STATUS,
    PERMISSIONS.NAVIGATION_PRODUCTINFO,
    PERMISSIONS.CLUSTER_FILTER,
    PERMISSIONS.CUSTOMER_READ,
    PERMISSIONS.VAR_READ,
    PERMISSIONS.VAR_USER_READ,
    PERMISSIONS.OPPORTUNITY_READ,
    PERMISSIONS.OPPORTUNITY_APPROVE,
    PERMISSIONS.OPPORTUNITY_REJECT,
    PERMISSIONS.OPPORTUNITY_SYNC_SFDCID,
    PERMISSIONS.OPPORTUNITY_HISTORY_READ,
    PERMISSIONS.OPPORTUNITY_CANCEL_ON_APPROVAL,
    PERMISSIONS.OPPORTUNITY_CHANGE_OWNER,
    PERMISSIONS.WALLET_READ,
    PERMISSIONS.DOMAIN_READ,
    PERMISSIONS.ACTIVATION_REQUEST_READ,
    PERMISSIONS.ACTIVATION_REQUEST_CREATE,
    PERMISSIONS.ACTIVATION_REQUEST_EDIT,
    PERMISSIONS.ACTIVATION_REQUEST_MARK_UNDER_REVIEW,
    PERMISSIONS.ACTIVATION_REQUEST_INVOICED,
    PERMISSIONS.ACTIVATION_REQUEST_DELETE,
    PERMISSIONS.RECHARGE_REQUEST_READ,
    PERMISSIONS.RECHARGE_REQUEST_CREATE,
    PERMISSIONS.RECHARGE_REQUEST_EDIT,
    PERMISSIONS.RECHARGE_REQUEST_TAKE_CHARGE,
    PERMISSIONS.RECHARGE_REQUEST_SELLOUT_GENERATED,
    PERMISSIONS.RECHARGE_REQUEST_INVOICED,
    PERMISSIONS.RECHARGE_REQUEST_DELETE,
    PERMISSIONS.KAM_READ,
  ],
  ROLE_VASHO_SALES_OPERATION_MANAGER: [
    PERMISSIONS.NAVIGATION_VAR_MANAGEMENT,
    PERMISSIONS.NAVIGATION_CUSTOMERS,
    PERMISSIONS.NAVIGATION_CARDS,
    PERMISSIONS.NAVIGATION_OPPORTUNITIES,
    PERMISSIONS.NAVIGATION_PLANT_ACTIVATIONS,
    PERMISSIONS.NAVIGATION_RECHARGE_REQUESTS,
    PERMISSIONS.NAVIGATION_ORDERS_STATUS,
    PERMISSIONS.NAVIGATION_PRODUCTINFO,
    PERMISSIONS.CUSTOMER_READ,
    PERMISSIONS.VAR_READ,
    PERMISSIONS.VAR_USER_READ,
    PERMISSIONS.OPPORTUNITY_READ,
    PERMISSIONS.OPPORTUNITY_APPROVE,
    PERMISSIONS.OPPORTUNITY_REJECT,
    PERMISSIONS.OPPORTUNITY_SYNC_SFDCID,
    PERMISSIONS.OPPORTUNITY_HISTORY_READ,
    PERMISSIONS.OPPORTUNITY_CANCEL_ON_APPROVAL,
    PERMISSIONS.OPPORTUNITY_CHANGE_OWNER,
    PERMISSIONS.ACTIVATION_REQUEST_READ,
    PERMISSIONS.ACTIVATION_REQUEST_CREATE,
    PERMISSIONS.ACTIVATION_REQUEST_EDIT,
    PERMISSIONS.ACTIVATION_REQUEST_MARK_UNDER_REVIEW,
    PERMISSIONS.ACTIVATION_REQUEST_INVOICED,
    PERMISSIONS.ACTIVATION_REQUEST_DELETE,
    PERMISSIONS.RECHARGE_REQUEST_READ,
    PERMISSIONS.RECHARGE_REQUEST_CREATE,
    PERMISSIONS.RECHARGE_REQUEST_EDIT,
    PERMISSIONS.RECHARGE_REQUEST_TAKE_CHARGE,
    PERMISSIONS.RECHARGE_REQUEST_SELLOUT_GENERATED,
    PERMISSIONS.RECHARGE_REQUEST_INVOICED,
    PERMISSIONS.RECHARGE_REQUEST_DELETE,
    PERMISSIONS.KAM_READ,
    PERMISSIONS.WALLET_READ,
    PERMISSIONS.DOMAIN_READ,
  ],
  ROLE_VASHO_MARKET_LEADER: [
    PERMISSIONS.NAVIGATION_VAR_MANAGEMENT,
    PERMISSIONS.NAVIGATION_CUSTOMERS,
    PERMISSIONS.NAVIGATION_OPPORTUNITIES,
    PERMISSIONS.NAVIGATION_PLANT_ACTIVATIONS,
    PERMISSIONS.NAVIGATION_RECHARGE_REQUESTS,
    PERMISSIONS.NAVIGATION_ORDERS_STATUS,
    PERMISSIONS.NAVIGATION_PRODUCTINFO,
    PERMISSIONS.VAR_READ,
    PERMISSIONS.VAR_USER_READ,
    PERMISSIONS.CUSTOMER_READ,
    PERMISSIONS.OPPORTUNITY_READ,
    PERMISSIONS.OPPORTUNITY_APPROVE,
    PERMISSIONS.OPPORTUNITY_REJECT,
    PERMISSIONS.OPPORTUNITY_SYNC_SFDCID,
    PERMISSIONS.OPPORTUNITY_HISTORY_READ,
    PERMISSIONS.OPPORTUNITY_CANCEL_ON_APPROVAL,
    PERMISSIONS.OPPORTUNITY_CHANGE_OWNER,
    PERMISSIONS.WALLET_READ,
    PERMISSIONS.DOMAIN_READ,
    PERMISSIONS.ACTIVATION_REQUEST_READ,
    PERMISSIONS.RECHARGE_REQUEST_READ,
    PERMISSIONS.KAM_READ,
  ],
  ROLE_VASHO_EUSM: [
    PERMISSIONS.NAVIGATION_VAR_MANAGEMENT,
    PERMISSIONS.NAVIGATION_CUSTOMERS,
    PERMISSIONS.NAVIGATION_OPPORTUNITIES,
    PERMISSIONS.NAVIGATION_PLANT_ACTIVATIONS,
    PERMISSIONS.NAVIGATION_RECHARGE_REQUESTS,
    PERMISSIONS.NAVIGATION_ORDERS_STATUS,
    PERMISSIONS.NAVIGATION_PRODUCTINFO,
    PERMISSIONS.VAR_READ,
    PERMISSIONS.VAR_USER_READ,
    PERMISSIONS.CUSTOMER_READ,
    PERMISSIONS.OPPORTUNITY_READ,
    PERMISSIONS.OPPORTUNITY_APPROVE,
    PERMISSIONS.OPPORTUNITY_REJECT,
    PERMISSIONS.OPPORTUNITY_SYNC_SFDCID,
    PERMISSIONS.OPPORTUNITY_HISTORY_READ,
    PERMISSIONS.OPPORTUNITY_CANCEL_ON_APPROVAL,
    PERMISSIONS.OPPORTUNITY_CHANGE_OWNER,
    PERMISSIONS.WALLET_READ,
    PERMISSIONS.DOMAIN_READ,
    PERMISSIONS.ACTIVATION_REQUEST_READ,
    PERMISSIONS.RECHARGE_REQUEST_READ,
    PERMISSIONS.KAM_READ,
  ],
  ROLE_VASHO_KAM: [
    PERMISSIONS.NAVIGATION_VAR_MANAGEMENT,
    PERMISSIONS.NAVIGATION_CUSTOMERS,
    PERMISSIONS.NAVIGATION_OPPORTUNITIES,
    PERMISSIONS.NAVIGATION_PLANT_ACTIVATIONS,
    PERMISSIONS.NAVIGATION_RECHARGE_REQUESTS,
    PERMISSIONS.NAVIGATION_ORDERS_STATUS,
    PERMISSIONS.NAVIGATION_PRODUCTINFO,
    PERMISSIONS.VAR_READ,
    PERMISSIONS.VAR_USER_READ,
    PERMISSIONS.CUSTOMER_READ,
    PERMISSIONS.OPPORTUNITY_READ,
    PERMISSIONS.OPPORTUNITY_APPROVE,
    PERMISSIONS.OPPORTUNITY_REJECT,
    PERMISSIONS.WALLET_READ,
    PERMISSIONS.ACTIVATION_REQUEST_READ,
    PERMISSIONS.RECHARGE_REQUEST_READ,
    PERMISSIONS.KAM_READ,
  ],
  ROLE_VASHO_GLOBAL_SOC_MANAGER: [
    PERMISSIONS.NAVIGATION_VAR_MANAGEMENT,
    PERMISSIONS.NAVIGATION_CUSTOMERS,
    PERMISSIONS.NAVIGATION_PLANT_ACTIVATIONS,
    PERMISSIONS.NAVIGATION_PRODUCTINFO,
    PERMISSIONS.NAVIGATION_GATEWAYS,
    PERMISSIONS.NAVIGATION_DOMAINS,
    PERMISSIONS.CLUSTER_FILTER,
    PERMISSIONS.VAR_READ,
    PERMISSIONS.VAR_USER_READ,
    PERMISSIONS.CUSTOMER_READ,
    PERMISSIONS.DOMAIN_ADDITIONAL_INFO_UPDATE,
    PERMISSIONS.DOMAIN_MANAGER_CREATE,
    PERMISSIONS.DOMAIN_MANAGER_UPDATE,
    PERMISSIONS.DOMAIN_INSTALLER_CREATE,
    PERMISSIONS.DOMAIN_INSTALLER_UPDATE,
    PERMISSIONS.DOMAIN_IMPERSONATE_WRITE,
    PERMISSIONS.DOMAIN_IMPERSONATE_SUPPORT,
    PERMISSIONS.DOMAIN_READ,
    PERMISSIONS.DOMAIN_LEGACY_DOMAINS_READ,
    PERMISSIONS.ACTIVATION_REQUEST_READ,
    PERMISSIONS.GATEWAYS,
    PERMISSIONS.KAM_READ,
  ],
  ROLE_VASHO_LOCAL_SOC_MANAGER: [
    PERMISSIONS.NAVIGATION_VAR_MANAGEMENT,
    PERMISSIONS.NAVIGATION_CUSTOMERS,
    PERMISSIONS.NAVIGATION_PLANT_ACTIVATIONS,
    PERMISSIONS.NAVIGATION_PRODUCTINFO,
    PERMISSIONS.NAVIGATION_GATEWAYS,
    PERMISSIONS.NAVIGATION_DOMAINS,
    PERMISSIONS.VAR_READ,
    PERMISSIONS.VAR_USER_READ,
    PERMISSIONS.CUSTOMER_READ,
    PERMISSIONS.DOMAIN_ADDITIONAL_INFO_UPDATE,
    PERMISSIONS.DOMAIN_MANAGER_CREATE,
    PERMISSIONS.DOMAIN_MANAGER_UPDATE,
    PERMISSIONS.DOMAIN_INSTALLER_CREATE,
    PERMISSIONS.DOMAIN_INSTALLER_UPDATE,
    PERMISSIONS.DOMAIN_IMPERSONATE_WRITE,
    PERMISSIONS.DOMAIN_IMPERSONATE_SUPPORT,
    PERMISSIONS.DOMAIN_LEGACY_DOMAINS_READ,
    PERMISSIONS.DOMAIN_READ,
    PERMISSIONS.ACTIVATION_REQUEST_READ,
    PERMISSIONS.GATEWAYS,
    PERMISSIONS.KAM_READ,
  ],
  ROLE_VASHO_VAR_ADMIN: [
    PERMISSIONS.NAVIGATION_CUSTOMERS,
    PERMISSIONS.NAVIGATION_OPPORTUNITIES,
    PERMISSIONS.NAVIGATION_CARDS,
    PERMISSIONS.NAVIGATION_PRODUCTINFO,
    PERMISSIONS.NAVIGATION_VAR_USERS,
    PERMISSIONS.NAVIGATION_VAR_SETTINGS,
    PERMISSIONS.NAVIGATION_DOMAINS,
    PERMISSIONS.VAR_USER_READ,
    PERMISSIONS.VAR_USER_UPDATE,
    PERMISSIONS.VAR_USER_CREATE,
    PERMISSIONS.VAR_PLANT_THEME,
    PERMISSIONS.CUSTOMER_READ,
    PERMISSIONS.CUSTOMER_DELETE_REQUEST,
    PERMISSIONS.CUSTOMER_CREATE,
    PERMISSIONS.CUSTOMER_UPDATE,
    PERMISSIONS.WALLET_CREATE,
    PERMISSIONS.WALLET_EXPORT,
    PERMISSIONS.WALLET_UPDATE,
    PERMISSIONS.WALLET_RECHARGE,
    PERMISSIONS.WALLET_DELETE_REQUEST,
    PERMISSIONS.WALLET_SUBSCRIPTION_UPDATE,
    PERMISSIONS.WALLET_SUBSCRIPTION_DELETE,
    PERMISSIONS.DOMAIN_READ,
    PERMISSIONS.DOMAIN_CREATE,
    PERMISSIONS.DOMAIN_ADDITIONAL_INFO_UPDATE,
    PERMISSIONS.DOMAIN_MANAGER_CREATE,
    PERMISSIONS.DOMAIN_MANAGER_UPDATE,
    PERMISSIONS.DOMAIN_INSTALLER_CREATE,
    PERMISSIONS.DOMAIN_INSTALLER_UPDATE,
    PERMISSIONS.DOMAIN_DELETE_REQUEST,
    PERMISSIONS.DOMAIN_IMPERSONATE_WRITE,
    PERMISSIONS.DOMAIN_IMPERSONATE_SUPPORT,
    PERMISSIONS.OPPORTUNITY_EXPORT,
    PERMISSIONS.OPPORTUNITY_READ,
    PERMISSIONS.OPPORTUNITY_EDIT,
    PERMISSIONS.OPPORTUNITY_CREATE,
    PERMISSIONS.OPPORTUNITY_UPDATE,
    PERMISSIONS.OPPORTUNITY_MARK_LOST,
    PERMISSIONS.OPPORTUNITY_MARK_WON,
    PERMISSIONS.OPPORTUNITY_CANCEL_DRAFT,
    PERMISSIONS.OPPORTUNITY_CANCEL_ON_APPROVAL,
    PERMISSIONS.OPPORTUNITY_CANCEL_APPROVED,
    PERMISSIONS.OPPORTUNITY_MARK_TO_BE_APPROVED,
    PERMISSIONS.OPPORTUNITY_CHANGE_OWNER,
  ],
  ROLE_VASHO_VAR_SUPPORT: [
    PERMISSIONS.NAVIGATION_CUSTOMERS,
    PERMISSIONS.NAVIGATION_CARDS,
    PERMISSIONS.NAVIGATION_OPPORTUNITIES,
    PERMISSIONS.NAVIGATION_PRODUCTINFO,
    PERMISSIONS.NAVIGATION_DOMAINS,
    PERMISSIONS.CUSTOMER_READ,
    PERMISSIONS.WALLET_EXPORT,
    PERMISSIONS.WALLET_RECHARGE,
    PERMISSIONS.DOMAIN_ADDITIONAL_INFO_UPDATE,
    PERMISSIONS.DOMAIN_MANAGER_CREATE,
    PERMISSIONS.DOMAIN_MANAGER_UPDATE,
    PERMISSIONS.DOMAIN_INSTALLER_CREATE,
    PERMISSIONS.DOMAIN_INSTALLER_UPDATE,
    PERMISSIONS.DOMAIN_DELETE_REQUEST,
    PERMISSIONS.DOMAIN_IMPERSONATE_WRITE,
    PERMISSIONS.DOMAIN_IMPERSONATE_SUPPORT,
    PERMISSIONS.DOMAIN_READ,
    PERMISSIONS.OPPORTUNITY_READ,
    PERMISSIONS.OPPORTUNITY_EDIT,
    PERMISSIONS.OPPORTUNITY_CREATE,
    PERMISSIONS.OPPORTUNITY_UPDATE,
    PERMISSIONS.OPPORTUNITY_MARK_LOST,
    PERMISSIONS.OPPORTUNITY_MARK_WON,
    PERMISSIONS.OPPORTUNITY_CANCEL_DRAFT,
    PERMISSIONS.OPPORTUNITY_CANCEL_ON_APPROVAL,
    PERMISSIONS.OPPORTUNITY_CANCEL_APPROVED,
    PERMISSIONS.OPPORTUNITY_MARK_TO_BE_APPROVED,
  ],
  ROLE_VASHO_VAR_SALES_MANAGER: [
    PERMISSIONS.NAVIGATION_CUSTOMERS,
    PERMISSIONS.NAVIGATION_OPPORTUNITIES,
    PERMISSIONS.NAVIGATION_CARDS,
    PERMISSIONS.NAVIGATION_PRODUCTINFO,
    PERMISSIONS.VAR_PLANT_THEME,
    PERMISSIONS.CUSTOMER_CREATE,
    PERMISSIONS.CUSTOMER_READ,
    PERMISSIONS.WALLET_READ,
    PERMISSIONS.WALLET_EXPORT,
    PERMISSIONS.WALLET_RECHARGE,
    PERMISSIONS.DOMAIN_READ,
    PERMISSIONS.OPPORTUNITY_READ,
    PERMISSIONS.OPPORTUNITY_EDIT,
    PERMISSIONS.OPPORTUNITY_CREATE,
    PERMISSIONS.OPPORTUNITY_UPDATE,
    PERMISSIONS.OPPORTUNITY_MARK_LOST,
    PERMISSIONS.OPPORTUNITY_MARK_WON,
    PERMISSIONS.OPPORTUNITY_CANCEL_DRAFT,
    PERMISSIONS.OPPORTUNITY_CANCEL_ON_APPROVAL,
    PERMISSIONS.OPPORTUNITY_CANCEL_APPROVED,
    PERMISSIONS.OPPORTUNITY_MARK_TO_BE_APPROVED,
    PERMISSIONS.VAR_USER_READ,
    PERMISSIONS.OPPORTUNITY_CHANGE_OWNER,
  ],
  ROLE_VASHO_VAR_SALES_REPRESENTATIVE: [
    PERMISSIONS.NAVIGATION_CUSTOMERS,
    PERMISSIONS.NAVIGATION_OPPORTUNITIES,
    PERMISSIONS.NAVIGATION_CARDS,
    PERMISSIONS.NAVIGATION_PRODUCTINFO,
    PERMISSIONS.OPPORTUNITY_READ,
    PERMISSIONS.OPPORTUNITY_EDIT,
    PERMISSIONS.OPPORTUNITY_CREATE,
    PERMISSIONS.OPPORTUNITY_UPDATE,
    PERMISSIONS.OPPORTUNITY_MARK_LOST,
    PERMISSIONS.OPPORTUNITY_MARK_WON,
    PERMISSIONS.OPPORTUNITY_CANCEL_DRAFT,
    PERMISSIONS.OPPORTUNITY_CANCEL_ON_APPROVAL,
    PERMISSIONS.OPPORTUNITY_CANCEL_APPROVED,
    PERMISSIONS.OPPORTUNITY_MARK_TO_BE_APPROVED,
    PERMISSIONS.DOMAIN_READ,
    PERMISSIONS.WALLET_READ,
    PERMISSIONS.CUSTOMER_READ,
  ]
}