import { AppBar, Card, Divider, IconButton, Tab, Tabs } from "@material-ui/core";
import { WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import { Edit, PublicOutlined } from "@material-ui/icons";
import AssignmentIndIcon from '@material-ui/icons/AssignmentIndOutlined';
import CustomersIcon from '@material-ui/icons/GroupOutlined';
import PersonAddIcon from '@material-ui/icons/PersonAddOutlined';
import * as React from "react";
import { ConnectedProps, connect } from "react-redux";
import ReduxLanguage from '../../components/ReduxLanguage';
import { IState } from "../../redux/store";
import { IVarInDTO } from "../../repository/jago/model/input/IVarInDTO";
import VarCustomersSection from "./VarCustomersSection";
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import VarUsersSection from "./VarUsersSection";
import VarOpportunitiesSection from "./VarOpportunitiesSection";
import AbilityProvider from "../../services/PermissionService/AbilityProvider";
import { PERMISSIONS, Roles } from "../../services/PermissionService/PermissionConstants";
import { PaginatedDataDTO } from "@bottega52/commons-pagination";
import { IVarOpportunityInDTO } from "../../repository/jago/model/input/IVarOpportunityInDTO";
import Permission from "../Permission/Permission";
import IClusterInDTO from "../../repository/jago/model/input/IClusterInDTO";
import IMarketplaceInDTO from "../../repository/jago/model/input/IMarketplaceInDTO";

const styles = createStyles({
  container: {
    display: 'flex',
    margin: 10,
    flexDirection: 'column',
    paddingBottom: 50,
  },
  headerContainer: {
    marginTop: 20,
    padding: 10,
    borderLeft: '3px solid #0D47A1',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  headerInner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerTextContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    marginRight: 5,
    fontSize: 25,
  },
  title: {
    fontWeight: 'bold'
  },
  sectionHeader: {
    fontWeight: 'bold'
  },
  sectionContent: {

  },
});

type IReduxProps = ConnectedProps<typeof connector>;

export interface IVarInfoDetailsViewProps extends WithStyles<typeof styles>{
}

export interface IVarInfoDetailsViewState {
}

export type ComponentProps = IVarInfoDetailsViewProps & IReduxProps;
class VarInfoDetailsView extends React.Component<ComponentProps, IVarInfoDetailsViewState> {


  public render() {
    const { selectedVar, marketplaces, clusters, classes } = this.props;
    const VAR = (selectedVar as IVarInDTO);
    const clustersData = clusters as PaginatedDataDTO<IClusterInDTO>;
    const clustersContent= clustersData.content ? clustersData.content:[]
    const marketplacesData = (marketplaces as PaginatedDataDTO<IMarketplaceInDTO>).content;
    const marketPlace = _.find(marketplacesData, marketplace => marketplace.id === VAR.marketplaceId);
    const varCluster = VAR.clusterId && clustersContent.filter(e=>e.id===VAR.clusterId).length>0 ? clustersContent.filter(e=> e.id === VAR.clusterId)[0] : undefined;
    return (
      <div className={classes.container}>
        <Card className={classes.headerContainer}>
          <div className={classes.header}>
            <div className={classes.headerInner}>
              <AssignmentIndIcon className={classes.icon} />
              <div className={classes.headerTextContainer}>
                <h2 className={classes.title}>{VAR && VAR.name ? `${VAR.id} - ${VAR.name}` : ''}</h2>
              </div>
            </div>
          </div>
          <div style={{ marginTop: 15 }}>
            <h3 className={classes.sectionHeader}>{<ReduxLanguage languageKey={'forms.email'} />}</h3>
            <h3 className={classes.sectionContent}>{VAR.contactEmail || '---'}</h3>
          </div>
          <div style={{ marginTop: 15 }}>
            <h3 className={classes.sectionHeader}>{<ReduxLanguage languageKey={'vars.varCluster'} />}</h3>
            {varCluster ? <h3><>[{varCluster.market}] - {varCluster.name}</></h3> : '---'}
          </div>
          <div style={{ marginTop: 15 }}>
            <h3 className={classes.sectionHeader}>{<ReduxLanguage languageKey={'vars.varPhone'} />}</h3>
            <h3 className={classes.sectionContent}>{VAR.contactPhone || '---'}</h3>
          </div>
          <div style={{ marginTop: 15 }}>
            <h3 className={classes.sectionHeader}>{<ReduxLanguage languageKey={'vars.varAddress'} />}</h3>
            <h3 className={classes.sectionContent}>{VAR.address || '---'}</h3>
          </div>
          <div style={{ marginTop: 15 }}>
            <h3 className={classes.sectionHeader}>{<ReduxLanguage languageKey={'vars.varVat'} />}</h3>
            <h3 className={classes.sectionContent}>{VAR.vat || '---'}</h3>
          </div>
          <Divider style={{ marginTop: 15 }} />
          <div style={{ marginTop: 15 }}>
            <h3 className={classes.sectionHeader}>{'SFDC ID'}</h3>
            <h3 className={classes.sectionContent}>{VAR.sfcdId}</h3>
          </div>
          <div style={{ marginTop: 15 }}>
            <h3 className={classes.sectionHeader}>{<ReduxLanguage languageKey={'forms.customerMarketplace'} />}</h3>
            <h3 className={classes.sectionContent}>{marketPlace && marketPlace.name ? marketPlace.name : '---'}</h3>
          </div>
          <div style={{ marginTop: 15 }}>
            <h3 className={classes.sectionHeader}>{<ReduxLanguage languageKey={'forms.customerCompany'} />}</h3>
            <h3 className={classes.sectionContent}>{VAR.company || '---'}</h3>
          </div>
          <div style={{ marginTop: 15 }}>
            <h3 className={classes.sectionHeader}>{<ReduxLanguage languageKey={'activations.keyAccountManager'} />}</h3>
            <h3 className={classes.sectionContent}>{VAR.kamEmail || '---'}</h3>
          </div>
          <Divider style={{ marginTop: 15 }} />
          <div style={{ marginTop: 15 }}>
            <h3 className={classes.sectionHeader}>{<ReduxLanguage languageKey={'vars.varSupportEmail'} />}</h3>
            <h3 className={classes.sectionContent}>{VAR.supportEmail || '---'}</h3>
          </div>
          <div style={{ marginTop: 15 }}>
            <h3 className={classes.sectionHeader}>{<ReduxLanguage languageKey={'vars.varSupportHours'} />}</h3>
            <h3 className={classes.sectionContent}>{VAR.supportHours || '---'}</h3>
          </div>
          <div style={{ marginTop: 15 }}>
            <h3 className={classes.sectionHeader}>{<ReduxLanguage languageKey={'vars.varCommercialContact'} />}</h3>
            <h3 className={classes.sectionContent}>{VAR.commercialRefName || '---'} - {VAR.commercialRefEmail} - {VAR.commercialRefPhone}</h3>
          </div>
          <div style={{ marginTop: 15 }}>
            <h3 className={classes.sectionHeader}>{<ReduxLanguage languageKey={'vars.varTechContact'} />}</h3>
            <h3 className={classes.sectionContent}>{VAR.techRefName || '---'} - {VAR.techRefEmail} - {VAR.techRefPhone}</h3>
          </div>
          
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    language: state.settings.language,
    selectedVar: state.vars.selectedVar.data,
    marketplaces: state.credits.marketplaces.data,
    clusters: state.credits.clusters.data,
  };
}

const connector = connect(mapStateToProps);

export default connector(withStyles (styles)(VarInfoDetailsView as any));