
import _ from 'lodash';
import { PaginationRestDTO, PaginatedDataDTO, PaginationDTO } from '@bottega52/commons-pagination';
import { IActivationInDTO } from '../repository/jago/model/input/IActivationInDTO';
import { IActivationOutDTO } from '../repository/jago/model/output/IActivationOutDTO';
import { IActivationNewFormDTO } from '../repository/jago/model/input/IActivationNewFormDTO';
import IClusterInDTO from '../repository/jago/model/input/IClusterInDTO';
import IMarketplaceInDTO from '../repository/jago/model/input/IMarketplaceInDTO';
import { IKamInDTO } from '../repository/jago/model/input/IKamInDTO';

export function decode(data: PaginationRestDTO<IActivationInDTO>): PaginatedDataDTO<IActivationInDTO> {
    const pagination: PaginationDTO = _.omit(data, 'content');
    return {
      content: data.content,
      pagination,
    };
}

export function decodeKam(data: PaginationRestDTO<IKamInDTO>): PaginatedDataDTO<IKamInDTO> {
  const pagination: PaginationDTO = _.omit(data, 'content');
  return {
    content: data.content,
    pagination,
  };
}


export enum ActivationstatusOptions {
  DRAFT = 'DRAFT',
  UNDER_REVIEW = 'UNDER_REVIEW',
  REJECTED = 'REJECTED',
  PROCESSING = 'PROCESSING',
  CREATED = 'CREATED',
  INVOICED = 'INVOICED',
  ACTIVE = 'ACTIVE',
}

export const ActivationFileAcceptedExtensions: string[] = ["pdf","txt","doc","docx","xls","xlsx","csv","png","jpg","jpeg","bmp"]

export const ActivationQuotationToolFileName: string = "quotation__tool"

export const ActivationInvoiceFileName: string = "invoice__file"

export interface IActivationFile {
  name: string;
  contentBase64?: string;
  url?: string;
}

export function decodeActivationContentToForm(data: IActivationInDTO, clusters: IClusterInDTO[], marketplaces: IMarketplaceInDTO[], kams: IKamInDTO[]): IActivationNewFormDTO {
  let foundClusters = clusters.filter(e=>e.id===data.clusterId)
  let foundMarketplaces = marketplaces.filter(e=>e.id===data.plantRegistry.customerMarketplaceId)
  let foundKams = kams.filter(e=>e.email===data.plantRegistry.keyAccountManager)
  return {
    status: data.status,
    cluster: foundClusters.length>0?foundClusters[0]:undefined,
    marketplace: foundMarketplaces.length>0?foundMarketplaces[0]:undefined,
    walletId: data.plantRegistry.walletId,
    scratchcardId: data.plantRegistry.scratchcardId,
    varId: data.plantRegistry.varId,
    varName: data.plantRegistry.varName,
    varSfdcId: data.plantRegistry.varSfdcId,
    customerId: data.plantRegistry.customerId,
    customerName: data.plantRegistry.customerName,
    customerCountry: data.plantRegistry.customerCountry,
    customerVAT: data.plantRegistry.customerVAT,
    customerAddress: data.plantRegistry.customerAddress,
    customerFiscalCode: data.plantRegistry.customerFiscalCode,
    customerCity: data.plantRegistry.customerCity,
    customerEmail: data.plantRegistry.customerEmail,
    customerPostalCode: data.plantRegistry.customerPostalCode,
    customerPEC: data.plantRegistry.customerPEC,
    customerProvince: data.plantRegistry.customerProvince,
    customerPhone: data.plantRegistry.customerPhone,
    iseoCompanyName: data.plantRegistry.iseoCompanyName,
    CRMID: data.plantRegistry.CRMID,
    custRefID: data.plantRegistry.custRefID,
    optId: data.optId,
    salesOrderConfirmationNumber: data.plantRegistry.salesOrderConfirmationNumber,
    purchaseOrderNumberSofia: data.plantRegistry.purchaseOrderNumberSofia,
    baselineSubscription: data.plantRegistry.baselineSubscription,
    baselineSubscriptionID: data.plantRegistry.baselineSubscriptionID,
    plantName: data.plantName,
    kam: foundKams.length>0?foundKams[0]:undefined,
    keyAccountManagerEmail: data.plantRegistry.keyAccountManager,
    systemManagerEmails: data.plantRegistry.systemManagerEmails,
    installerEmail: data.plantRegistry.installerEmail,
    pmEmail: data.plantRegistry.pmEmail,
    systemEngineerEmail: data.plantRegistry.systemEngineerEmail,
    numberOfElements: data.plantRegistry.numberOfElements,
    V364orF9000: data.plantRegistry.V364orF9000,
    notes: data.plantRegistry.notes,
    submitterEmails: data.plantRegistry.submitterEmails,
    rejectedNotes: data.rejectedNotes,
    ownerEmail: data.plantRegistry.ownerEmail,
    addons: data.plantRegistry.addons,
    credits: data.plantRegistry.credits,
    expectedCreditsEndDays: data.plantRegistry.expectedCreditsEndDays,
    invoiceNumber: data.plantRegistry.invoiceNumber,
    documents: data.documents?Object.keys(data.documents).map((key: string) => {return {name:key, url:data.documents[key]}}):[],
    documentsOUT: {},
    clusterIdForVar: null,
  };
}

export function encodeActivationFromForm(data: IActivationNewFormDTO): IActivationOutDTO {
    let retVal: IActivationOutDTO = {
      clusterId: data.cluster?data.cluster.id:data.clusterIdForVar,
      optId: data.optId,
      plantName: data.plantName,
      rejectedNotes: data.rejectedNotes,
      plantRegistry: {
        walletId: data.walletId,
        scratchcardId: data.scratchcardId,
        varId: data.varId,
        varName: data.varName,
        varSfdcId: data.varSfdcId,
        customerId: data.customerId,
        customerName: data.customerName,
        customerCountry: data.customerCountry,
        customerVAT: data.customerVAT,
        customerAddress: data.customerAddress,
        customerFiscalCode: data.customerFiscalCode,
        customerCity: data.customerCity,
        customerEmail: data.customerEmail,
        customerPostalCode: data.customerPostalCode,
        customerPEC: data.customerPEC,
        customerProvince: data.customerProvince,
        customerPhone: data.customerPhone,
        iseoCompanyName: data.iseoCompanyName,
        customerMarketplaceId: data.marketplace!.id,
        CRMID: data.CRMID,
        custRefID: data.custRefID,
        salesOrderConfirmationNumber: data.salesOrderConfirmationNumber,
        purchaseOrderNumberSofia: data.purchaseOrderNumberSofia,
        baselineSubscription: data.baselineSubscription,
        baselineSubscriptionID: data.baselineSubscriptionID,
        keyAccountManager: data.kam!.email,
        systemManagerEmails: data.systemManagerEmails,
        installerEmail: data.installerEmail,
        pmEmail: data.pmEmail,
        systemEngineerEmail: data.systemEngineerEmail,
        numberOfElements: data.numberOfElements,
        V364orF9000: data.V364orF9000,
        notes: data.notes,
        submitterEmails: data.submitterEmails,
        ownerEmail: data.ownerEmail,
        addons: data.addons,
        credits: data.credits,
        expectedCreditsEndDays: data.expectedCreditsEndDays,
        invoiceNumber: data.invoiceNumber,
      },
      documents:data.documentsOUT,
    };
    return retVal;
}

export function encodeActivationInFromOut(data: IActivationInDTO): IActivationOutDTO {
    return {
      clusterId: data.clusterId,
      optId: data.optId,
      plantName: data.plantName,
      rejectedNotes: data.rejectedNotes,
      plantRegistry: {
        walletId: data.plantRegistry.walletId,
        scratchcardId: data.plantRegistry.scratchcardId,
        varId: data.plantRegistry.varId,
        varName: data.plantRegistry.varName,
        varSfdcId: data.plantRegistry.varSfdcId,
        customerId: data.plantRegistry.customerId,
        customerName: data.plantRegistry.customerName,
        customerCountry: data.plantRegistry.customerCountry,
        customerVAT: data.plantRegistry.customerVAT,
        customerAddress: data.plantRegistry.customerAddress,
        customerFiscalCode: data.plantRegistry.customerFiscalCode,
        customerCity: data.plantRegistry.customerCity,
        customerEmail: data.plantRegistry.customerEmail,
        customerPostalCode: data.plantRegistry.customerPostalCode,
        customerPEC: data.plantRegistry.customerPEC,
        customerProvince: data.plantRegistry.customerProvince,
        customerPhone: data.plantRegistry.customerPhone,
        iseoCompanyName: data.plantRegistry.iseoCompanyName,
        customerMarketplaceId: data.plantRegistry.customerMarketplaceId,
        CRMID: data.plantRegistry.CRMID,
        custRefID: data.plantRegistry.custRefID,
        salesOrderConfirmationNumber: data.plantRegistry.salesOrderConfirmationNumber,
        purchaseOrderNumberSofia: data.plantRegistry.purchaseOrderNumberSofia,
        baselineSubscription: data.plantRegistry.baselineSubscription,
        baselineSubscriptionID: data.plantRegistry.baselineSubscriptionID,
        keyAccountManager: data.plantRegistry.keyAccountManager,
        systemManagerEmails: data.plantRegistry.systemManagerEmails,
        installerEmail: data.plantRegistry.installerEmail,
        pmEmail: data.plantRegistry.pmEmail,
        systemEngineerEmail: data.plantRegistry.systemEngineerEmail,
        numberOfElements: data.plantRegistry.numberOfElements,
        V364orF9000: data.plantRegistry.V364orF9000,
        notes: data.plantRegistry.notes,
        submitterEmails: data.plantRegistry.submitterEmails,
        ownerEmail: data.plantRegistry.ownerEmail,
        addons: data.plantRegistry.addons,
        credits: data.plantRegistry.credits,
        expectedCreditsEndDays: data.plantRegistry.expectedCreditsEndDays,
        invoiceNumber: data.plantRegistry.invoiceNumber,
      },
      documents:{}
    };
}