
import { PaginatedDataDTO, PaginationDTO, PaginationRestDTO } from '@bottega52/commons-pagination';
import _ from 'lodash';
import { IVarInDTO } from '../repository/jago/model/input/IVarInDTO';
import { IVarFormDTO } from '../repository/jago/model/input/IVarFormDTO';
import { IVarOutDTO } from '../repository/jago/model/output/IVarOutDTO';
import IClusterInDTO from '../repository/jago/model/input/IClusterInDTO';
import { IPlantThemeInDTO } from '../repository/jago/model/input/IPlantThemeInDTO';
import { IPlantThemeFormDTO } from '../repository/jago/model/input/IPlantThemeFormDTO';
import { IPlantThemeOutDTO } from '../repository/jago/model/output/IPlantThemeOutDTO';

export const PlantThemeLogoAcceptedExtensions: string[] = ["png","jpg","jpeg","bmp"]

export function decode(data: PaginationRestDTO<IPlantThemeInDTO>): PaginatedDataDTO<IPlantThemeInDTO> {
    const pagination: PaginationDTO = _.omit(data, 'content');
    return {
      content: data.content,
      pagination,
    };
}

export function decodePlantThemeContentToForm(data: IPlantThemeInDTO): IPlantThemeFormDTO {
  return {
    id: data.id,
    name: data.name,
    colour: data.colour,
    logoUrl: data.logoUrl,
    logoName: undefined,
    varDefault: data.varDefault,
    logoFileBase64: undefined,
  };
}

export function encodePlantThemeFromForm(data: IPlantThemeFormDTO): IPlantThemeOutDTO {
    let returnVal: IPlantThemeOutDTO = {
      id: data.id,
      name: data.name,
      colour: data.colour,
      varDefault: data.varDefault
    };
    if (data.logoFileBase64&&data.logoName) {
      returnVal.logoFileBase64 = data.logoFileBase64;
      returnVal.logoName = data.logoName;
    }
    return returnVal;
}

export function encodePlantThemeInFromOut(data: IPlantThemeInDTO): IPlantThemeOutDTO {
    return {
      id: data.id,
      name: data.name,
      colour: data.colour,
      varDefault: data.varDefault
    };
}