import { PaginatedDataDTO, PaginationDTO, PaginationRestDTO } from '@bottega52/commons-pagination';
import _ from 'lodash';
import { ICustomerFormDTO } from '../repository/jago/model/input/ICustomerFormDTO';
import { ICustomerInDTO } from '../repository/jago/model/input/ICustomerInDTO';
import { ICustomerOutDTO } from '../repository/jago/model/output/ICustomerOutDTO';
import { IVarInDTO } from '../repository/jago/model/input/IVarInDTO';

export function decode(data: PaginationRestDTO<ICustomerInDTO>): PaginatedDataDTO<ICustomerInDTO> {
    const pagination: PaginationDTO = _.omit(data, 'content');
    return {
      content: data.content,
      pagination,
    };
}

export function decodeCustomerContentToForm(data: ICustomerInDTO, vars: IVarInDTO[]): ICustomerFormDTO {
  let foundVar = vars.filter(e=>e.id===data.varId).length>0?vars.filter(e=>e.id===data.varId)[0]:null
  return {
    ..._.omit(data, 'createdAt', 'id'),
    VAR: foundVar
  };
}

export function encodeCustomerFromForm(data: ICustomerFormDTO): ICustomerOutDTO {
    return {
      clusterId: data.cluster!.id,
      company: data.company,
      country: data.country,
      crmId: data.crmId,
      fiscalCode: data.fiscalCode,
      marketplaceId: data.marketplace!.id,
      name: data.name,
      vat: data.vat,
      address: data.address,
      email: data.email,
      certifiedEmail: data.certifiedEmail,
      city: data.city,
      phone: data.phone,
      varId: data?.VAR?.id?data.VAR.id:null,
    };
}

export function encodeCustomerFromFormVAR(data: ICustomerFormDTO, VAR: IVarInDTO): ICustomerOutDTO {
    return {
      clusterId: VAR.clusterId,
      company: VAR.company,
      country: data.country,
      crmId: "N/A",
      fiscalCode: data.fiscalCode,
      marketplaceId: VAR.marketplaceId,
      name: data.name,
      vat: data.vat,
      address: data.address,
      email: data.email,
      certifiedEmail: data.certifiedEmail,
      city: data.city,
      phone: data.phone,
      varId: VAR.id,
    };
}