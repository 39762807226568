import { PaginatedDataDTO, PaginationDTO } from '@bottega52/commons-pagination';
import { Button, Collapse, IconButton, Menu, MenuItem, Popover, Tooltip } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { DeleteOutline, Edit, ExpandLess, ExpandMore, SearchOutlined } from '@material-ui/icons';
import BuildIcon from '@material-ui/icons/Build';
import WalletIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import UserIcon from '@material-ui/icons/PersonOutline';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import CardIcon from '@material-ui/icons/CreditCard';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import LastPageIcon from '@material-ui/icons/LastPage';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import SendIcon from '@material-ui/icons/Send';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Stack } from '@mui/system';
import { DataGrid, GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import _ from 'lodash';
import moment from 'moment';
import * as React from "react";
import { RechargeStatusOptions } from '../../codec/rechargeInDTODecoder';
import { ICustomerInDTO } from "../../repository/jago/model/input/ICustomerInDTO";
import { IRechargeInDTO } from '../../repository/jago/model/input/IRechargeInDTO';
import { IWalletInDTO } from '../../repository/jago/model/input/IWalletInDTO';
import AbilityProvider from '../../services/PermissionService/AbilityProvider';
import { PERMISSIONS, Roles } from '../../services/PermissionService/PermissionConstants';
import translations from '../../translations/i18next';
import { getRechargeStatusColor } from '../../utils/Utils';
import Permission from '../Permission/Permission';
import ReduxLanguage from '../ReduxLanguage';

const styles = createStyles({
  rowContainer: {
    display: 'flex',
    margin: 10,
    flexDirection: 'column'
  },
  rowBodyContainer: {
    padding: 15,
    minHeight: 100,
  },
  rowTitle: {
    fontWeight: 'bold'
  },
  logo: {
    width: '20%',
  },
  info1Container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems:'flex-start',
    justifyContent:'space-evenly',
    flexGrow: 1,
    flexBasis:0
  },
  info2Container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems:'flex-start',
    justifyContent:'flex-end',
    flexGrow:1,
    flexBasis:0
  },
  info3Container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems:'center',
    justifyContent:'flex-end',
    flexGrow:2,
    flexBasis:0
  },
  SFCDtext: {
    fontSize:'0.9em'
  },
  plantNameText: {
    fontWeight: 'bold',
    fontSize:'1.2em',
    marginTop:'5px',
    marginBottom:'5px'
  },
  lightText: {
    fontSize:'0.8em',
    color:'grey',
    textTransform:'uppercase'
  },
  info2Text: {
    fontSize: 13
  },
  boldText: {
    fontWeight: 'bold',
    fontSize:'1.0em',
  },
  odsText: {
    fontSize:'0.8em',
    color:'grey',
  },
  icon: {
    fontSize: 15,
    marginRight: 5,
  },
  actionButton: {
    width: 'fit-content',
    color: 'white',
    marginLeft: 20,
    padding: 4,
    paddingLeft: 10,
    paddingRight: 10,
    fontWeight: 'bold'
  },
  actionButtonText: {
    margin: 0,
    fontWeight: 'bold',
  }
});


export interface IRechargesTableState {
  selectedRecharge: IRechargeInDTO;
  anchorElActions: any;
  actionsStatusAdminOpen: boolean;
}

export interface IRechargesTableProps extends WithStyles<typeof styles>{
  recharges: PaginatedDataDTO<IRechargeInDTO>;
  rowsPerPage: number;
  isLoading?: boolean;
  customers: PaginatedDataDTO<ICustomerInDTO>;
  wallets: PaginatedDataDTO<IWalletInDTO>;
  onFetchItemsOnPage: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  onDeleteRechargeRequest: (recharge: IRechargeInDTO) => void;
  onDeleteRechargeAdminRequest: (recharge: IRechargeInDTO) => void;
  onPromoteRechargeRequest: (recharge: IRechargeInDTO) => void;
  onRejectRechargeRequest: (recharge: IRechargeInDTO) => void;
  onScratchcardCreatedRechargeRequest: (recharge: IRechargeInDTO) => void;
  onInvoicedRechargeRequest:  (recharge: IRechargeInDTO) => void;
  onNewRecharge: () => void;
  onEditRecharge: (recharge: IRechargeInDTO) => void;
  onEditStatusAdmin: (recharge: IRechargeInDTO, newStatus: string) => void;
  onTakeChargeRechargeRequest: (recharge: IRechargeInDTO) => void;
  onOpenCustomerInfo: (customer: ICustomerInDTO) => void;
  onEditCustrefRequest: (recharge: IRechargeInDTO) => void;
  onWalletRechargedRechargeRequest: (recharge: IRechargeInDTO) => void;
  onRejectInvoicedRechargeRequest: (recharge: IRechargeInDTO) => void;
  onCreateNewScratchcardRequest: (recharge: IRechargeInDTO) => void;
}

class RechargesTable extends React.Component<IRechargesTableProps, IRechargesTableState> {
  private defaultColumns: GridColumns<IRechargeInDTO>;

  constructor(props: IRechargesTableProps) {
    super(props);
    this.state = {
      selectedRecharge: null,
      anchorElActions: null,
      actionsStatusAdminOpen: false
    }
  }

  public openActionsMenu(event, recharge) {
    this.setState({ anchorElActions: event.currentTarget, selectedRecharge: recharge });
  }

  public closeActionsMenu() {
    this.setState({ anchorElActions: null });
  };

  public toggleActionsStatusAdminOpen() {
    this.setState({ actionsStatusAdminOpen: !this.state.actionsStatusAdminOpen });
  }

  public render() {
    const { recharges, customers, rowsPerPage, onPageSizeChange, onOpenCustomerInfo, onFetchItemsOnPage, classes, wallets, onPromoteRechargeRequest, onInvoicedRechargeRequest, onDeleteRechargeRequest, onTakeChargeRechargeRequest, onEditRecharge, onScratchcardCreatedRechargeRequest, onRejectRechargeRequest, onWalletRechargedRechargeRequest, onRejectInvoicedRechargeRequest, onEditStatusAdmin, onCreateNewScratchcardRequest, onDeleteRechargeAdminRequest, onEditCustrefRequest } = this.props;
    const { anchorElActions, selectedRecharge, actionsStatusAdminOpen } = this.state;
    this.defaultColumns = [
      {
        field: 'id',
        headerName: 'Id',
        type: 'string',
        sortable: false,
        filterable: false,
        width: 60,
        renderCell: (params) => {
          const recharge = params.row;
          return (
            <div>
              <h3 style={{ margin: 0, fontSize: 13, whiteSpace: 'normal' }}>{recharge.id}</h3>
            </div>
        )}
      },
      {
        field: 'name',
        headerName: translations.t('forms.customerInfo'),
        type: 'string',
        sortable: false,
        filterable: false,
        flex: 1,
        renderCell: (params: GridRenderCellParams<any>) => {
          const recharge = params.row;
          const customer = recharge.customerId && customers.content.filter(e=>e.id===recharge.customerId).length > 0 ? customers.content.filter(e=>e.id === recharge.customerId)[0] : null;
          const wallet = recharge.walletId && wallets.content.filter(e=>e.id===recharge.walletId).length > 0 ? wallets.content.filter(e=>e.id === recharge.walletId)[0] : null;
          return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
              <Tooltip title={<ReduxLanguage languageKey="recharges.seeCustomerDetails" />} placement="left" arrow>
                <IconButton onClick={() => {if (customer) onOpenCustomerInfo(customer)}} style={{ padding: 0, marginRight: 15 }}>
                  <VisibilityIcon style={{ color: 'darkcyan', fontSize: 20, border: '1px solid darkcyan', borderRadius: 50, padding:2 }} />
                </IconButton>
              </Tooltip>
              <div className={classes.info1Container}>
                <span className={classes.SFCDtext} style={{ color: 'gray' }}>{recharge?.sfdcId}</span>
                <div style={{ display:'flex', flexDirection:'row', alignItems:'center' }}>
                  <div style={{ display:'flex', flexDirection:'column' }}>
                    <h4 className={classes.boldText} style={{ margin: 0, fontSize: '1.1em', whiteSpace: 'normal' }}>
                      {customer? customer.name: "---" }
                    </h4>
                    {wallet ? (
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <WalletIcon style={{ fontSize: 15, color: 'gray' }} />
                        <h5 className={classes.lightText}>{wallet ? wallet.id+" "+wallet.name: "---" }</h5>
                      </div>
                    ): null}
                  </div>
                </div>
                {recharge?.odsMail ? (
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <UserIcon style={{ fontSize: 15, color: 'gray' }} />
                    <h5 className={classes.lightText} style={{ margin: 0, marginLeft: 5, textTransform: 'lowercase' }}>{recharge.odsMail.toLowerCase()}</h5>
                  </div>
                ) : null}
              </div>
            </div>
          )
        }
      },
      {
        field: 'value',
        headerName: translations.t('forms.details'),
        type: 'string',
        sortable: false,
        minWidth: 450,
        flex: 1,
        renderCell: (params: any) => {
          const recharge = params.row;
          return (
            <div className={classes.info2Container}>
              <div>
                <span className={classes.info2Text} style={{textDecoration:'underline'}}>
                  <ReduxLanguage languageKey={"recharges.creditsSuggested"}/>
                </span>
                <span className={classes.info2Text}>
                  {" "+(recharge?.creditsSuggested!==null ? Math.ceil(recharge.creditsSuggested/100) : "N/D")}
                </span>
                <span className={classes.info2Text}>
                  {" | "}
                </span>
                <span className={classes.info2Text} style={{textDecoration:'underline'}}>
                  <ReduxLanguage languageKey={"recharges.creditsConfirmed"}/>
                </span>
                <span className={classes.info2Text}>
                  {" "+(recharge?.creditsConfirmed!==null ? Math.ceil(recharge.creditsConfirmed/100) : "N/D")}
                </span>
              </div>
              <div>
                <span className={classes.info2Text} style={{textDecoration:'underline'}}>
                  <ReduxLanguage languageKey={"recharges.createdAt"}/>
                </span>
                <span className={classes.info2Text}>
                  {" "+(recharge?.createdAt!==null? moment(recharge.createdAt).format('DD/MM/YYYY'):"N/D")}
                </span>
                <span className={classes.info2Text}>
                  {" | "}
                </span>
                <span className={classes.info2Text} style={{textDecoration:'underline'}}>
                  <ReduxLanguage languageKey={"recharges.updatedAt"}/>
                </span>
                <span className={classes.info2Text}>
                  {" "+(recharge?.updatedAt!==null? moment(recharge.updatedAt).format('DD/MM/YYYY'):"N/D")}
                </span>
              </div>
              <div>
                <span className={classes.info2Text} style={{textDecoration:'underline'}}>
                  <ReduxLanguage languageKey={"recharges.selloutOrder"}/>
                </span>
                <span className={classes.info2Text}>
                  {" "+(recharge?.walletRegistry?.salesOrderConfirmationNumber?recharge.walletRegistry.salesOrderConfirmationNumber:"N/D")}
                </span>
                <span className={classes.info2Text}>
                  {" | "}
                </span>
                <span className={classes.info2Text} style={{textDecoration:'underline'}}>
                  <ReduxLanguage languageKey={"recharges.intragroupOrder"}/>
                </span>
                <span className={classes.info2Text}>
                  {" "+(recharge?.walletRegistry?.purchaseOrderNumberSofia?recharge.walletRegistry.purchaseOrderNumberSofia:"N/D")}
                </span>
              </div>
              <div>
                <span className={classes.info2Text} style={{textDecoration:'underline'}}>
                  <ReduxLanguage languageKey={"recharges.custref"}/>
                </span>
                <span className={classes.info2Text}>
                  {" "+(recharge?.walletRegistry?.custRefID?recharge.walletRegistry.custRefID:"N/D")}
                </span>
              </div>
            </div>
          )
        }
      },
      {
        field: 'status',
        headerName: translations.t('vars.opportunityStatus'),
        renderCell: (params) => {
          const status = params.row.status;
          return (
            <div>
              <h6 className={classes.info2Text} style={{ color: getRechargeStatusColor(status), fontWeight: 'bold', whiteSpace: 'normal' }}>{translations.t(`recharges.status${status}`).toUpperCase()}</h6>
            </div>
          )
        },
        type: 'string',
        sortable: false,
        width: 140,
      },
      {
        field: 'actions',
        headerName: translations.t('forms.actions'),
        flex: 1,
        renderCell: (params: any) => {
          const recharge = params.row;
          return (
            <div>            
            {recharge.status===RechargeStatusOptions.TO_BE_MANAGED?(
              <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.RECHARGE_REQUEST_TAKE_CHARGE}>
                <Button
                  variant="contained"
                  className={classes.actionButton}
                  style={{ backgroundColor:'darkcyan', flexShrink:0}}
                  onClick={() => onTakeChargeRechargeRequest(recharge)}
                >
                    <PlayCircleOutlineIcon className={classes.icon} />
                  <h5 className={classes.actionButtonText}><ReduxLanguage languageKey={"recharges.takeCharge"} /></h5>
                </Button>
              </Permission>
            ):(
              AbilityProvider.getAbilityHelper().hasPermission(PERMISSIONS.RECHARGE_REQUEST_EDIT)? (
                <Button
                  variant="contained"
                  className={classes.actionButton}
                  style={{ backgroundColor:'darkcyan', flexShrink:0, marginTop:5, marginBottom:5}}
                  onClick={() => onEditRecharge(recharge)}
                >
                  {!(recharge.status===RechargeStatusOptions.TAKEN_CHARGE||recharge.status===RechargeStatusOptions.REJECTED||recharge.status===RechargeStatusOptions.SCRATCHCARD_CREATED)?<VisibilityIcon className={classes.icon} />:<Edit className={classes.icon} />}
                  <h5 className={classes.actionButtonText}>
                    <ReduxLanguage languageKey={(recharge.status===RechargeStatusOptions.TAKEN_CHARGE||recharge.status===RechargeStatusOptions.REJECTED||recharge.status===RechargeStatusOptions.SCRATCHCARD_CREATED)?"forms.edit":"forms.open"} />
                  </h5>
                </Button>
              ): (
                <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.RECHARGE_REQUEST_READ}>
                  <Button
                    variant="contained"
                    className={classes.actionButton}
                    style={{ backgroundColor:'darkcyan', flexShrink:0, marginTop:5, marginBottom:5}}
                    onClick={() => onEditRecharge(recharge)}
                  >
                    {<VisibilityIcon className={classes.icon}/>}
                    <h5 className={classes.actionButtonText}>
                      <ReduxLanguage languageKey={"forms.open"} />
                    </h5>
                  </Button>
                </Permission>
              )
            )}

            <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.RECHARGE_REQUEST_SELLOUT_GENERATED}>
              {recharge.status===RechargeStatusOptions.TAKEN_CHARGE||recharge.status===RechargeStatusOptions.REJECTED ? (
                <Tooltip title={
                  !recharge.creditsConfirmed||recharge.creditsConfirmed<=0||!recharge.walletRegistry||!recharge.walletRegistry.salesOrderConfirmationNumber?
                  <ReduxLanguage languageKey="recharges.dataMissingTakenCharge" />:""
                } placement="left" arrow>
                  <span style={{width:'max-content'}}>
                    <Button
                      variant="contained"
                      className={classes.actionButton}
                      disabled={!recharge.creditsConfirmed||recharge.creditsConfirmed<=0||!recharge.walletRegistry||
                        !recharge.walletRegistry.salesOrderConfirmationNumber||!recharge.walletRegistry.purchaseOrderNumberSofia}
                      style={{ backgroundColor:
                        !recharge.creditsConfirmed||recharge.creditsConfirmed<=0||
                        !recharge.walletRegistry||!recharge.walletRegistry.salesOrderConfirmationNumber||!recharge.walletRegistry.purchaseOrderNumberSofia?'lightgrey':'green', 
                        flexShrink:0}}
                      onClick={() => onPromoteRechargeRequest(recharge)}
                    >
                      <SendIcon className={classes.icon} />
                      <h5 className={classes.actionButtonText}><ReduxLanguage languageKey={"recharges.changeStatusToSelloutOrderGenerated"} /></h5>
                    </Button>
                  </span>
                </Tooltip>
              ):null}
            </Permission>
            <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.RECHARGE_REQUEST_INVOICED}>
              {recharge.status===RechargeStatusOptions.SCRATCHCARD_CREATED ? (
                <Tooltip title={!recharge.walletRegistry||!recharge.walletRegistry.invoiceNumber?
                  <ReduxLanguage languageKey="recharges.dataMissingScratchcardCreated" />:""
                  } placement="left" arrow>
                  <span style={{width:'max-content'}}>
                  <Button
                    variant="contained"
                    className={classes.actionButton}
                    disabled={!recharge.walletRegistry||!recharge.walletRegistry.invoiceNumber}
                    style={{ backgroundColor:
                      !recharge.walletRegistry||!recharge.walletRegistry.invoiceNumber?'lightgrey':'green', 
                      flexShrink:0}}
                    onClick={() => onInvoicedRechargeRequest(recharge)}
                  >
                    <MonetizationOnIcon className={classes.icon} />
                    <h5 className={classes.actionButtonText}><ReduxLanguage languageKey={"recharges.changeStatusToInvoiced"} /></h5>
                  </Button>
                  </span>
                </Tooltip>
              ):null}
            </Permission>
            <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.RECHARGE_REQUEST_DELETE}>
              {recharge.status===RechargeStatusOptions.TO_BE_MANAGED||recharge.status===RechargeStatusOptions.TAKEN_CHARGE ? (
                <Button
                  variant="contained"
                  className={classes.actionButton}
                  style={{ backgroundColor:'darkred', flexShrink:0}}
                  onClick={() => onDeleteRechargeRequest(recharge)}
                >
                  <DeleteOutline className={classes.icon}/>
                  <h5 className={classes.actionButtonText}><ReduxLanguage languageKey={"forms.delete"} /></h5>
                </Button>
              ):null}
            </Permission>
            
            <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.RECHARGE_REQUEST_EDIT_ADMIN}>
              <Button
                variant="contained"
                style={{backgroundColor:'#282c34', flexShrink:0}}
                className={classes.actionButton}
                onClick={(e) => {this.openActionsMenu(e, recharge)}}
              >
                <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="forms.actions" /></h5>
                {Boolean(anchorElActions) && selectedRecharge && selectedRecharge.id === recharge.id ? <ExpandLess className={classes.icon}/> : <ExpandMore className={classes.icon}/>}
              </Button>
            </Permission>
            {selectedRecharge && selectedRecharge.id === recharge.id ? (
              <Popover
                id={`simple-menu-${recharge}`}
                anchorEl={anchorElActions}
                keepMounted
                open={Boolean(anchorElActions) && selectedRecharge && selectedRecharge.id === recharge.id}
                onClose={()=>this.closeActionsMenu()}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                {selectedRecharge.status === RechargeStatusOptions.SELLOUT_ORDER_GENERATED || selectedRecharge.status === RechargeStatusOptions.SCRATCHCARD_CREATED|| selectedRecharge.status === RechargeStatusOptions.INVOICED || selectedRecharge.status === RechargeStatusOptions.WALLET_RECHARGED ? (
                  <MenuItem onClick={()=>{ this.closeActionsMenu(); onEditCustrefRequest(selectedRecharge); }}>
                    <ConfirmationNumberIcon className={classes.icon} color="primary"/>
                    <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="recharges.changeCustRef" /></h5>
                  </MenuItem>
                ):(null)}
                {selectedRecharge.status === RechargeStatusOptions.SELLOUT_ORDER_GENERATED ? (
                  <MenuItem onClick={()=>{ this.closeActionsMenu(); onScratchcardCreatedRechargeRequest(selectedRecharge); }}>
                    <DoneOutlineIcon className={classes.icon} color="primary"/>
                    <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="recharges.changeStatusToScratchcardCreated" /></h5>
                  </MenuItem>
                ):(null)}
                {selectedRecharge.status === RechargeStatusOptions.SELLOUT_ORDER_GENERATED ? (
                  <MenuItem onClick={()=>{ this.closeActionsMenu(); onRejectRechargeRequest(selectedRecharge); }}>
                    <ThumbDownIcon className={classes.icon} color="primary"/>
                    <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="recharges.changeStatusToRejected" /></h5>
                  </MenuItem>
                ):(null)}
                {selectedRecharge.status === RechargeStatusOptions.INVOICED ? (
                  <MenuItem onClick={()=>{ this.closeActionsMenu(); onWalletRechargedRechargeRequest(selectedRecharge); }}>
                    <DoneOutlineIcon className={classes.icon} color="primary"/>
                    <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="recharges.changeStatusToWalletRecharged" /></h5>
                  </MenuItem>
                ):(null)}
                {selectedRecharge.status === RechargeStatusOptions.INVOICED ? (
                  <MenuItem onClick={()=>{ this.closeActionsMenu(); onRejectInvoicedRechargeRequest(selectedRecharge); }}>
                    <ThumbDownIcon className={classes.icon} color="primary"/>
                    <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="recharges.changeStatusToRejected" /></h5>
                  </MenuItem>
                ):(null)}
                {selectedRecharge && selectedRecharge.walletRegistry && !selectedRecharge.walletRegistry.scratchcardId ? (
                  <MenuItem onClick={()=>{ this.closeActionsMenu(); onCreateNewScratchcardRequest(selectedRecharge) }}>
                    <CardIcon className={classes.icon} color="primary"/>
                    <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="recharges.createCard" /></h5>
                  </MenuItem>
                ):null}
                {selectedRecharge.status === RechargeStatusOptions.REQUEST_TO_DELETE?(
                  <MenuItem onClick={()=>{ this.closeActionsMenu(); onDeleteRechargeAdminRequest(selectedRecharge) }}>
                    <DeleteOutline className={classes.icon} style={{ color: 'darkred' }}/>
                    <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="forms.deleteAdminOnly" /></h5>
                  </MenuItem>
                ):(selectedRecharge.status === RechargeStatusOptions.TO_BE_MANAGED || selectedRecharge.status === RechargeStatusOptions.TAKEN_CHARGE ? (
                  <MenuItem onClick={()=>{ this.closeActionsMenu(); onDeleteRechargeRequest(selectedRecharge) }}>
                    <DeleteOutline className={classes.icon} style={{ color: 'darkred' }}/>
                    <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="forms.delete" /></h5>
                  </MenuItem>
                ):(null))}
                <MenuItem onClick={()=> this.toggleActionsStatusAdminOpen()}>
                  <BuildIcon className={classes.icon} color="secondary"/>
                  <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="recharges.changeStatusAdmin" /></h5>
                  {actionsStatusAdminOpen?<ExpandLess />:<ExpandMore />}
                </MenuItem>
                <Collapse in={actionsStatusAdminOpen} timeout="auto" unmountOnExit>
                  {_.map(RechargeStatusOptions, status => (
                    <MenuItem key={status} onClick={()=>{this.closeActionsMenu();this.toggleActionsStatusAdminOpen(); onEditStatusAdmin(selectedRecharge, status)}}>
                      <LastPageIcon className={classes.icon} style={{ color: 'black' }}/>
                      <h5 className={classes.actionButtonText}><ReduxLanguage languageKey={`recharges.status${status}`} /></h5>
                    </MenuItem>
                  ))}
                </Collapse>
              </Popover>
            ): null}
            </div>
          )
        }
      }
    ];

    return (
      <div style={{ width: '100%'}}>
        <DataGrid
          getRowId={(d) => d.id}
          rows={recharges.content || []}
          columns={this.defaultColumns}
          rowHeight={120}
          disableSelectionOnClick
          loading={this.props.isLoading}
          onPageSizeChange={(pageSize) => onPageSizeChange(pageSize)}
          onPageChange={page => onFetchItemsOnPage(page)}
          page={(recharges?.pagination as PaginationDTO)?.number}
          rowCount={(recharges?.pagination as PaginationDTO)?.totalElements}
          pageSize={rowsPerPage}
          paginationMode="server"
          componentsProps={{
            pagination: {
              labelRowsPerPage: translations.t('forms.rowsPerPage')
            }
          }}
          components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                  <SearchOutlined style={{ alignSelf: 'center' }} />
                  <h3 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 0 }}><ReduxLanguage languageKey='recharges.noRechargesFoundTitle' /></h3>
                  <h4 style={{ textAlign: 'center', marginTop: 0 }}><ReduxLanguage languageKey='recharges.noRechargesFoundDescription' /></h4>
                </div>
              </Stack>
            ),
            NoResultsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                <h3 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 0 }}><ReduxLanguage languageKey='recharges.noRechargesFoundTitle' /></h3>
                <h4 style={{ textAlign: 'center', marginTop: 0 }}><ReduxLanguage languageKey='recharges.noRechargesFoundDescription' /></h4>
              </Stack>
            )
          }}
          sx={{
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
          }}
        />
      </div>
    );
  }
}

export default withStyles(styles)(RechargesTable);