import { PaginatedDataDTO, PaginationDTO, PaginationQueryParamsDTO } from '@bottega52/commons-pagination';
import { createStyles, IconButton, WithStyles } from '@material-ui/core';
import { Domain, PublicOutlined, SearchOutlined, Visibility } from '@material-ui/icons';
import CustomersIcon from '@material-ui/icons/GroupOutlined';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/styles';
import { Stack } from '@mui/material';
import { DataGrid, GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import _ from 'lodash';
import 'moment/locale/it';
import qs from 'query-string';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import * as VarDecoder from '../../codec/varInDTODecoder';
import VarForm from '../../components/Forms/VarForm';
import { ModalTypes } from '../../components/Modals/ModalTypes';
import ReduxLanguage from '../../components/ReduxLanguage';
import VarsSearchBar from '../../components/SearchBar/VarsSearchBar';
import VarDetailsView from '../../components/Vars/VarDetailsView';
import * as CreditsActions from '../../redux/credits/credits.actions';
import * as ModalsActions from '../../redux/modals/modals.actions';
import * as SettingsActions from '../../redux/settings/settings.actions';
import * as ActivationActions from '../../redux/activations/activations.actions';
import { IState } from '../../redux/store';
import * as VarsActions from '../../redux/vars/vars.actions';
import IClusterInDTO from '../../repository/jago/model/input/IClusterInDTO';
import IMarketplaceInDTO from '../../repository/jago/model/input/IMarketplaceInDTO';
import { IVarFormDTO } from '../../repository/jago/model/input/IVarFormDTO';
import { IVarInDTO } from '../../repository/jago/model/input/IVarInDTO';
import { IVarsSearchDTO } from '../../repository/jago/model/input/IVarsSearchDTO';
import translations from '../../translations/i18next';
import AbilityProvider from '../../services/PermissionService/AbilityProvider';
import { PERMISSIONS, Roles } from '../../services/PermissionService/PermissionConstants';
import { IKamInDTO } from '../../repository/jago/model/input/IKamInDTO';
import AccountBriefcaseIconCustom from '../../components/Icons/AccountBriefcaseIconCustom';
import VarInfoDetailsView from '../../components/Vars/VarInfoDetailsView';

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
  text: {
    margin: 0,
    marginBottom: 15,
  },
  innerHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    zIndex: 200,
    padding: 10,
    paddingRight: 20,
    borderBottom: '2px solid #5AC0B1',
  },
  tableContainer: {
    display: 'flex',
    backgroundColor: 'white',
    height: 'calc(100vh - 160px)',
    width: '100%'
  },
});
export interface IVarsPageState {
  searchValuesCurrent: IVarsSearchDTO;
  rowsPerPage: number;
  isLoading: boolean;
}

type IReduxProps = ConnectedProps<typeof connector> & RouteComponentProps<any>;

export interface IVarsPageProps extends WithStyles<typeof styles> {
  language: string;
  vars: PaginatedDataDTO<IVarInDTO>;
}

export type ComponentProps = IVarsPageProps & IReduxProps;

class VarsPage extends React.Component<ComponentProps, IVarsPageState> {
  private varColumns: GridColumns<IVarInDTO>;
  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      searchValuesCurrent: {},
      rowsPerPage: 100,
      isLoading: false,
    }
  }

  public async componentDidMount() {
    const { dispatch, history } = this.props;
    const { rowsPerPage } = this.state;
    const parsed = qs.parse(history.location.search);
    this.setState({ isLoading: true });
    try {
      await dispatch<any>(CreditsActions.fetchClusters());
      await dispatch<any>(CreditsActions.fetchMarketplaces());
      const vars = await this.onFetchVars(0, rowsPerPage);
      this.setState({ isLoading: false });
      if (vars && vars.content && !_.isEmpty(vars.content) && parsed && parsed.varId) {
        try {
          const varId = Number(parsed.varId);
          const VAR = _.find(vars.content, { id: varId });
          if (VAR) {
            this.onRowClick(VAR);
          } else {
            dispatch<any>(ModalsActions.showModal(`ERROR_VAR_DETAILS`, {
              modalType: ModalTypes.ERROR_MODAL,
              modalProps: {
                titleMessageKey: 'errors.error',
                errorMessageKey: 'errors.getVarDetailsError',
              }
            }));
          }
        } catch (error) {
          this.setState({ isLoading: false });
          dispatch<any>(ModalsActions.showModal(`ERROR_VAR_DETAILS`, {
            modalType: ModalTypes.ERROR_MODAL,
            modalProps: {
              titleMessageKey: 'errors.error',
              errorMessageKey: 'errors.getVarDetailsError',
            }
          }));
        }
      }
      if (AbilityProvider.getAbilityHelper().hasPermission(PERMISSIONS.KAM_READ)) {
        /* TODO DOPO FIX KAMS
        await dispatch<any>(UserActions.fetchUsersRoles)
        const { usersRoles } = this.props;
        const usersRolesData = (usersRoles as PaginatedDataDTO<IUserRoleInDTO>).content
        const kamRole = _.find(usersRolesData, e=>e.name===Roles.ROLE_VASHO_KAM);
        if (kamRole && kamRole.id) {
          const params: IUserRequestParamsDTO = { 
            roleIds: kamRole.id.toString(),
            page: 0,
            pageSize: 1000,
          };
          await dispatch<any>(UserActions.fetchUsers(params))
        }
        */
        await dispatch<any>(ActivationActions.fetchKams({ page: 0, pageSize: 500 }));
      }
      else {
        /* TODO DOPO FIX KAMS
        await dispatch<any>(UserActions.UserActions.saveUsers({content: [], pagination: {}}));
        */
        await dispatch<any>(ActivationActions.ActivationsActions.saveKams({content: [], pagination: {}}));
      }
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  async onFetchVars(page: number = 0, pageSize: number = 100) {
    const { dispatch } = this.props;
    const { searchValuesCurrent } = this.state;
    const params = { page, pageSize, ...searchValuesCurrent };
    try {
      this.setState({ isLoading: true });
      const vars = await dispatch<any>(VarsActions.fetchVars(params));
      this.setState({ isLoading: false });
      return vars;
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  async onOpenNewVarForm() {
    const { dispatch } = this.props;
    dispatch<any>(SettingsActions.setSpinnerVisible(true));
    dispatch<any>(SettingsActions.setSpinnerVisible(false));
    dispatch<any>(ModalsActions.showModal(`OPERATIONAL_CREATE_VAR_MODAL`, {
      modalType: ModalTypes.OPERATIONAL_VIEW_MODAL_BACKDROP,
      modalProps: {
        content: (
          <VarForm
            onCreateEditVar={(newVarData: IVarFormDTO) => this.onCreateNewVarRequest(newVarData)}
          />
        ),
        titleMessageKey: 'forms.varFormTitle',
      }
    }));
  }

  async onOpenEditVarDetails() {
    const { dispatch, selectedVar, history } = this.props;
    const VAR = selectedVar?(selectedVar as IVarInDTO):null
    if (VAR) {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(VarsActions.fetchVarCustomers(VAR.id, { page: 0, pageSize: 100 }));
      await dispatch<any>(VarsActions.fetchVarUsers(VAR.id));
      if (AbilityProvider.getAbilityHelper().hasPermission(PERMISSIONS.OPPORTUNITY_READ))
        await dispatch<any>(VarsActions.fetchOpportunities({ page:0, pageSize: 1000, varId:VAR.id.toString() }));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`OPERATIONAL_EDIT_VAR_MODAL`, {
        modalType: ModalTypes.OPERATIONAL_VIEW_MODAL,
        modalProps: {
          content: (
            <VarDetailsView
              onOpenEditVarForm={() => this.onOpenEditVarForm()}
              onOpenVarDetails={() => this.onOpenVarDetails()}
              onGoToCustomerDetailsPage={(customerId) => history.push(`/customers?customerId=${customerId}`)}
            />
          ),
          titleMessageKey: 'forms.varFormTitle',
        }
      }));
    }
  }

  onOpenVarDetails() {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`OPERATIONAL_VAR_REGISTRY_DETAILS`, {
      modalType: ModalTypes.OPERATIONAL_VIEW_MODAL,
      modalProps: {
        content: (
          <VarInfoDetailsView />
        ),
        titleMessageKey: 'forms.varFormTitle',
      }
    }));
  }
  async onOpenEditVarForm() {
    const { dispatch, selectedVar } = this.props;
    const VAR = selectedVar?(selectedVar as IVarInDTO):null
    if (VAR) {
      const varFormData = VarDecoder.decodeVarContentToForm(
        (selectedVar as IVarInDTO),
        (this.props.clusters as PaginatedDataDTO<IClusterInDTO>).content,
        (this.props.marketplaces as PaginatedDataDTO<IMarketplaceInDTO>).content,
        (this.props.kams as PaginatedDataDTO<IKamInDTO>).content
        // TODO DOPO FIX KAMS (this.props.users as PaginatedDataDTO<IKamInDTO>).content
      );
      dispatch<any>(ModalsActions.showModal(`OPERATIONAL_EDIT_FORM_VAR_MODAL`, {
        modalType: ModalTypes.OPERATIONAL_VIEW_MODAL_BACKDROP,
        modalProps: {
          content: (
            <VarForm
              VAR={varFormData}
              onCreateEditVar={(newVarData: IVarFormDTO) => this.onEditVarRequest(VAR.id, newVarData)}
            />
          ),
          titleMessageKey: 'forms.varFormTitle',
        }
      }));
    }
  }

  public onEditVarRequest(varId: number, newVarData: IVarFormDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`EDIT_VAR_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'vars.editVar',
        successMessageKey: 'vars.editVarConfirm',
        confirmMessageKey: 'vars.editVar',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onEditVarConfirm(varId, newVarData),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`EDIT_VAR_CONFIRM_MODAL`)),
      }
    }));
  }

  public async onEditVarConfirm(varId: number, newVarData: IVarFormDTO) {
    const { dispatch } = this.props;
    const { rowsPerPage } = this.state;
    try {
      dispatch<any>(ModalsActions.hideModal(`EDIT_VAR_CONFIRM_MODAL`));
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      const varUpdated = await dispatch<any>(VarsActions.editVarAdmin(varId, newVarData));
      dispatch<any>(VarsActions.setSelectedVar(varUpdated));
      await this.onFetchVars(0, rowsPerPage);
      dispatch<any>(ModalsActions.hideModal('OPERATIONAL_EDIT_VAR_MODAL'));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_DOMAIN_CREATION`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.updateVarError',
        }
      }));
    }
  }

  public onCreateNewVarRequest(newVarData: IVarFormDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`CREATE_VAR_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'vars.newVar',
        successMessageKey: 'vars.varCreationConfirm',
        confirmMessageKey: 'vars.newVar',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onCreateNewVarConfirm(newVarData),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`CREATE_VAR_CONFIRM_MODAL`)),
      }
    }));
  }

  public async onCreateNewVarConfirm(newVarData: IVarFormDTO) {
    const { dispatch } = this.props;
    const { rowsPerPage } = this.state;
    try {
      dispatch<any>(ModalsActions.hideModal(`CREATE_VAR_CONFIRM_MODAL`));
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(VarsActions.createNewVar(newVarData));
      await this.onFetchVars(0, rowsPerPage);
      dispatch<any>(ModalsActions.hideModal('OPERATIONAL_CREATE_VAR_MODAL'));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_DOMAIN_CREATION`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.updateVarError',
        }
      }));
    }
  }

  public async onRowClick(VAR: IVarInDTO) {
    const { dispatch } = this.props;
    try {
      await dispatch<any>(VarsActions.setSelectedVar(VAR));
      this.onOpenEditVarDetails()
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_VAR_DETAILS`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.getVarDetailsError',
        }
      }));
    }
  }

  async onResetSearch() {
    const { dispatch } = this.props;
    const { rowsPerPage } = this.state;
    this.setState({ searchValuesCurrent:{}, isLoading: true });
    try {
      await dispatch<any>(VarsActions.fetchVars({ page: 0, pageSize: rowsPerPage }));
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  public async onSearch(searchValues: IVarsSearchDTO) {
    const { dispatch } = this.props;
    const { rowsPerPage } = this.state;
    this.setState({ searchValuesCurrent: searchValues, isLoading: true });
    try {
      await dispatch<any>(VarsActions.fetchVars({ page: 0, pageSize: rowsPerPage, ...searchValues }));
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  public async onFetchVarsOnPage(page: number) {
    const { rowsPerPage } = this.state;
    await this.onFetchVars(page, rowsPerPage);
  }

  public handleChangeRowsPerPage(pageSize: number) {
    this.setState({ rowsPerPage: pageSize, isLoading: true }, async () => {
      await this.onFetchVars(0, pageSize);
    });
  }

  render() {
    const { dispatch, classes, vars, clusters } = this.props;
    const { rowsPerPage } = this.state;
    const varsPagination = !_.isEmpty(vars.pagination) ? (vars.pagination as PaginationDTO) : null;
    const isSofiaAdmin = AbilityProvider.getAbilityHelper().hasRoles([Roles.ROLE_VASHO_SOFIA_ADMIN]);
    
    this.varColumns = [
      {
        field: 'id',
        headerName: 'Id',
        type: 'string',
        sortable: false,
        filterable: false,
        width: 60,
        renderCell: (params: GridRenderCellParams<any>) => {
          const VAR = params.row;
          return (
            <div>
              <h3 style={{ margin: 0, fontSize: 13, whiteSpace: 'normal' }}>{VAR.id}</h3>
            </div>
        )}
      },
      {
        field: 'name',
        headerName: translations.t('gateways.name'),
        type: 'string',
        sortable: false,
        filterable: false,
        minWidth: 130,
        flex: 1,
        renderCell: (params: GridRenderCellParams<any>) => {
          const VAR = params.row;
            return (
              <div style={{ display: 'flex', alignItems: 'center'}}>
                <IconButton onClick={(e) => { e.stopPropagation(); dispatch<any>(VarsActions.setSelectedVar(VAR)); this.onOpenVarDetails(); }} style={{ padding: 0, marginRight: 15 }}>
                  <Visibility style={{ color: 'darkcyan', fontSize: 20, border: '1px solid darkcyan', borderRadius: 50, padding:2 }} />
                </IconButton>
                <div>
                  <h3 style={{ marginLeft: 0, marginBottom: 0, fontWeight: 'bold' }}>{VAR.name}</h3>
                  <h4 style={{ margin: 0 }}>{VAR.contactEmail}</h4>
                </div>
              </div>
            )
        }
      },
      {
        field: 'KAM',
        headerName: translations.t('activations.keyAccountManager'),
        type: 'string',
        sortable: false,
        filterable: false,
        minWidth: 250,
        renderCell: (params: GridRenderCellParams<any>) => {
          const VAR = params.row;
            return (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <AccountBriefcaseIconCustom style={{ width: 18 }} />
                <h4 style={{ margin: 0, marginLeft: 5, }}>{VAR.kamEmail || '---'}</h4>
              </div>
            )
        }
      },
      {
        field: 'customers',
        headerName: translations.t('vars.customers'),
        type: 'string',
        sortable: false,
        filterable: false,
        minWidth: 100,
        renderCell: (params: GridRenderCellParams<any>) => {
          const VAR = params.row;
            return (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <CustomersIcon style={{ fontSize: 18 }} />
                <h3 style={{ marginLeft: 5, marginBottom: 0, marginTop: 0, fontWeight: 'bold' }}>{_.size(VAR.customerIds)}</h3>
              </div>
            )
        }
      },
      {
        field: 'vat',
        headerName: isSofiaAdmin ? translations.t('forms.clusterAndIseoCompany') : translations.t('activations.cluster'),
        type: 'string',
        sortable: false,
        filterable: false,
        minWidth: 350,
        renderCell: (params: GridRenderCellParams<any>) => {
          const VAR = params.row;
          const clustersData = clusters as PaginatedDataDTO<IClusterInDTO>;
          const clustersContent= clustersData.content ? clustersData.content:[]
          const varCluster = VAR.clusterId && clustersContent.filter(e=>e.id===VAR.clusterId).length>0 ? clustersContent.filter(e=> e.id === VAR.clusterId)[0] : undefined;
            return (
              <div>
              {isSofiaAdmin  && VAR.company ? (
                  <div style={{ display: 'flex', alignItems: 'center', marginTop: 5 }}>
                  <Domain style={{ fontSize: 15 }}/>
                    <h4 style={{ margin: 0, marginLeft: 5 }}>{VAR.company}</h4>
                </div>
                ): null}
              {varCluster ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <PublicOutlined style={{ fontSize: 15 }}/>
                  <h4 key={varCluster.id}>
                  <>[{varCluster.market}] - {varCluster.name}</>
                  </h4>
                </div>
              ) : null}
              </div>
            )
        }
      },
    ]
    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <VarsSearchBar
            totalElements={varsPagination?varsPagination.totalElements:''}
            onCreateNewEntityButtonClick={() => this.onOpenNewVarForm()}
            onSearch={(searchValues: IVarsSearchDTO) => this.onSearch(searchValues)}
            onResetSearch={() => this.onResetSearch()}
            clusters={(clusters as PaginatedDataDTO<IClusterInDTO>)}
          />
        </div>
        <div className={classes.tableContainer}>
          <DataGrid
            getRowId={(d) => d.id}
            rows={vars.content || []}
            columns={this.varColumns}
            rowHeight={60}
            loading={this.state.isLoading}
            onPageSizeChange={(pageSize) => this.handleChangeRowsPerPage(pageSize)}
            onPageChange={page => this.onFetchVarsOnPage(page)}
            page={(vars?.pagination as PaginationDTO)?.number}
            rowCount={(vars?.pagination as PaginationDTO)?.totalElements}
            pageSize={rowsPerPage}
            onRowClick={row => this.onRowClick(row.row)}
            paginationMode="server"
            componentsProps={{
              pagination: {
                labelRowsPerPage: translations.t('forms.rowsPerPage')
              }
            }}
            components={{
              NoRowsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    <SearchOutlined style={{ alignSelf: 'center' }} />
                    <h3 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 0 }}><ReduxLanguage languageKey='vars.noVarFindTitle' /></h3>
                    <h4 style={{ textAlign: 'center', marginTop: 0 }}><ReduxLanguage languageKey='vars.noVarFindDescription' /></h4>
                  </div>
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  <h3 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 0 }}><ReduxLanguage languageKey='vars.noVarFindTitle' /></h3>
                  <h4 style={{ textAlign: 'center', marginTop: 0 }}><ReduxLanguage languageKey='vars.noVarFindDescription' /></h4>
                </Stack>
              )
            }}
            sx={{
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
            }}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    language: state.settings.language,
    vars: state.vars.vars.data,
    selectedVar: state.vars.selectedVar.data,
    clusters: state.credits.clusters.data,
    marketplaces: state.credits.marketplaces.data,
    kams: state.activations.kams.data,
  };
}

const connector = connect(mapStateToProps);

export default connector(withRouter(withStyles(styles)(VarsPage)));