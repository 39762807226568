import { Accordion, AccordionDetails, AccordionSummary, Button, Divider, IconButton, Snackbar, Tooltip } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { FileCopy } from "@material-ui/icons";
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _ from 'lodash';
import moment from "moment";
import * as React from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CardStatusOptions, ICardInDTO } from "../../repository/jago/model/input/ICardInDTO";
import { ICRMSourceEnum } from '../../repository/jago/model/output/ICRMSourceEnum';
import AbilityProvider from '../../services/PermissionService/AbilityProvider';
import { BLUE, GREEN, ORANGE } from "../../style/StyleConsts";
import Utils, { VAR_USERS_ROLES } from '../../utils/Utils';
import SubscriptionRow from '../MarketplaceSubscriptions/SubscriptionRow';
import ReduxLanguage from "../ReduxLanguage";

const styles = createStyles({
  rowContainer: {
    display: 'flex',
    margin: 10,
    flexDirection: 'column',
    borderRadius: 10,
    paddingTop: 5,
    paddingBottom: 10,
    marginLeft: 0,
  },
  rowActionBodyContainer: {
    padding: 15,
    minHeight: 100,
  },
  titleHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  sectionHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  rechargeDetailsContainer: {
    flex: 1,
  },
  rowBodyContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  rowTitle: {
    fontWeight: 'bold'
  },
  rowBodySectionHeaderText: {
    fontWeight: 'bold'
  },
  avatar: {
    color: 'white',
    marginRight: 10,
    backgroundColor: '#0D47A1',
  },
  logo: {
    width: '20%',
  },
  federatedContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 5
  },
  icon: {
    fontSize: 15,
    marginRight: 5,
  },
  rowBodyText: {
    fontWeight: 'normal',
  },
  statusContainer: {
    width: 'fit-content',
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
    padding: 7,
    paddingTop: 5
  },
  actionButton: {
    width: 'fit-content',
    color: 'white',
    marginLeft: 20,
    padding: 4,
    paddingLeft: 10,
    paddingRight: 10,
    fontWeight: 'bold',
  },
  actionButtonText: {
    margin: 0,
    fontWeight: 'bold',
  }
});

export interface ICardRowProps extends WithStyles<typeof styles>{
  card?: ICardInDTO;
  onCopyCode: () => void;
}

export interface ICardRowState {
}
export interface ICardStatusProps {
  isUsed: boolean;
  isDisabled: boolean;
  isVar: boolean;
  classes: ClassNameMap
}

class CardRow extends React.Component<ICardRowProps, ICardRowState> {


  public onCopyCode(code: string) {
    this.setState({ snackIsOpen: true });
  }

  public render() {
    const { card, classes } = this.props;
    const isUsed =  card && card.used ? card.used : false;
    const isDisabled =  card && card.status && card.status!==CardStatusOptions.ACTIVE;
    let crmLinkComponent = <></>;

    if (!card) {
      return;
    }

    if (card.crmSource === ICRMSourceEnum.SFDC_ISEO) {
      const SFDCCRMLinks = Utils.generateSFDCCRMLink(card.crmId);
      crmLinkComponent = (
        <>
          <a href={SFDCCRMLinks.SFCDLinkBasic} target="_blank" rel="noopener noreferrer">Link Lite</a>
          <br />
          <a href={SFDCCRMLinks.SFCDLinkPremium} target="_blank" rel="noopener noreferrer">Link Standard</a>
        </>
      );
    } else if (card.crmSource === ICRMSourceEnum.PD_SOFIA) {
      const PDCRMLink = Utils.generateSofiaPDLink(card.crmId);
      crmLinkComponent = (
        <a href={PDCRMLink} target="_blank" rel="noopener noreferrer">Link</a>
      );
    }

    const isVar = AbilityProvider.getAbilityHelper().hasRoles(VAR_USERS_ROLES.map(e=>{return e.name}))
    
    return (
      <div className={classes.rowContainer}>
        <div className={classes.sectionHeader}>
          <div className={classes.titleHeader}>
            <h3 className={classes.rowTitle}>{card?.code}</h3>
              <CopyToClipboard text={card?.code || ''}>
                <Button
                  variant="contained"
                  className={classes.actionButton}
                  style={{ backgroundColor: isDisabled || isUsed? 'lightgrey' : BLUE }}
                  onClick={(e) => { e.stopPropagation(); this.onCopyCode(card?.code || ''); this.props.onCopyCode()}}
                >
                  <FileCopy className={classes.icon}/>  
                  <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="forms.copy" /></h5>
                </Button>
            </CopyToClipboard>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 5 }}>
          <div>
          {card.freeOfCharge ? <h4 className={classes.rowBodyText} style={{ marginTop: 20, marginBottom: 10, fontWeight: 'bold' }}><ReduxLanguage languageKey="forms.freeOfCharge" /></h4> : null}
          <h4 className={classes.rowBodyText} style={{ marginTop: 10, marginBottom: 10 }}><ReduxLanguage languageKey="credits.rechargeCardCreditsDetails" languageProps={{ credits: Utils.formatCreditsIn(card.quantity) }} /></h4>
          </div>
        </div>
        {!isVar && 
          <Accordion style={{ marginTop: 5 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <h3 className={classes.rowBodySectionHeaderText}>{<ReduxLanguage languageKey="credits.rechargeDetails" />}</h3>
            </AccordionSummary>
            <AccordionDetails>
              <table width="100%">
                <tr>
                  <td>
                    <h4 className={classes.rowBodySectionHeaderText}>{<ReduxLanguage languageKey="forms.iseoSalesOrderReference" />}</h4>
                    <h4 className={classes.rowBodyText}>{card.iseoSalesOrderReference || '---'}</h4>
                  </td>
                  <td>
                    <h4 className={classes.rowBodySectionHeaderText}>{<ReduxLanguage languageKey="forms.iseoSalesOrderDate" />}</h4>
                    <h4 className={classes.rowBodyText}>{card.iseoSalesOrderDate ? moment(card.iseoSalesOrderDate).format('L') : '---'}</h4>
                  </td>
                  <td>
                    <h4 className={classes.rowBodySectionHeaderText}>{<ReduxLanguage languageKey="forms.iseoPurchaseOrderReference" />}</h4>
                    <h4 className={classes.rowBodyText}>{card.iseoPurchaseOrderReference || '---'}</h4>
                  </td>
                  <td>
                    <h4 className={classes.rowBodySectionHeaderText}>{<ReduxLanguage languageKey="forms.iseoPurchaseOrderDate" />}</h4>
                    <h4 className={classes.rowBodyText}>{card.iseoPurchaseOrderDate ? moment(card.iseoPurchaseOrderDate).format('L') : '---'}</h4>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4 className={classes.rowBodySectionHeaderText}>{<ReduxLanguage languageKey="forms.sofiaSalesOrderReference" />}</h4>
                    <h4 className={classes.rowBodyText}>{card.sofiaSalesOrderReference || '---'}</h4>
                  </td>
                  <td>
                    <h4 className={classes.rowBodySectionHeaderText}>{<ReduxLanguage languageKey="forms.sofiaSalesOrderDate" />}</h4>
                    <h4 className={classes.rowBodyText}>{card.sofiaSalesOrderDate ? moment(card.sofiaSalesOrderDate).format('L') : '---'}</h4>
                  </td>
                  <td>
                    <h4 className={classes.rowBodySectionHeaderText}>{<ReduxLanguage languageKey="forms.crmSource" />}</h4>
                    <h4 className={classes.rowBodyText}>{card.crmSource || '---'}</h4>
                  </td>
                  <td>
                    <h4 className={classes.rowBodySectionHeaderText}>{<ReduxLanguage languageKey="forms.crmId" />}</h4>
                    <h4 className={classes.rowBodyText}>{card.crmId || '---'}</h4>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4 className={classes.rowBodySectionHeaderText}>{<ReduxLanguage languageKey="forms.sofiaProjectId" />}</h4>
                    <h4 className={classes.rowBodyText}>{card.sofiaProjectId || '---'}</h4>
                  </td>
                  <td>
                    <h4 className={classes.rowBodySectionHeaderText}>{<ReduxLanguage languageKey="forms.kamReference" />}</h4>
                    <h4 className={classes.rowBodyText}>{card.kamReference || '---'}</h4>
                  </td>
                  <td>
                    <h4 className={classes.rowBodySectionHeaderText}>{<ReduxLanguage languageKey="forms.crmLink" />}</h4>
                    <h4 className={classes.rowBodyText}>{crmLinkComponent}</h4>
                  </td>
                  <td>
                    <h4 className={classes.rowBodySectionHeaderText}>{<ReduxLanguage languageKey="forms.notes" />}</h4>
                    <h4 className={classes.rowBodyText}>{card.notes || '---'}</h4>
                  </td>
                </tr>
              </table>
            </AccordionDetails>
          </Accordion>
        }
        {!isVar && card?.actions && card?.actions.length ?
          <>
            <Divider />
            <Accordion style={{ marginTop: 5 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h3 className={classes.rowBodySectionHeaderText}>{<ReduxLanguage languageKey="forms.cardSubscriptionsLinked" />}</h3>
              </AccordionSummary>
              <AccordionDetails style={{ overflowX: 'auto', flexDirection: 'column' }}>
                {_.map(card?.actions, (a) =>
                  <SubscriptionRow
                    key={a.subscription.id}
                    subscription={a.subscription}
                  />
                )}
              </AccordionDetails>
            </Accordion>
          </> : null
        }
      </div>
    );
  }
}

export default withStyles(styles)(CardRow);