import { IDomainDetailedDTO } from './../../repository/jago/model/input/IDomainDetailedDTO';
import { PaginatedDataDTO, PaginationDTO, PaginationQueryParamsDTO } from '@bottega52/commons-pagination';
import * as FileSaver from 'file-saver';
import _ from 'lodash';
import moment from 'moment';
import * as XLSX from 'xlsx';
import * as CustomerCodec from '../../codec/customerDTOCodec';
import * as MarketplaceDecoder from '../../codec/marketplaceInDTODecoder';
import * as ClusterDecoder from '../../codec/clusterInDTODecoder';
import * as DomainUsersDecoder from '../../codec/domainUsersInDTODecoder';
import * as WalletDecoder from '../../codec/walletInDTODecoder';
import * as CreditsAPI from '../../repository/jago/creditsAPI';
import { ICreditsInDTO } from '../../repository/jago/model/input/ICreditsInDTO';
import { ICustomerFormDTO } from '../../repository/jago/model/input/ICustomerFormDTO';
import { IDomainAdditionalInfoOutDTO } from '../../repository/jago/model/input/IDomainAdditionalInfoInDTO';
import { IDomainConfigParsedDTO } from '../../repository/jago/model/input/IDomainConfigInDTO';
import { IWalletDetailedDTO } from '../../repository/jago/model/input/IWalletDetailedDTO';
import { IWalletFormDTO } from '../../repository/jago/model/input/IWalletFormDTO';
import { IWalletRechargeFormDTO } from '../../repository/jago/model/input/IWalletRechargeFormDTO';
import { ICustomerOutDTO } from '../../repository/jago/model/output/ICustomerOutDTO';
import { IDomainConfigOutDTO } from '../../repository/jago/model/output/IDomainConfigOutDTO';
import { IDomainUserOutDTO } from '../../repository/jago/model/output/IDomainUserOutDTO';
import { IWalletSubscriptionsRequestParamsDTO, ICustomerRequestParamsDTO, ISubscriptionsRequestParamsDTO, IWalletCreditsRequestParamsDTO, IWalletPlantsRequestParamsDTO, IWalletUsageRequestParamsDTO, IClusterRequestParamsDTO, IMarketplacesRequestParamsDTO, IOrderDirectionEnum, ICardRequestParamsDTO, IExportWalletUsageCSVParamsDTO, ICustomerPlantsRequestParamsDTO } from '../../repository/jago/model/output/RequestParamsDTOs';
import translations from '../../translations/i18next';
import { elaborateDomainType } from '../domains/domain.utils';
import { IState } from '../store';
import { ActionsUnion, createAction, IThunkAction } from "../utils";
import { CardStatusOptions, ICardInDTO } from './../../repository/jago/model/input/ICardInDTO';
import { IVarInDTO } from '../../repository/jago/model/input/IVarInDTO';
import { IChartDataDTO } from './../../repository/jago/model/input/IChartDataDTO';
import { ICustomerInDTO } from './../../repository/jago/model/input/ICustomerInDTO';
import { ISubscriptionInDTO } from './../../repository/jago/model/input/ISubscriptionInDTO';
import { IWalletCustomerDTO } from './../../repository/jago/model/input/IWalletCustomerDTO';
import { IWalletInDTO } from './../../repository/jago/model/input/IWalletInDTO';
import { IWalletUsageInDTO } from './../../repository/jago/model/input/IWalletUsageInDTO';
import { ICardOutDTO, IEditCardOutDTO, IEnableCardOutDTO } from './../../repository/jago/model/output/ICardOutDTO';
import { IEditSubscriptionOutDTO, ISubscriptionOutDTO } from './../../repository/jago/model/output/ISubscriptionOutDTO';
import { IWalletOutDTO } from './../../repository/jago/model/output/IWalletOutDTO';
import { IWalletPlantOutDTO } from './../../repository/jago/model/output/IWalletPlantOutDTO';
import { IWalletRequestParamsDTO } from './../../repository/jago/model/output/IWalletRequestParamsDTO';
import { IWalletSubscriptionOutDTO } from './../../repository/jago/model/output/IWalletSubscriptionOutDTO';
import { GREEN, RED } from './../../style/StyleConsts';
import CreditsActionTypesEnum from "./model/CreditsActionTypesEnum";
import IMarketplaceInDTO from '../../repository/jago/model/input/IMarketplaceInDTO';
import IMarketplaceOutDTO from '../../repository/jago/model/output/IMarketplaceOutDTO';
import IClusterInDTO from '../../repository/jago/model/input/IClusterInDTO';
import Utils, { getDisplayCredits, getMonthlyBurnRate } from '../../utils/Utils';
import { DomainsActions } from '../domains/domains.actions';
import { IPlantInDTO } from '../../repository/jago/model/input/IPlantInDTO';
import { IDomainInDTO } from '../../repository/jago/model/input/IDomainInDTO';


export const CreditsActions = {
  saveClusters: createAction<typeof CreditsActionTypesEnum.SAVE_CLUSTERS, PaginatedDataDTO<IClusterInDTO>>(CreditsActionTypesEnum.SAVE_CLUSTERS),
  saveMarketPlaces: createAction<typeof CreditsActionTypesEnum.SAVE_MARKETPLACES, PaginatedDataDTO<IMarketplaceInDTO>>(CreditsActionTypesEnum.SAVE_MARKETPLACES),
  setSelectedMarketplace: createAction<typeof CreditsActionTypesEnum.SET_SELECTED_MARKETPLACE, IMarketplaceInDTO | {}>(CreditsActionTypesEnum.SET_SELECTED_MARKETPLACE),
  saveCustomers: createAction<typeof CreditsActionTypesEnum.SAVE_CUSTOMERS, PaginatedDataDTO<ICustomerInDTO>>(CreditsActionTypesEnum.SAVE_CUSTOMERS),
  setSelectedDetailedCustomer: createAction<typeof CreditsActionTypesEnum.SET_SELECTED_DETAILED_CUSTOMER, ICustomerInDTO | {}>(CreditsActionTypesEnum.SET_SELECTED_DETAILED_CUSTOMER),
  saveWallets: createAction<typeof CreditsActionTypesEnum.SAVE_WALLETS, PaginatedDataDTO<IWalletCustomerDTO>>(CreditsActionTypesEnum.SAVE_WALLETS),
  appendWallet: createAction<typeof CreditsActionTypesEnum.APPEND_WALLET, IWalletCustomerDTO>(CreditsActionTypesEnum.APPEND_WALLET),
  updateWallet: createAction<typeof CreditsActionTypesEnum.UPDATE_WALLET, IWalletCustomerDTO>(CreditsActionTypesEnum.UPDATE_WALLET),
  setSelectedWallet: createAction<typeof CreditsActionTypesEnum.SET_SELECTED_WALLET, IWalletInDTO | {}>(CreditsActionTypesEnum.SET_SELECTED_WALLET),
  saveMarketplaceSubscriptions: createAction<typeof CreditsActionTypesEnum.SAVE_MARKETPLACE_SUBSCRIPTIONS, PaginatedDataDTO<ISubscriptionInDTO>>(CreditsActionTypesEnum.SAVE_MARKETPLACE_SUBSCRIPTIONS),
  setSelectedMarketplaceMainTier: createAction<typeof CreditsActionTypesEnum.SET_SELECTED_MARKETPLACE_MAIN_TIER, ISubscriptionInDTO | {}>(CreditsActionTypesEnum.SET_SELECTED_MARKETPLACE_MAIN_TIER),
  saveSelectedMarketplaceMainTierSubscriptions: createAction<typeof CreditsActionTypesEnum.SAVE_SELECTED_MARKETPLACE_MAIN_TIER_SUBSCRIPTIONS, PaginatedDataDTO<ISubscriptionInDTO>>(CreditsActionTypesEnum.SAVE_SELECTED_MARKETPLACE_MAIN_TIER_SUBSCRIPTIONS),
  saveSystemCards: createAction<typeof CreditsActionTypesEnum.SAVE_SYSTEM_CARDS, PaginatedDataDTO<ICardInDTO>>(CreditsActionTypesEnum.SAVE_SYSTEM_CARDS),
  saveWalletsAll: createAction<typeof CreditsActionTypesEnum.SAVE_WALLETS_ALL, PaginatedDataDTO<ICustomerInDTO> | {}>(CreditsActionTypesEnum.SAVE_WALLETS_ALL),
  saveCustomersSearch: createAction<typeof CreditsActionTypesEnum.SAVE_CUSTOMERS_SEARCH, PaginatedDataDTO<ICustomerInDTO> | {}>(CreditsActionTypesEnum.SAVE_CUSTOMERS_SEARCH),
  saveCustomerPlants: createAction<typeof CreditsActionTypesEnum.SAVE_CUSTOMER_PLANTS, PaginatedDataDTO<ICustomerInDTO> | {}>(CreditsActionTypesEnum.SAVE_CUSTOMER_PLANTS),
  saveAllDomains: createAction<typeof CreditsActionTypesEnum.SAVE_ALL_DOMAINS, PaginatedDataDTO<IDomainInDTO>>(CreditsActionTypesEnum.SAVE_ALL_DOMAINS),
};

export type CreditsActionsType = ActionsUnion<typeof CreditsActions>;

export function setSelecteCustomer(customer: ICustomerInDTO | {}): IThunkAction<void, IState> {
  return (dispatch ) => {
    dispatch(CreditsActions.setSelectedDetailedCustomer(customer));
  }
}

export function setSelecteWallet(wallet: IWalletInDTO | {}): IThunkAction<void, IState> {
  return (dispatch ) => {
    dispatch(CreditsActions.setSelectedWallet(wallet));
  }
}

export function fetchMarketplaces(params: IMarketplacesRequestParamsDTO = { page: 0, pageSize: 500 }): IThunkAction<Promise<PaginatedDataDTO<IMarketplaceInDTO>>, IState> {
  return async (dispatch, getState) => {
    try {
      const response = await CreditsAPI.fetchMarketplaces(params);
      if (response && response.data) {
        const decodedData = MarketplaceDecoder.decode(response.data);
        dispatch(CreditsActions.saveMarketPlaces(decodedData));
        return decodedData;
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function fetchClusters(params: IClusterRequestParamsDTO = { page: 0, pageSize: 500 }): IThunkAction<Promise<PaginatedDataDTO<IMarketplaceInDTO>>, IState> {
  return async (dispatch, getState) => {
    try {
      const response = await CreditsAPI.fetchClusters(params);
      if (response && response.data) {
        const decodedData = ClusterDecoder.decode(response.data);
        dispatch(CreditsActions.saveClusters(decodedData));
        return decodedData;
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function appendMarketplaces(params?: IMarketplacesRequestParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const { marketplaces: { data } } = getState().credits;
      if (_.isEmpty(data)) return;
      const stateMarketplaces = data as PaginatedDataDTO<IMarketplaceInDTO>;
      const response = await CreditsAPI.fetchCustomers(params);
      if (response && response.data) {
        const decodedData = CustomerCodec.decode(response.data);
        const marketplacesToSave : PaginatedDataDTO<IMarketplaceInDTO> = {
          pagination: decodedData.pagination,
          content: [
            ...stateMarketplaces.content,
            ...decodedData.content
          ],
        }
        dispatch(CreditsActions.saveMarketPlaces(marketplacesToSave));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function createMarketplace(data: IMarketplaceOutDTO): IThunkAction<void, IState> {
  return async () => {
    try {
      const mktPlace: IMarketplaceOutDTO = {
        name: data.name,
      }
      const response = await CreditsAPI.createMarketplace(mktPlace);
      if (response && response.data) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function fetchCustomers(params?: ICustomerRequestParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const customersResponse = await CreditsAPI.fetchCustomers(params);
      if (customersResponse && customersResponse.data) {
        const decodedData = CustomerCodec.decode(customersResponse.data);
        dispatch(CreditsActions.saveCustomers(decodedData));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function appendCustomers(params?: ICustomerRequestParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const { customers: { data } } = getState().credits;
      if (_.isEmpty(data)) return;
      const stateCustomers = data as PaginatedDataDTO<ICustomerInDTO>;
      const customersResponse = await CreditsAPI.fetchCustomers(params);
      if (customersResponse && customersResponse.data) {
        const decodedData = CustomerCodec.decode(customersResponse.data);
        const customersToSave : PaginatedDataDTO<ICustomerInDTO> = {
          pagination: decodedData.pagination,
          content: [
            ...stateCustomers.content,
            ...decodedData.content
          ],
        }
        dispatch(CreditsActions.saveCustomers(customersToSave));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function fetchCustomerDetails(customerId: number): IThunkAction<void, IState> {
  return async () => {
    try {
      const createCustomerResponse = await CreditsAPI.gestCustomerById(customerId);
      if (createCustomerResponse && createCustomerResponse.data) {
        return createCustomerResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function createNewCustomer(newCustomerData: ICustomerFormDTO, currentVar?: IVarInDTO): IThunkAction<void, IState> {
  return async () => {
    try {
      const newCustomer: ICustomerOutDTO = 
        currentVar!==undefined?CustomerCodec.encodeCustomerFromFormVAR(newCustomerData,currentVar):CustomerCodec.encodeCustomerFromForm(newCustomerData);
      const createCustomerResponse = await CreditsAPI.createNewCustomer(newCustomer);
      if (createCustomerResponse && createCustomerResponse.data) {
        return createCustomerResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function editCustomer(customerId: number, newCustomerData: ICustomerFormDTO, currentVar?: IVarInDTO): IThunkAction<void, IState> {
  return async () => {
    try {
      const newCustomer: ICustomerOutDTO = CustomerCodec.encodeCustomerFromForm(newCustomerData);
      if (currentVar!==undefined) {
        newCustomer.clusterId = undefined;
        newCustomer.marketplaceId = -1; // Solo perché è campo obbligatorio, ma tanto viene sovrascritto con effettivo marketplaceId del VAR
        newCustomer.company = undefined;
        newCustomer.varId = currentVar.id;
      }
      const createCustomerResponse = await CreditsAPI.editCustomer(customerId, newCustomer);
      if (createCustomerResponse && createCustomerResponse.data) {
        return createCustomerResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function deleteCustomer(customerId: number): IThunkAction<void, IState> {
  return async () => {
    try {
      const deleteCustomerResponse = await CreditsAPI.deleteCustomer(customerId);
      if (deleteCustomerResponse && deleteCustomerResponse.data) {
        return deleteCustomerResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function fetchCustomerWallets(params?: IWalletRequestParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const customersWalletsResponse = await CreditsAPI.fetchWallets(params);
      if (customersWalletsResponse && customersWalletsResponse.data) {
        const decodedData = WalletDecoder.decode(customersWalletsResponse.data);
        const detailedWallets: IWalletCustomerDTO[] = [];
        for (const wallet of decodedData.content) {
          try {
            const availableCreditsResponse = await CreditsAPI.fetchWalletAvailableCredits(wallet.id);
            if (availableCreditsResponse && availableCreditsResponse.data) {
              detailedWallets.push({
                ...wallet,
                availableCredits: availableCreditsResponse.data.availableQuantity,
              })
            } else {
              detailedWallets.push(wallet);
            }
          } catch (error) {
            detailedWallets.push(wallet);
          }
        }
        const walletsToSave: PaginatedDataDTO<IWalletCustomerDTO> = {
          content: detailedWallets,
          pagination: decodedData.pagination,
        }
        dispatch(CreditsActions.saveWallets(walletsToSave));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}


export function appendCustomerWallets(params?: IWalletRequestParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const { wallets: { data } } = getState().credits;
      if (_.isEmpty(data)) return;
      const stateWallets = data as PaginatedDataDTO<IWalletInDTO>;
      const walletsResponse = await CreditsAPI.fetchWallets(params);
      if (walletsResponse && walletsResponse.data) {
        const decodedData = WalletDecoder.decode(walletsResponse.data);
        const detailedWallets: IWalletCustomerDTO[] = [];
        for (const wallet of decodedData.content) {
          try {
            const availableCreditsResponse = await CreditsAPI.fetchWalletAvailableCredits(wallet.id);
            if (availableCreditsResponse && availableCreditsResponse.data) {
              detailedWallets.push({
                ...wallet,
                availableCredits: availableCreditsResponse.data.availableQuantity,
              })
            } else {
              detailedWallets.push(wallet);
            }
          } catch (error) {
            detailedWallets.push(wallet);
          }
        }
        const walletsToSave : PaginatedDataDTO<IWalletCustomerDTO> = {
          pagination: decodedData.pagination,
          content: [
            ...stateWallets.content,
            ...detailedWallets
          ],
        }
        dispatch(CreditsActions.saveWallets(walletsToSave));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function createNewCustomerWallet(customerId: number, newWalletData: IWalletFormDTO): IThunkAction<void, IState> {
  return async (dispatch) => {
    try {
      const newWallet: IWalletOutDTO = {
        ...newWalletData,
        customerId,
      }
      const createCustomerWalletResponse = await CreditsAPI.createNewCustomerWallet(newWallet);
      dispatch(CreditsActions.appendWallet(createCustomerWalletResponse.data));
      return createCustomerWalletResponse.data;
    } catch (error) {
      throw error;
    }
  }
}

export function editCustomerWallet(walletId: number, data: IWalletFormDTO): IThunkAction<void, IState> {
  return async (dispatch) => {
    try {
      const editWalletResponse = await CreditsAPI.editCustomerWallet(walletId, data);
      try {
        const availableCreditsResponse = await CreditsAPI.fetchWalletAvailableCredits(walletId);
        dispatch(CreditsActions.updateWallet({
          ...editWalletResponse.data,
          availableCredits: availableCreditsResponse.data.availableQuantity,
        }));
      } catch (error) {
        dispatch(CreditsActions.updateWallet(editWalletResponse.data));
      }
      return editWalletResponse.data;
    } catch (error) {
      throw error;
    }
  }
}

export function fetchCustomerWalletCredits(params: IWalletCreditsRequestParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const customersWalletsResponse = await CreditsAPI.fetchWalletCredits(params);
      if (customersWalletsResponse && customersWalletsResponse.data) {
        const decodedData = WalletDecoder.decodeCredits(customersWalletsResponse.data);
        const { selectedWallet } = getState().credits.wallets;
        const wallet = selectedWallet as IWalletDetailedDTO;
        const updatedWallet = {
          ...wallet,
          credits: decodedData,
        }
        dispatch(CreditsActions.setSelectedWallet(updatedWallet));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function fetchCustomerWalletAvailableCredits(walletId: number): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const customersWalletsCreditsResponse = await CreditsAPI.fetchWalletAvailableCredits(walletId);
      if (customersWalletsCreditsResponse && customersWalletsCreditsResponse.data) {
        const { selectedWallet } = getState().credits.wallets;
        const wallet = selectedWallet as IWalletDetailedDTO;
        const updatedWallet = {
          ...wallet,
          availableCredits: customersWalletsCreditsResponse.data.availableQuantity,
        }
        dispatch(CreditsActions.setSelectedWallet(updatedWallet));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function appendCustomerWalletCredits(params: IWalletCreditsRequestParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const { wallets: { selectedWallet } } = getState().credits;
      const stateWallet = selectedWallet as IWalletDetailedDTO;
      if (_.isEmpty(stateWallet.credits?.content)) return;
      if (stateWallet.credits?.content) {
        const customersWalletsResponse = await CreditsAPI.fetchWalletCredits(params);
        if (customersWalletsResponse && customersWalletsResponse.data) {
          const decodedData = WalletDecoder.decodeCredits(customersWalletsResponse.data);
          const walletToSave : IWalletDetailedDTO = {
            ...stateWallet,
            credits: {
              pagination: decodedData.pagination,
              content: [
                ...stateWallet.credits.content,
                ...decodedData.content
              ],
            }
          }
          dispatch(CreditsActions.setSelectedWallet(walletToSave));
        } else {
          throw new Error();
        }
      }
    } catch (error) {
      throw error;
    }
  }
};

export function fetchCustomerWalletSubscriptions(params: IWalletSubscriptionsRequestParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const customersWalletsResponse = await CreditsAPI.fetchWalletSubscription(params);
      if (customersWalletsResponse && customersWalletsResponse.data) {
        const decodedData = WalletDecoder.decodeWalletSubscriptions(customersWalletsResponse.data);
        const { selectedWallet } = getState().credits.wallets;
        const wallet = selectedWallet as IWalletDetailedDTO;
        let burnRate = 0;
        let burnRateDaily = 0;
        if (decodedData.content && !_.isEmpty(decodedData.content)) {
          _.each(decodedData.content, (subscription) => {
            burnRate += getMonthlyBurnRate(subscription.subscription.burnRate);
            burnRateDaily += getDisplayCredits(subscription.subscription.burnRate);
          });
        }
        const updatedWallet = {
          ...wallet,
          subscriptions: decodedData,
          burnRate,
          burnRateDaily,
        }
        dispatch(CreditsActions.setSelectedWallet(updatedWallet));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function appendCustomerWalletSubscriptions(params: IWalletSubscriptionsRequestParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const { wallets: { selectedWallet } } = getState().credits;
      const stateWallet = selectedWallet as IWalletDetailedDTO;
      if (_.isEmpty(stateWallet.subscriptions?.content)) return;
      if (stateWallet.subscriptions?.content) {
        const customersWalletsResponse = await CreditsAPI.fetchWalletSubscription(params);
        if (customersWalletsResponse && customersWalletsResponse.data) {
          const decodedData = WalletDecoder.decodeWalletSubscriptions(customersWalletsResponse.data);
          const walletToSave : IWalletDetailedDTO = {
            ...stateWallet,
            subscriptions: {
              pagination: decodedData.pagination,
              content: [
                ...stateWallet.subscriptions.content,
                ...decodedData.content
              ],
            }
          }
          dispatch(CreditsActions.setSelectedWallet(walletToSave));
        } else {
          throw new Error();
        }
      }
    } catch (error) {
      throw error;
    }
  }
};

export function deleteCustomerWalletSubscription(walletId: number, walletSubscriptionId: number): IThunkAction<void, IState> {
  return async () => {
    return await CreditsAPI.deleteCustomerWalletSubscription(walletId, walletSubscriptionId);
  };
}

export function fetchCustomerWalletPlants(params: IWalletPlantsRequestParamsDTO, saveAsDomains: boolean = false): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const customersWalletsResponse = await CreditsAPI.fetchWalletPlants(params);
      if (customersWalletsResponse && customersWalletsResponse.data) {
        const decodedData = WalletDecoder.decodeWalletPlants(customersWalletsResponse.data);
        if (saveAsDomains) {
          dispatch(CreditsActions.saveAllDomains(decodedData))
        }
        else {
          const { selectedWallet } = getState().credits.wallets;
          const wallet = selectedWallet as IWalletDetailedDTO;
          const updatedWallet = {
            ...wallet,
            plants: decodedData,
          }
          dispatch(CreditsActions.setSelectedWallet(updatedWallet));
        }
        
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function appendCustomerWalletPlants(params: IWalletPlantsRequestParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const { wallets: { selectedWallet } } = getState().credits;
      const stateWallet = selectedWallet as IWalletDetailedDTO;
      if (_.isEmpty(stateWallet.plants?.content)) return;
      if (stateWallet.plants?.content) {
        const customersWalletsResponse = await CreditsAPI.fetchWalletPlants(params);
        if (customersWalletsResponse && customersWalletsResponse.data) {
          const decodedData = WalletDecoder.decodeWalletPlants(customersWalletsResponse.data);
          const walletToSave : IWalletDetailedDTO = {
            ...stateWallet,
            plants: {
              pagination: decodedData.pagination,
              content: [
                ...stateWallet.plants.content,
                ...decodedData.content
              ],
            }
          }
          dispatch(CreditsActions.setSelectedWallet(walletToSave));
        } else {
          throw new Error();
        }
      }
    } catch (error) {
      throw error;
    }
  }
};

export function createNewWalletPlant(plant: IWalletPlantOutDTO): IThunkAction<void, IState> {
  return async () => {
    try {
      const createPlantResponse = await CreditsAPI.createCustomerWalletPlant(plant);
      if (createPlantResponse && createPlantResponse.data) {
        return createPlantResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function deleteWalletPlant(plantId: number): IThunkAction<void, IState> {
  return async () => {
    try {
      const deletePlantResponse = await CreditsAPI.deleteCustomerWalletPlant(plantId);
      if (deletePlantResponse && deletePlantResponse.data) {
        return deletePlantResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function fetchCustomerWalletUsage(params: IWalletUsageRequestParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const customersWalletUsageResponse = await CreditsAPI.fetchWalletUsage(params);
      if (customersWalletUsageResponse && customersWalletUsageResponse.data) {
        const decodedData = WalletDecoder.decodeCreditsUsage(customersWalletUsageResponse.data);
        const { selectedWallet } = getState().credits.wallets;
        const wallet = selectedWallet as IWalletDetailedDTO;
        const updatedWallet = {
          ...wallet,
          usage: decodedData,
        }
        dispatch(CreditsActions.setSelectedWallet(updatedWallet));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function appendCustomerWalletUsage(params: IWalletUsageRequestParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const { wallets: { selectedWallet } } = getState().credits;
      const stateWallet = selectedWallet as IWalletDetailedDTO;
      if (_.isEmpty(stateWallet.usage?.content)) return;
      if (stateWallet.usage?.content) {
        const customersWalletUsageResponse = await CreditsAPI.fetchWalletUsage(params);
        if (customersWalletUsageResponse && customersWalletUsageResponse.data) {
          const decodedData = WalletDecoder.decodeCreditsUsage(customersWalletUsageResponse.data);
          const walletToSave : IWalletDetailedDTO = {
            ...stateWallet,
            usage: {
              pagination: decodedData.pagination,
              content: [
                ...stateWallet.usage.content,
                ...decodedData.content
              ],
            }
          }
          dispatch(CreditsActions.setSelectedWallet(walletToSave));
        } else {
          throw new Error();
        }
      }
    } catch (error) {
      throw error;
    }
  }
};

export function rechargeCustomerWallet(walletId: number, card: IWalletRechargeFormDTO): IThunkAction<void, IState> {
  return async () => {
    try {
      const rechargeWalletResponse = await CreditsAPI.activateCustomerWallet(walletId, card);
      if (rechargeWalletResponse && rechargeWalletResponse.data) {
        return rechargeWalletResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function fetchMarketplaceSubscriptions(marketplaceId: number, params?: ISubscriptionsRequestParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const subscriptionsResponse = await CreditsAPI.fetchMarketplaceSubscriptions(marketplaceId, params);
      if (subscriptionsResponse && subscriptionsResponse.data) {
        const decodedData = WalletDecoder.decodeSubscriptions(subscriptionsResponse.data);
        dispatch(CreditsActions.saveMarketplaceSubscriptions(decodedData));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function appendMarketplaceSubscriptions(marketplaceId: number, params?: ISubscriptionsRequestParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const { marketplaceSubscriptions: { data } } = getState().credits;
      if (_.isEmpty(data)) return;
      const stateSubscriptions = data as PaginatedDataDTO<ISubscriptionInDTO>;
      const subscriptionsResponse = await CreditsAPI.fetchMarketplaceSubscriptions(marketplaceId, params);
      if (subscriptionsResponse && subscriptionsResponse.data) {
        const decodedData = WalletDecoder.decodeSubscriptions(subscriptionsResponse.data);
        const subscriptionsToSave : PaginatedDataDTO<ISubscriptionInDTO> = {
          pagination: decodedData.pagination,
          content: [
            ...stateSubscriptions.content,
            ...decodedData.content
          ],
        }
        dispatch(CreditsActions.saveMarketplaceSubscriptions(subscriptionsToSave));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function fetchMarketplaceMainTierSubscriptions(marketplaceId: number, mainTierId: number, params?: ISubscriptionsRequestParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    let formattedParams: ISubscriptionsRequestParamsDTO = {
      mainTier: mainTierId,
    };
    if (params) {
      formattedParams = { ...params, ...formattedParams }
    }
    try {
      const subscriptionsResponse = await CreditsAPI.fetchMarketplaceSubscriptions(marketplaceId, formattedParams);
      if (subscriptionsResponse && subscriptionsResponse.data) {
        const decodedData = WalletDecoder.decodeSubscriptions(subscriptionsResponse.data);
        dispatch(CreditsActions.saveSelectedMarketplaceMainTierSubscriptions(decodedData));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function appendMarketplaceMainTierSubscriptions(marketplaceId: number, mainTierId: number, params?: ISubscriptionsRequestParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      let formattedParams: ISubscriptionsRequestParamsDTO = {
        mainTier: mainTierId,
      };
      if (params) {
        formattedParams = { ...params, ...formattedParams };
      }
      const { marketplaceSubscriptions: { selectedMainTierSubcriptions } } = getState().credits;
      if (_.isEmpty(selectedMainTierSubcriptions)) return;
      const stateSubscriptions = selectedMainTierSubcriptions as PaginatedDataDTO<ISubscriptionInDTO>;
      const subscriptionsResponse = await CreditsAPI.fetchMarketplaceSubscriptions(marketplaceId, formattedParams);
      if (subscriptionsResponse && subscriptionsResponse.data) {
        const decodedData = WalletDecoder.decodeSubscriptions(subscriptionsResponse.data);
        const subscriptionsToSave : PaginatedDataDTO<ISubscriptionInDTO> = {
          pagination: decodedData.pagination,
          content: [
            ...stateSubscriptions.content,
            ...decodedData.content
          ],
        }
        dispatch(CreditsActions.saveMarketplaceSubscriptions(subscriptionsToSave));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function createMarketplaceSubscription(marketplaceId: number, subscription: ISubscriptionOutDTO): IThunkAction<void, IState> {
  return async () => {
    try {
      const createSubscriptionResponse = await CreditsAPI.createMarketplaceSubscription(marketplaceId, subscription);
      if (createSubscriptionResponse && createSubscriptionResponse.data) {
        return createSubscriptionResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function editMarketplaceSubscription(marketplaceId: number, subscriptionId: number, subscription: IEditSubscriptionOutDTO): IThunkAction<void, IState> {
  return async () => {
    try {
      const response = await CreditsAPI.editMarketplaceSubscription(marketplaceId, subscriptionId, subscription);
      if (response && response.data) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function fetchSystemCards(params?: ICardRequestParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    const formattedParams: ICardRequestParamsDTO = {
      orderDir: IOrderDirectionEnum.DESC,
      ...params,
    };
    try {
      const cardsResponse = await CreditsAPI.fetchSystemCards(formattedParams);
      if (cardsResponse && cardsResponse.data) {
        const decodedData = WalletDecoder.decodeCards(cardsResponse.data);
        dispatch(CreditsActions.saveSystemCards(decodedData));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function appendSystemCards(params?: ICustomerRequestParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const { systemCards: { data } } = getState().credits;
      if (_.isEmpty(data)) return;
      const stateCards = data as PaginatedDataDTO<ICardInDTO>;
      const formattedParams: ICardRequestParamsDTO = {
        orderDir: IOrderDirectionEnum.DESC,
        ...params,
      };
      const cardsResponse = await CreditsAPI.fetchSystemCards(formattedParams);
      if (cardsResponse && cardsResponse.data) {
        const decodedData = WalletDecoder.decodeCards(cardsResponse.data);
        const cardsToSave : PaginatedDataDTO<ICardInDTO> = {
          pagination: decodedData.pagination,
          content: [
            ...stateCards.content,
            ...decodedData.content
          ],
        }
        dispatch(CreditsActions.saveSystemCards(cardsToSave));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function createSystemCard(card: ICardOutDTO): IThunkAction<void, IState> {
  return async () => {
    try {
      const createCardResponse = await CreditsAPI.createSystemCard(card);
      if (createCardResponse && createCardResponse.data) {
        return createCardResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function editSystemCard(cardId: number, card: IEditCardOutDTO): IThunkAction<void, IState> {
  return async () => {
    try {
      const response = await CreditsAPI.editSystemCard(cardId, card);
      if (response && response.data) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function enableSystemCard(cardId: number): IThunkAction<void, IState> {
  return async () => {
    try {
      const payload: IEnableCardOutDTO = {status:CardStatusOptions.ACTIVE}
      const response = await CreditsAPI.enableSystemCard(cardId, payload);
      if (response && response.data) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function deleteSystemCard(cardId: number): IThunkAction<void, IState> {
  return async () => {
    try {
      const deleteCardResponse = await CreditsAPI.deleteSystemCard(cardId);
      if (deleteCardResponse && deleteCardResponse.data) {
        return deleteCardResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function fetchMarketplaceSubscriptionsRawData(marketplaceId: number, params?: ISubscriptionsRequestParamsDTO): IThunkAction<Promise<ISubscriptionInDTO[]>, IState> {
  return async (dispatch, getState) => {
    try {
      const subscriptionsResponse = await CreditsAPI.fetchMarketplaceSubscriptions(marketplaceId, params);
      if (subscriptionsResponse && subscriptionsResponse.data && subscriptionsResponse.data.content) {
        return subscriptionsResponse.data.content;
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function createNewWalletSubscription(walletId: number, data: IWalletSubscriptionOutDTO): IThunkAction<void, IState> {
  return async () => {
    try {
      const createWalletSubscriptionResponse = await CreditsAPI.createCustomerWalletSubscription(walletId, data);
      if (createWalletSubscriptionResponse && createWalletSubscriptionResponse.data) {
        return createWalletSubscriptionResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function editWalletMainTierSubscription(walletId: number, data: IWalletSubscriptionOutDTO): IThunkAction<void, IState> {
  return async () => {
    try {
      const response = await CreditsAPI.editCustomerWalletMainTierSubscription(walletId, data);
      if (response && response.data) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function createWalletUsageChartData(walletUsageData: IWalletUsageInDTO[]): IChartDataDTO {

  // ATTENZIONE CHE QUI LA createdAt PUO ESSERE LO STESSO
  const labels: string[] = [];
  const data: number[] = [];
  const orderedData = _.orderBy(walletUsageData, 'createdAt', 'asc');
  _.each(orderedData, usageData => {
    const label = moment(usageData.createdAt).format('L');
    labels.push(label);
    data.push(Utils.formatCreditsIn(usageData.quantity));
  });
  return {
    labels,
    datasets: [{
      label: 'Usage',
      data,
      borderColor: RED,
      backgroundColor: RED,
    }]
  }
}

export function createWalletRechargeChartData(walletCreditsData: ICreditsInDTO[]): IChartDataDTO {

  // ATTENZIONE CHE QUI LA createdAt PUO ESSERE LO STESSO
  const labels: string[] = [];
  const data: number[] = [];
  const orderedData = _.orderBy(walletCreditsData, 'createdAt', 'asc');
  _.map(orderedData, usageData => {
    const label = moment(usageData.createdAt).format('L');
    labels.push(label);
    data.push(Utils.formatCreditsIn(usageData.quantity));
  });
  return {
    labels,
    datasets: [{
      label: 'Recharge',
      data,
      borderColor: GREEN,
      backgroundColor: GREEN,
    }]
  }
}

export function exportWalletCreditsData(): IThunkAction<void, IState> {
  return (dispatch, getState) => {
    const { wallets: { selectedWallet }, customers: { selectedDetailedCustomer } } = getState().credits;
    const stateWallet = selectedWallet as IWalletDetailedDTO;
    if (stateWallet && stateWallet.name && stateWallet.credits && stateWallet.credits.content) {
      const exportData: any[] = [];
      const fileName = `Recharges Wallet ${stateWallet.name}`;
      _.map(stateWallet.credits.content, credit => {
        exportData.push({
          Customer: selectedDetailedCustomer?.name,
          Quantity: credit.quantity,
          'Creation Date': moment(credit.createdAt).format('LL'),
          'Card Code': credit && credit.card ? credit.card.code : '---',
          'Card Order Form': credit && credit.card ? credit.card.iseoSalesOrderReference : '---',
          'Card Notes': credit && credit.card ? credit.card.notes : '---',
        });
      });
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = `.xlsx`;
      const ws = XLSX.utils.json_to_sheet(exportData);
      const wb = { Sheets: { Recharges: ws }, SheetNames: ['Recharges'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    }
  }
}

export function exportWalletUsageData(): IThunkAction<void, IState> {
  return (dispatch, getState) => {
    const { wallets: { selectedWallet }, customers: { selectedDetailedCustomer } } = getState().credits;
    const stateWallet = selectedWallet as IWalletDetailedDTO;
    if (stateWallet && stateWallet.name && stateWallet.usage && stateWallet.usage.content) {
      const exportData: any[] = [];
      const fileName = `Consumption Wallet ${stateWallet.name}`;
      _.map(stateWallet.usage.content, usage => {
        exportData.push({
          Customer: selectedDetailedCustomer?.name,
          Quantity: usage.quantity,
          Date: moment(usage.createdAt).format('LL'),
          'Usage Type': usage.usageType ? translations.t(`credits.${usage.usageType}`) : '---',
        });
      });
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = `.xlsx`;
      const ws = XLSX.utils.json_to_sheet(exportData);
      const wb = { Sheets: { Usage: ws }, SheetNames: ['Usage'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    }
  }
}

export function fetchWalletPlantConfiguration(): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const { selectedDetailedDomain } = getState().domains;
      const plant = selectedDetailedDomain as IDomainDetailedDTO;
      const configResponse = await CreditsAPI.fetchWalletPlantConfiguration(plant.hostname);
      if (configResponse && configResponse.data) {
        const plantConfig: IDomainConfigParsedDTO = JSON.parse(configResponse.data.data);
        const plantDomain: IDomainDetailedDTO = {
          ...plant,
          config: plantConfig,
        };
        dispatch(DomainsActions.setSelectedDetailedDomain(plantDomain));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function fetchWalletPlantUsers(params?: PaginationQueryParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const { selectedDetailedDomain } = getState().domains;
      const plant = selectedDetailedDomain as IDomainDetailedDTO;
      const plantUsersResponse = await CreditsAPI.fetchWalletPlantUsers(plant.hostname, { ...params, roleIds: [1] });
      if (plantUsersResponse && plantUsersResponse.data) {
        const decodedData = DomainUsersDecoder.decode(plantUsersResponse.data);
        const detailedPlant = {
          ...plant,
          users: decodedData,
        };
        dispatch(DomainsActions.setSelectedDetailedDomain(detailedPlant));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function fetchWalletPlantInstallers(params?: PaginationQueryParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const { selectedDetailedDomain } = getState().domains;
      const plant = selectedDetailedDomain as IDomainDetailedDTO;
      const domainUsersResponse = await CreditsAPI.fetchWalletPlantUsers(plant.hostname, { ...params, roleIds: [2] });
      if (domainUsersResponse && domainUsersResponse.data) {
        const decodedData = DomainUsersDecoder.decode(domainUsersResponse.data);
        const detailedPlant = {
          ...plant,
          installers: decodedData,
        };
        dispatch(DomainsActions.setSelectedDetailedDomain(detailedPlant));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function fetchWalletPlantAdditionalInfo(): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const { selectedDetailedDomain } = getState().domains;
      const plant = selectedDetailedDomain as IDomainDetailedDTO;
      const domainInfoResponse = await CreditsAPI.fetchWalletPlantAdditionalInfo(plant.hostname);
      if (domainInfoResponse && domainInfoResponse.data) {
        const detailedPlant = {
          ...plant,
          note: domainInfoResponse.data.note,
        };
        dispatch(DomainsActions.setSelectedDetailedDomain(detailedPlant));
      }
    } catch (error) {
    }
  };
}

export function fetchWalletPlantInfo(): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const { selectedDetailedDomain } = getState().domains;
      const plant = selectedDetailedDomain as IDomainDetailedDTO;
      const domainInfoResponse = await CreditsAPI.fetchWalletPlantInfo(plant.hostname);
      if (domainInfoResponse && domainInfoResponse.data) {
        const domainTypeDTO = elaborateDomainType(domainInfoResponse.data);
        const detailedPlant: IDomainDetailedDTO = {
          ...plant,
          info: domainInfoResponse.data,
          ...domainTypeDTO,
          installationMode: domainInfoResponse.data.installationMode,
        };
        dispatch(DomainsActions.setSelectedDetailedDomain(detailedPlant));
      }
    } catch (error) {
    }
  };
}

export function updateWalletPlantAdditionalInfo(note: IDomainAdditionalInfoOutDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    const { selectedDetailedDomain } = getState().domains;
      const plant = selectedDetailedDomain as IDomainDetailedDTO;
    try {
      await CreditsAPI.updateWalletPlantAdditionalInfo(plant.hostname, note);
    } catch (error) {
      throw error;
    }
  };
}

export function updateWalletPlantConfiguration(config: IDomainConfigOutDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    const { selectedDetailedDomain } = getState().domains;
    const plant = selectedDetailedDomain as IDomainDetailedDTO;
    try {
      await CreditsAPI.updateWalletPlantConfiguration(plant.hostname, config);
    } catch (error) {
      throw error;
    }
  };
}

export function impersonateDomainUser(userEmail: string, duration?: number): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const { selectedDetailedDomain } = getState().domains;
      const plant = selectedDetailedDomain as IDomainDetailedDTO;
      const clientId = 'luckeyfrontend';
      const impersonateResponse = await CreditsAPI.impersonatePlantUser(plant.hostname, userEmail, clientId, duration);
      if(impersonateResponse.data && impersonateResponse.data.token) {
        return impersonateResponse.data.token;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function createNewUserForPlant(newUserData: IDomainUserOutDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const { selectedDetailedDomain } = getState().domains;
      const plant = selectedDetailedDomain as IDomainDetailedDTO;
      const createUserResponse = await CreditsAPI.createUserForPlant(plant.hostname, newUserData);
      if (createUserResponse && createUserResponse.data) {
        return createUserResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function exportWalletsUsageCSV(params?: IExportWalletUsageCSVParamsDTO) {
  return async () => {
    const response = await CreditsAPI.exportWalletsUsageCSV(params);
    FileSaver.saveAs(response.data, `export-wallet-usages-${moment().toISOString()}.csv`);
  }
}

export function exportWalletCreditUsageCSV() {
  return async () => {
    const response = await CreditsAPI.exportWalletCreditUsageCSV();
    FileSaver.saveAs(response.data, `export-wallet-credit-${moment().toISOString()}.csv`);
  }
}

export function fetchCustomersSearch(params?: ICustomerRequestParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const customersResponse = await CreditsAPI.fetchCustomers(params);
      if (customersResponse && customersResponse.data) {
        const decodedData = CustomerCodec.decode(customersResponse.data);
        dispatch(CreditsActions.saveCustomersSearch(decodedData));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function resetCustomersSearch(): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      dispatch(CreditsActions.saveCustomersSearch({}));
    } catch (error) {
      throw error;
    }
  };
}

export function fetchCustomerPlants(params?: ICustomerPlantsRequestParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const plantsResponse = await CreditsAPI.fetchCustomerPlants(params);
      if (plantsResponse && plantsResponse.data) {
        const pagination: PaginationDTO = _.omit(plantsResponse.data, 'content');
        const paginatedData: PaginatedDataDTO<IPlantInDTO> = {
          content: plantsResponse.data.content,
          pagination,
        };
        dispatch(CreditsActions.saveCustomerPlants(paginatedData));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function resetCustomerPlants(): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      dispatch(CreditsActions.saveCustomerPlants({}));
    } catch (error) {
      throw error;
    }
  };
}

export function fetchWalletsAll(): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      var isLast = false;
      var pageCounter = 0;
      const pageSize = 200;
      var params: IWalletRequestParamsDTO = { page: pageCounter, pageSize: pageSize }
      while (!isLast && pageCounter<20) {
        params.page = pageCounter;
        const { wallets: { data } } = getState().recharges;
        const walletsResponse = await CreditsAPI.fetchWallets(params);
        if (walletsResponse && walletsResponse.data) {
          const decodedData = WalletDecoder.decode(walletsResponse.data);
          isLast = (decodedData.pagination as PaginationDTO).last===true?true:false
          const walletsToSave : PaginatedDataDTO<IWalletInDTO> = _.isEmpty(data)?(decodedData):(
            {
              pagination: decodedData.pagination,
              content: [
                ...((data) as PaginatedDataDTO<IWalletInDTO>).content,
                ...decodedData.content
              ],
            }
          )
          dispatch(CreditsActions.saveWalletsAll(walletsToSave));
        } else {
          throw new Error();
        }
        pageCounter+=1
      }
      
    } catch (error) {
      throw error;
    }
  };
}